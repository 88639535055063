export const HOME_ROUTE = '/';
export const LOG_IN_ROUTE = '/login';
export const STYLE_GUIDE = '/style-guide';
export const ROADMAP_ROUTE = '/features';
export const REPORT_CENTER_ROUTE = '/report-center';
export const REPAIR_CENTER_ROUTE = '/repair-360';
export const REPAIR_CENTER_BOX_DETAIL_ROUTE = '/repair-360/box-detail/:boxNumber';
export const REPAIR_CENTER_INBOUND_BOX_DETAIL = '/repair-360/inbound-box-detail/:boxNumber';
export const REPAIR_CENTER_INHOUSE_DETAIL_ROUTE = '/repair-360/inHouse-detail/:ticketNumber';
export const REPAIR_INHOUSE_DETAIL_ROUTE = '/repair-360/inHouse-detail';
export const REPAIR_CENTER_TICKET_DETAIL_ROUTE = '/repair-360/ticket-detail/:ticketNumber';
export const REPAIR_TICKET_DETAILS_ROUTE = '/repair-360/ticket-detail';
export const REPAIR_BOX_DETAILS_ROUTE = '/repair-360/box-detail';
export const ACCOUNT_DASHBOARD_ROUTE = '/my-account';
export const RESOURCES_ROUTE = '/resources';
export const SUPPORT_ROUTE = '/support';
export const MY_BUILDINGS = '/my-buildings';
export const MY_PROFILE = '/my-profile';
export const MANAGE_DEVICES = '/devices';
export const DEVICE_INFO = '/devices/info/:serialNumber';
export const LANDING = '/landing';
export const LOGGED_OUT_ROUTE = '/logged-out';
export const USERS = '/users';
export const USER = '/users/:record_id';
export const ADMIN = '/admin';
export const FORBIDDEN_ACCESS = '/forbidden-access';
export const PRIVACY_POLICY = '/privacy-policy';
export const GOOGLE_USE_POLICY = '/google-use-policy';
export const TERMS_OF_SERVICE = '/terms-of-service';
export const HOME_LANDING_PAGE = '/';
export const BLOCKED_USER_PAGE = '/user-blocked';
export const INVOICES_ORDERS = '/invoices-orders';
