import baseAPIFactory, { handleTokenHeaders } from './base';

export interface NoteItem {
  node_id: string | number;
  note: string;
  author: number;
  full_name: string;
  note_date: any;
  visibility: string;
  repair_ticket: number | string;
  note_type: string;
}

export const getRepairNotes = async ({ ticketId, token }: { ticketId?: string | number; token?: string }) =>
  baseAPIFactory.get<NoteItem[]>(`/note/${ticketId}`, handleTokenHeaders({ token }));
