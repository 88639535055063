import baseAPIFactory, { handleTokenHeaders } from './base';
import type { TokenParams } from './base';

export type VivacityAdmiinfo = {
  record_id: number;
  user_id: number;
  account_id: number;
  created_at: number;
  fulfillment_date: number;
  request_type: string;
  message?: string;
};
export type VivacityAdminData = {
  user_id: number;
  account: number;
  fulfillment_date: Date | null;
  request_type: string;
  message?: string;
};

const END_POINTS = {
  ADD_ACCOUNT_REQUEST: () => `/vivacity-admin/`,
  ADD_ACCOUNT_PAID_SUBSCRIPTION_REQUEST: () => `/vivacity-admin/addPaidSubscriptionRequest`,
  DELETE_REQUEST: (recordId: any) => `vivacity-admin/deleteRequest/${recordId}`,
};

export const addAccountRequest = async (vivacityAdminData: VivacityAdminData, props: TokenParams) =>
  baseAPIFactory.post<VivacityAdmiinfo>(END_POINTS.ADD_ACCOUNT_REQUEST(), vivacityAdminData, {
    ...handleTokenHeaders(props),
  });

export const addPaidSubscriptionRequest = async (vivacityAdminData: VivacityAdminData, props: TokenParams) =>
  baseAPIFactory.post<VivacityAdmiinfo>(END_POINTS.ADD_ACCOUNT_PAID_SUBSCRIPTION_REQUEST(), vivacityAdminData, {
    ...handleTokenHeaders(props),
  });

export const deleteRequest = async (recordId: number, props: TokenParams) =>
  baseAPIFactory.delete(END_POINTS.DELETE_REQUEST(recordId), handleTokenHeaders(props));
