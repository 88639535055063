import { useEffect, useMemo } from 'react';
import { get, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { SUBSCRIPTION_TIER } from 'src/constants/data/paid-tier.constants';
import { useUserDetails } from '../../wrappers/UserDetailWrapper';
import RoleInfo, { PermissionReturn, ProxyReturnType, ReturnView } from './RoleInfo';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getDarklyFeatures, setDarklyFeatureValue } from '../../slices/commonSlice';
import { getBooleanFeatureFlagValue } from '../../utils/LaunchDarklyQuery';
import appLogger from '../../infrastructure/config/appLogger';

export {
  PAGE_NAMES,
  REPAIR_360_SUB_MODULE_MAP,
  USER_SUB_MODULE_MAP,
  RESOURCES_SUB_MODULE_MAP,
  ROLE_ACTIONS,
} from './constants';

const useRoleIdentity = (pageName?: string | null) => {
  const userInfo = useUserDetails();
  return useMemo((): ReturnView => {
    if (!isEmpty(userInfo.user) && pageName) {
      const roleName = get(userInfo.user, 'role_name') || '';
      return new RoleInfo(roleName, pageName);
    }
    return {
      canViewPage: () => false,
      checkPageName: () => false,
      handleMenuData: () => false,
      canView: () => false,
      canCreate: () => false,
      canModify: () => false,
      checkPermissions: () => ({
        canView: false,
        canCreate: false,
        canModify: false,
      }),
      defaultTabName: () => '',
      hasPermission: () => false,
    };
  }, [userInfo.user, pageName]);
};

export const useSubModuleRoleCheck = (pageName: string, subModule: string): PermissionReturn => {
  const roleIdentity = useRoleIdentity(pageName);
  return roleIdentity.checkPermissions(subModule);
};

export const useLocationParamRoleCheck = (): PermissionReturn => {
  const location = useLocation();
  const state = get(location, 'state') || {};
  if (!isEmpty(state)) {
    const pageName = get(state, 'module') || '';
    const subModule = get(state, 'subModule') || '';
    if (!isEmpty(pageName) && !isEmpty(subModule)) {
      return useSubModuleRoleCheck(pageName, subModule);
    }
  }
  return {
    canCreate: true,
    canView: true,
    canModify: true,
  };
};

export const useSubModuleProxyRoleCheck = (pageName: string, subModule: string): ProxyReturnType => {
  const roleIdentity = useRoleIdentity(pageName);
  return {
    permission: roleIdentity.checkPermissions(subModule),
    hasPermission: roleIdentity.hasPermission.bind(roleIdentity, subModule),
  };
};

export const useIsDarklyFeatureEnabled = (featureName: string) => {
  const currentFeatures = useAppSelector(getDarklyFeatures);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchFeatureFlag = async () => {
      const flagValue = await getBooleanFeatureFlagValue(featureName);
      dispatch(
        setDarklyFeatureValue({
          feature: featureName,
          value: flagValue,
        })
      );
    };

    fetchFeatureFlag().catch((e) => appLogger.error(e));
  }, [featureName]);
  return useMemo(() => !!get(currentFeatures, featureName), [currentFeatures, featureName]);
};

export const useCheckIfFeatureAndRoleEnabled = (featureName: string, roleName: string) => {
  const isFeatureEnabled = useIsDarklyFeatureEnabled(featureName);
  const userData = useUserDetails();
  return isFeatureEnabled && get(userData, 'user.role_name') === roleName;
};

export const useCheckIfUserHasTierPermission = (featureName: string, tier: string) => {
  const isFeatureEnabled = useIsDarklyFeatureEnabled(featureName);
  return !isFeatureEnabled || tier !== SUBSCRIPTION_TIER.STARTER;
};

export default useRoleIdentity;
