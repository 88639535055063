import { styled } from '@mui/material';
import logo from '../../assets/images/Vivacity_V_Purple.png';

const StyledLogo = styled('img')(({ theme }) => ({
  width: theme.spacing(6.25),
}));

const Logo = (props: any) => <StyledLogo {...props} src={logo} alt="logo" />;

export default Logo;
