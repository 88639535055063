export function transformErrorMessage(apiError: any, defaultText: string, override = false): string {
  return apiError.response?.data?.message && !override ? apiError.response.data.message : defaultText;
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '';
  // Remove any non-numeric characters from the input
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  // Apply the desired format
  const formattedNumber = `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(3, 6)}-${cleanedNumber.slice(6, 10)}`;

  return formattedNumber;
}

export function formatCurrency(amount: string): string {
  if (Number.isNaN(parseFloat(amount))) return `$0.00`;
  return `$${parseFloat(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}
export default transformErrorMessage;
