import baseAPIFactory, { handleTokenHeaders } from './base';
import ExternalUser from './types/externalUserRole';

export interface Student {
  record_id: number;
  full_name: string;
  title: string;
  email: string;
  building: number;
  student_id: string;
  student_status: string;
  last_modified_date: string;
  last_modified_by: number;
  created_by: number;
  external_user?: ExternalUser;
}
export interface StudentByAccount {
  record_id: number;
  full_name: string;
  title: string;
  email: string;
  building: number;
  student_id: string;
  student_status: string;
  last_modified_date: string;
  last_modified_by: number;
  created_by: number;
}
export type StudentInfo = {
  record_id: number;
  full_name: string;
  title: string;
  email: string;
  building: number;
  student_id: string;
  student_status: string;
};

export type CreateStudentData = {
  full_name: string;
  title: string;
  email: string;
  building: number;
  last_modified_date: string | Date;
  src_status: string;
  student_id: string;
  student_status: string;
  created_by: number;
  last_modified_by: number;
  token: string;
};

export type FetchParamTypesWithPage = {
  token: string;
  page?: number;
  per_page?: number;
  search_field?: string;
  search_value?: number | string;
  sort?: string;
};

const END_POINTS = {
  GET_STUDENT: (recordId: string | number) => `/student/${recordId}`,
  GET_STUDENT_by_Name: () => `/student/findByStudent`,
  GET_STUDENT_account: (accountNumber: string | number) => `/student/account/${accountNumber}`,
  CREATE_ONE: () => `/student`,
  FETCH_ALL: () => `/student`,
};

export const getStudentById = async (recordId: string | number, { token }: { token?: string }) =>
  baseAPIFactory.get<Student>(END_POINTS.GET_STUDENT(recordId), handleTokenHeaders({ token }));

export const createStudent = async (data: CreateStudentData) =>
  baseAPIFactory.post(END_POINTS.CREATE_ONE(), data, handleTokenHeaders(data));

export const getPaginatedListStudent = async (props: FetchParamTypesWithPage) => {
  const params: { [key: string]: any } = {};
  if (props.per_page) {
    params.per_page = props.per_page;
  }
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  return baseAPIFactory.get<Student[]>(END_POINTS.FETCH_ALL(), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const listStudents = async (token: string) =>
  baseAPIFactory.get<Student[]>(END_POINTS.CREATE_ONE(), handleTokenHeaders({ token }));

export const getStudentByAccount = async (token: string, accountNumber: string | number) =>
  baseAPIFactory.get<Student[]>(END_POINTS.GET_STUDENT_account(accountNumber), handleTokenHeaders({ token }));

export const findByStudent = async (data: any) => {
  const params: { [key: string]: any } = {};
  if (data.search_field) {
    params.search_field = data.search_field;
    params.search_value = data.search_value;
  }
  return baseAPIFactory.get<any>(END_POINTS.GET_STUDENT_by_Name(), {
    params,
    ...handleTokenHeaders(data),
  });
};
