import React from 'react';
import Typography from '@mui/material/Typography';
import { DefaultButton } from '../StyledComponent';

type Props = {
  refreshAuth?: () => void;
};

const ErrorCard = ({ refreshAuth = () => null }: Props) => (
  <>
    <Typography variant="h5" gutterBottom sx={{ mt: 5 }}>
      Something went wrong
    </Typography>
    {refreshAuth && (
      <DefaultButton sx={{ mt: 3 }} onClick={refreshAuth} variant="contained">
        Try Again ?
      </DefaultButton>
    )}
  </>
);

export default ErrorCard;
