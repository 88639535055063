export const PER_PAGE_RECORD = 12;
export const PER_PAGE_RECORD_DEFAULT = 10;
export const PER_PAGE_RECORD_100 = 100;
export const PER_PAGE_RECORD_ALL = 500;
export const externalUserTable = 'external_user';

export const DEVICE_FILTER_FIELDS = [
  {
    id: 1,
    label: 'Serial Number',
    value: 'serial_number',
  },
  {
    id: 2,
    label: 'Asset Tag',
    value: 'asset_tag',
  },
  {
    id: 3,
    label: 'Student',
    value: 'student',
  },
  {
    id: 4,
    label: 'Device',
    value: 'device',
  },
  {
    id: 5,
    label: 'Organizational Unit',
    value: 'organizational_unit',
  },
];
export const FILTER_FIELDS = [
  {
    id: 1,
    label: 'Full Name',
    value: 'full_name',
  },
  {
    id: 2,
    label: 'Email',
    value: 'email',
  },
  {
    id: 3,
    label: 'Title',
    value: 'title',
  },
  {
    id: 4,
    label: 'Phone',
    value: 'phone',
  },
];

export const FILTER_FIELDS_BUILDING = [
  {
    id: 1,
    label: 'Building Name',
    value: 'building_name',
  },
  {
    id: 2,
    label: 'Phone',
    value: 'phone',
  },
];

export const FILTER_OPERATORS = [
  {
    id: 1,
    label: 'Contains',
    value: 'contains',
  },
  {
    id: 2,
    label: 'Does not contain',
    value: 'does_not_contain',
  },
];

export const ACCOUNT_TEMPLATE = {
  account_name: '',
  account_number: '',
  phone_number: '',
  email: '',
  domain: '',
  credit_limit: '',
  account_credit: '',
  account_balance: '',
  account_manager: '',
  account_site: '',
  in_house_repairs: '',
  territory: '',
  payment_terms: 'Special Terms',
  netsuite_id: '',
  account_paid_tier: 'Starter',
  subscription_tier: 'Starter',
};
export const ACCOUNT_REQUIRED_HEADERS = [
  { id: 1, title: 'Account Name *', key: 'account_name' },
  { id: 2, title: 'Account Number*', key: 'account_number' },
  { id: 3, title: 'Phone Number *', key: 'phone_number' },
  { id: 4, title: 'Email *', key: 'email' },
  { id: 5, title: 'Domain *', key: 'domain' },
  { id: 6, title: 'Credit Limit', key: 'credit_limit' },
  { id: 7, title: 'Account Credit', key: 'account_credit' },
  { id: 8, title: 'Account Manager', key: 'account_manager' },
  { id: 9, title: 'Account Site', key: 'account_site' },
  { id: 10, title: 'Territory', key: 'territory' },
  { id: 11, title: 'In House Repairs *', key: 'in_house_repairs' },
  { id: 12, title: 'Payment Terms *', key: 'payment_terms' },
  { id: 13, title: 'Netsuite Id *', key: 'netsuite_id' },
  { id: 14, title: 'Account Paid Tier', key: 'account_paid_tier' },
  { id: 15, title: 'Subscription Tier', key: 'subscription_tier' },
  { id: 16, title: 'Account Balance', key: 'account_balance' },
];
export const ACCOUNT_TABLEDATA: Record<string, any>[] = [
  {
    id: 1,
    account_name: 'Test Account',
    account_number: '1',
    email: 'johndoe@vivecity.com',
    phone_number: '(555) 435-2332',
    domain: 'Test',
    in_house_repairs: 'true',
    payment_terms: 'Special Terms',
    credit_limit: '100000',
    account_credit: '500',
    account_balance: '1000000',
    account_manager: '1',
    account_site: '1',
    territory: '1',
    netsuite_id: '1234',
    account_paid_tier: 'Starter',
    subscription_tier: 'Starter',
  },
];

export const PAYMENT_TERMS = [
  { id: 1, title: 'Net 30 Days' },
  { id: 2, title: 'Net 60 Days' },
  { id: 3, title: 'Prepayment' },
  { id: 4, title: 'Special Terms' },
];
export const ACCOUNT_PAID_TIER = [
  { id: 1, title: 'Starter' },
  { id: 2, title: 'Professional' },
  { id: 3, title: 'Premium(Only for Account Paid Tier)' },
  { id: 4, title: 'Enterprise(Only for Subscription Tier)' },
];
export const BUILDING_TEMPLATE = [
  {
    building_name: '',
    default_building: '',
    phone: '',
    extension: '',
    building_abbreviation: '',
    shipping_address_1: '',
    shipping_address_2: '',
    shipping_city: '',
    shipping_us_state: '',
    shipping_zip: '',
    billing_address_1: '',
    billing_address_2: '',
    billing_city: '',
    billing_us_state: '',
    billing_zip: '',
  },
];

export const ACCOUNT_MAIN_TABLE = [
  { id: 1, title: 'A' },
  { id: 2, title: 'B' },
  { id: 3, title: 'C' },
  { id: 4, title: 'D' },
  { id: 5, title: 'E' },
  { id: 6, title: 'F' },
  { id: 7, title: 'G' },
  { id: 8, title: 'H' },
  { id: 9, title: 'I' },
  { id: 10, title: 'K' },
  { id: 11, title: 'L' },
  { id: 12, title: 'M' },
  { id: 13, title: 'N' },
  { id: 14, title: 'O' },
  { id: 15, title: 'P' },
  { id: 16, title: 'Q' },
];
export const BUILDING_MAIN_TABLE = [
  { id: 1, title: 'A' },
  { id: 2, title: 'B' },
  { id: 3, title: 'C' },
  { id: 4, title: 'D' },
  { id: 5, title: 'E' },
  { id: 6, title: 'F' },
  { id: 7, title: 'G' },
  { id: 8, title: 'H' },
  { id: 9, title: 'I' },
  { id: 10, title: 'J' },
  { id: 11, title: 'K' },
  { id: 12, title: 'L' },
  { id: 13, title: 'M' },
  { id: 14, title: 'N' },
  { id: 15, title: 'O' },
];

export const BUILDING_TEMPLATE_MAIN = [
  { id: '1', title: 'Building Name', key: 'building_name', required: true, regex: '^.{1,255}$' },
  { id: '2', title: 'Default Building', key: 'default_building', required: true },
  { id: '3', title: 'Phone', key: 'phone', required: true, regex: '^.{1,20}$' },
  { id: '4', title: 'Extension', key: 'extension', required: false, regex: '^.{0,20}$' },
  {
    id: '5',
    title: 'Building Abbreviation',
    key: 'building_abbreviation',
    required: false,
    regex: '^.{0,20}$',
  },
  { id: '6', title: 'Shipping Address', key: 'shipping_address_1', required: true, regex: '^.{1,35}$' },
  { id: '7', title: 'Shipping Address 2', key: 'shipping_address_2', required: false, regex: '^.{0,35}$' },
  { id: '8', title: 'Shipping City', key: 'shipping_city', required: true, regex: '^.{1,30}$' },
  {
    id: '9',
    title: 'Shipping State',
    key: 'shipping_us_state',
    required: true,
    regex:
      '^(?:AL|AK|AZ|AR|CA|CO|CT|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY|NE)$',
  },
  { id: '10', title: 'Shipping Zip', key: 'shipping_zip', required: true, regex: '^.{5,10}$' },
  { id: '11', title: 'Billing Address', key: 'billing_address_1', required: true, regex: '^.{1,35}$' },
  { id: '12', title: 'Billing Address 2', key: 'billing_address_2', required: false, regex: '^.{0,35}$' },
  { id: '13', title: 'Billing City', key: 'billing_city', required: true, regex: '^.{1,30}$' },
  {
    id: '14',
    title: 'Billing State',
    key: 'billing_us_state',
    required: true,
    regex:
      '^(?:AL|AK|AZ|AR|CA|CO|CT|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY|NE)$',
  },
  { id: '15', title: 'Billing Zip', key: 'billing_zip', required: true, regex: '^.{5,10}$' },
];

export const BUILDING_DATA = [
  {
    building_name: 'Demo Building',
    phone: '(555) 435-2332',
    default_building: 'true/false',
    extension: '123',
    building_abbreviation: 'DB',
    shipping_address_1: '10 Ben Hamby Drive',
    shipping_address_2: '',
    shipping_city: 'Greenville',
    shipping_zip: '29303',
    shipping_us_state: 'SC',
    billing_address_1: '10 Ben Hamby Drive',
    billing_address_2: '',
    billing_city: 'Greenville',
    billing_zip: '29303',
    billing_us_state: 'SC',
  },
];

export const BUILDING_CONSTANT = [
  'billing_us_state',
  'shipping_us_state',
  'billing_country',
  'shipping_country',
  'billing_zip',
  'shipping_zip',
];

export const SERVICE_ACCOUNT_STATES = {
  NOT_SETUP: 'NOT_SETUP',
  HEALTHY: 'HEALTHY',
  ERRORED: 'ERRORED',
  SYNCING: 'SYNCING',
  DISABLED: 'DISABLED',
};
