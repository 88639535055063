import baseAPIFactory, { commonThenResult, handleTokenHeaders } from '../base';
import type { TokenParams } from '../base';

export interface RegisterAuth {
  id: number;
}

export interface AuthData {
  record_id: number;
  user_id: number;
  logged_in_id?: string;
  email: string;
  token: string;
  full_name: string;
  profile_image: string;
  has_token: boolean;
  role_name: string | null;
  user_status: string;
  requested_at: string;
  is_gac?: boolean;
  account: number;
  user_type: string;
  account_number: string | number;
  allow_user?: boolean;
}

export interface ModifyType {
  lastDate: string;
}

export interface UserData {
  two_factor_authentication?: boolean;
  record_id?: number;
  full_name: string;
  title: string | null;
  email: string;
  phone: string;
  account: number;
  profile_image: any | null;
  user_status: string | null;
  user_role: number;
  role_record_id?: number;
  user_account: number | null;
  in_house_repair: boolean | null;
  google_id?: string;
  isLoading?: boolean;
  building_name?: string;
  building_record_id?: number;
  role_name?: string;
  building?: number;
}

export type CHANGE_PASSWORD_DATA = {
  old_password: string;
  password: string;
};

export interface WarrantyType {
  record_id: number;
  warranty_provider: number;
  sku: string;
  warranty_type: string;
  warranty_description: string;
  price_per_device: string;
  years: number;
  months: number;
  battery_year: number;
  battery_month: number;
  warranty_name: null | number | string;
}

export type FetchParamTypesWithPage = {
  token: string;
  page?: number;
  per_page?: number;
  search_field?: string;
  search_value?: number | string;
  sort?: string;
};

export type FetchParamTypesWithNewUser = {
  token: string;
  page?: number;
  search_field?: string;
  search_value?: number | string;
  sort?: string;
};

const END_POINTS = {
  EXTERNAL_USER: (accountNumber: number, recordId: number) => `/customer/${accountNumber}/external-user/${recordId}`,
  REGISTER_AUTH_DATA: () => '/auth/logged-in-session',
  GET_USER_AUTH_DATA: () => '/user/auth-data',
  GET_ALL_USERS: () => '/user',
  UPDATE_USERS_IN_BULK: () => '/user/bulk',
  CHANGE_USER_PASSWORD: () => `/user/change-password`,
  CHANGE_ANOTHER_USER_PASSWORD: () => `/user/change-password`,
  DELETE_USER: (recordId: any) => `/user/${recordId}`,
  WARRANTY_USER: () => '/warranty/all',
  GET_NEW_USER: () => '/user/new-users',
  USERS_BY_ACCOUNT: (recordId: any) => `/user/get_users/${recordId}`,
  USERS_BY_ACCOUNT_AG: (recordId: any) => `/user/get_users_ag/${recordId}`,
  POST_WARRANTY: () => '/warranty',
  UPDATE_WARRANTY: (recordId: any) => `/warranty/${recordId}`,
  UPDATE_REQUEST_USER: (recordId: any) => `/user/update_account_id/${recordId}`,
  DELETE_WARRANTY: (recordId: any) => `/warranty/${recordId}`,
  POST_BULK_WARRANTY: () => '/fleet_device/bulk',
  POST_BULK_WARRANTY_ADMIN: () => '/warranty/bulk',
  CHANGE_ROLE: (recordId: any) => `/user/change_user_role/${recordId}`,
  GET_ALL_WARRANTY_ADMIM: () => '/warranty/getAllWarranty',
  GET_ALL_NEW_USER: () => '/user/allNewUsers',
};

export const registerAuthData = (data: any) => baseAPIFactory.post<RegisterAuth>(END_POINTS.REGISTER_AUTH_DATA(), data);
export const getUserAuthData = async (token: string) => {
  const response = await baseAPIFactory.get<AuthData>(END_POINTS.GET_USER_AUTH_DATA(), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return commonThenResult<AuthData>(response);
};
export const getAllUsers = async (props: TokenParams) => {
  const users = await baseAPIFactory.get<UserData[]>(END_POINTS.GET_ALL_USERS(), {
    ...handleTokenHeaders(props),
  });
  return users.data;
};
export const updateUsersInBulk = async (users: UserData[], props: TokenParams) => {
  const response = await baseAPIFactory.post(
    END_POINTS.UPDATE_USERS_IN_BULK(),
    { users },
    {
      ...handleTokenHeaders(props),
    }
  );
  return response.status;
};

export const changeUserPassword = async (data: CHANGE_PASSWORD_DATA, props: TokenParams) => {
  const response = await baseAPIFactory.post(END_POINTS.CHANGE_USER_PASSWORD(), data, {
    ...handleTokenHeaders(props),
  });
  return response;
};

export type CHANGE_ANOTHER_PASSWORD = {
  password: string;
};

export const changeAnotherUserPassword = async (data: CHANGE_ANOTHER_PASSWORD, props: TokenParams) => {
  const response = await baseAPIFactory.post(END_POINTS.CHANGE_ANOTHER_USER_PASSWORD(), data, {
    ...handleTokenHeaders(props),
  });
  return response;
};

export const deleteUserData = async (data: string | number | null, props: TokenParams) => {
  const response = await baseAPIFactory.delete(END_POINTS.DELETE_USER(data), {
    ...handleTokenHeaders(props),
  });
  return response;
};

export const fetchWarranty = async (props: FetchParamTypesWithPage) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  return baseAPIFactory.get<WarrantyType[]>(END_POINTS.WARRANTY_USER(), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const fetchAllWarranty = async ({
  offset,
  filterName,
  token,
}: {
  offset: number;
  filterName?;
  token?: string;
}) =>
  baseAPIFactory.get<any>(`${END_POINTS.WARRANTY_USER()}/${offset}/${filterName}`, {
    ...handleTokenHeaders({ token }),
  });

export const fetchAllProvider = async ({ token }: { token?: string }) =>
  baseAPIFactory.get<any>(`warranty/provider/`, {
    ...handleTokenHeaders({ token }),
  });

export const fetchNewUsers = async (props: FetchParamTypesWithNewUser) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.search_field && props.search_value) {
    params.search_field = props.search_field;
    params.search_value = props.search_value;
  }
  return baseAPIFactory.get<any[]>(END_POINTS.GET_NEW_USER(), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const UsersByAccount = async ({ token, recordId }: { token?: string; recordId: string | number }) =>
  baseAPIFactory.get<any[]>(END_POINTS.USERS_BY_ACCOUNT(recordId), handleTokenHeaders({ token }));

export const UsersByAccountAG = async ({
  token,
  recordId,
  params,
}: {
  token?: string;
  recordId: string | number;
  params?: any;
}) =>
  baseAPIFactory.get<any[]>(END_POINTS.USERS_BY_ACCOUNT_AG(recordId), {
    params,
    ...handleTokenHeaders({ token }),
  });

export const createWarranty = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.POST_WARRANTY(), data, handleTokenHeaders({ token }));

export const updateWarranty = async (data: any, recordId: number | string, token: string) =>
  baseAPIFactory.put(END_POINTS.UPDATE_WARRANTY(recordId), data, handleTokenHeaders({ token }));

export const updateRequestUser = async (email: string, data: any, token: string) =>
  baseAPIFactory.put(END_POINTS.UPDATE_REQUEST_USER(email), data, handleTokenHeaders({ token }));

export const updateUserRole = async (recordId: number | string, data: any, token: string) =>
  baseAPIFactory.put(END_POINTS.CHANGE_ROLE(recordId), data, handleTokenHeaders({ token }));

export const deleteWarranty = async ({ token, recordId }: { token: string; recordId: string | number }) =>
  baseAPIFactory.delete(END_POINTS.DELETE_WARRANTY(recordId), handleTokenHeaders({ token }));

export const createBulkDevice = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.POST_BULK_WARRANTY(), data, handleTokenHeaders({ token }));

export const createBulkWarranty = async (data: any, token: string) =>
  baseAPIFactory.post(END_POINTS.POST_BULK_WARRANTY_ADMIN(), data, handleTokenHeaders({ token }));

export const getUser = async (accountNumber: number, recordId: number, token: string) =>
  baseAPIFactory.get(END_POINTS.EXTERNAL_USER(accountNumber, recordId), handleTokenHeaders({ token }));

export const updateUser = async (accountNumber: number, recordId: number, data: any, token: string) =>
  baseAPIFactory.put(END_POINTS.EXTERNAL_USER(accountNumber, recordId), data, handleTokenHeaders({ token }));

export const getAllAdminWarranty = async ({ token }: { token: string }) =>
  baseAPIFactory.get(END_POINTS.GET_ALL_WARRANTY_ADMIM(), handleTokenHeaders({ token }));

export const getAllAdminNewUser = async ({ token }: { token: string }) =>
  baseAPIFactory.get(END_POINTS.GET_ALL_NEW_USER(), handleTokenHeaders({ token }));
