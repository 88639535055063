import baseAPIFactory, { handleTokenHeaders } from '../base';
import { UserData } from './user';

export interface UserGoogleData extends UserData {
  id: string;
  primaryEmail: string;
  hashFunction: string;
  isAdmin: boolean;
  isDelegatedAdmin: boolean;
  agreedToTerms: boolean;
  suspended: boolean;
  changePasswordAtNextLogin: boolean;
  ipWhitelisted: boolean;
  name: UserName;
  kind: string;
  etag: string;
  emails: Email[];
  externalIds: ExternalId[];
  relations: Relation[];
  aliases: [string];
  isMailboxSetup: boolean;
  customerId: string;
  addresses: Address[];
  organizations: Organization[];
  lastLoginTime: string;
  phones: Phone[];
  suspensionReason: string;
  thumbnailPhotoUrl: string;
  languages: Language[];
  creationTime: string;
  nonEditableAliases: [string];
  notes: Note[];
  websites: Website[];
  locations: Location[];
  includeInGlobalAddressList: boolean;
  keywords: Keyword[];
  deletionTime: string;
  gender: Gender;
  thumbnailPhotoEtag: string;
  customSchemas: any;
  isEnrolledIn2Sv: boolean;
  isEnforcedIn2Sv: boolean;
  archived: boolean;
  orgUnitPath: string;
  orgUnitId?: string;
  recoveryEmail: string;
  recoveryPhone: string;
}

interface UserName {
  fullName: string;
  familyName: string;
  givenName: string;
  displayName: string;
}

interface Email {
  address: string;
  customType: string;
  primary: string;
  type: string;
}

interface ExternalId {
  customType: string;
  type: string;
  value: string;
}

interface Relation {
  customType: string;
  type: string;
  value: string;
}

interface Address {
  country: string;
  countryCode: string;
  customType: string;
  extendedAddress: string;
  formatted: string;
  locality: string;
  poBox: string;
  postalCode: string;
  primary: string;
  region: string;
  sourceIsStructured: string;
  streetAddress: string;
  type: string;
}

interface Organization {
  constCenter: string;
  customType: string;
  department: string;
  description: string;
  domain: string;
  fullTimeEquivalent: number;
  location: string;
  name: string;
  primary: boolean;
  symbol: string;
  title: string;
  type: string;
}

interface Phone {
  customType: string;
  primary: boolean;
  type: string;
  value: string;
}

interface Language {
  customLanguage: string;
  languageCode: string;
  preference: string;
}

interface Note {
  contentType: string;
  value: string;
}

interface Website {
  customType: string;
  primary: boolean;
  type: string;
  value: string;
}

interface Location {
  area: string;
  buildingId: string;
  customType: string;
  deskCode: string;
  floorName: string;
  floorSection: string;
  type: string;
}

interface Keyword {
  customType: string;
  type: string;
  value: string;
}

interface Gender {
  addressMeAs: string;
  customGender: boolean;
  type: string;
}

const END_POINTS = {
  USER_GOOGLE_DATA: (accountNumber: number, recordId: number) =>
    `/customer/${accountNumber}/external-user/${recordId}/google-data`,
  ORG_UNITS: (accountNumber: number) => `/customer/${accountNumber}/organizational-units`,
};

export const getUserGoogleData = async (accountNumber: number, recordId: number, token: string) =>
  baseAPIFactory.get(END_POINTS.USER_GOOGLE_DATA(accountNumber, recordId), handleTokenHeaders({ token }));

export const getOrgUnits = async (accountNumber: number, token: string) =>
  baseAPIFactory.get(END_POINTS.ORG_UNITS(accountNumber), handleTokenHeaders({ token }));

export const updateUserGoogleData = async (
  accountNumber: number,
  recordId: number,
  token: string,
  data: Partial<UserGoogleData>
) => baseAPIFactory.put(END_POINTS.USER_GOOGLE_DATA(accountNumber, recordId), data, handleTokenHeaders({ token }));
