import baseAPIFactory, { handleTokenHeaders } from './base';
import { TransformedQueryResult } from './types/query';

export interface TicketAccount {
  record_id: number;
  ticket_number: string;
  problem_description: string;
  repair_type: string;
  failure_type: string;
  student_technician: number;
  last_modified_date: string;
  complete_date: string;
  technician: number;
  repair_note: string;
  repair_status: string;
  last_modified_by: number;
  created_by: number;
  repair_box: number;
  device: string;
  fleet_device: number;
}

export enum RepairStatusOptions {
  BoxCreated = 'Box Created',
  ClaimEstimateApproved = 'Claim Estimate Approved',
  ClaimEstimateDenied = 'Claim Estimate Denied',
  ClaimOverage = 'Claim Overage',
  ClaimDOA = 'Claim-DOA',
  ClaimLaborOnly = 'Claim-Labor Only',
  ClaimOnHold = 'Claim-On Hold',
  Closed = 'Closed',
  Complete = 'Complete',
  DeniedRecycle = 'Denied - Recycle',
  DidNotArrive = 'Did Not Arrive',
  EstimateApproved = 'Estimate Approved',
  EstimateDenied = 'Estimate Denied',
  InQAQueue = 'In QA Queue',
  InRepairQueue = 'In Repair Queue',
  InHouse = 'In-House',
  Intake = 'Intake',
  IntakeQueue = 'Intake Queue',
  InTransit = 'In Transit',
  OnHold = 'On Hold',
  PartOrdered = 'Part Ordered',
  PartQueue = 'Part Queue',
  PartReceived = 'Part Received',
  PendingRepairBox = 'Pending Repair Box',
  ProcessingRequest = 'Processing Request',
  QAInProgress = 'QA in Progress',
  QualityAssurance = 'Quality Assurance',
  Received = 'Received',
  ReceivedByVivacity = 'Received By Vivacity',
  Repair = 'Repair',
  RepairComplete = 'Repair Complete',
  RepairInProgress = 'Repair in Progress',
  ShippingPkgMaterialToCustomer = 'Shipping Pkg Material to Customer',
  ShippingToCustomer = 'Shipping to Customer',
  ShippingToVivacity = 'Shipping to Vivacity',
  SolderQueue = 'Solder Queue',
  WaitingOnBreakdownPart = 'Waiting on Breakdown Part',
  WaitingOnBufferStock = 'Waiting on Buffer Stock',
  WaitingOnClaimApproval = 'Waiting on Claim Approval',
  WaitingOnClaimPart = 'Waiting on Claim Part',
  WaitingOnDeprovision = 'Waiting on Deprovision',
  WaitingOnPartApproval = 'Waiting on Part Approval',
  WaitingOnPartPurchase = 'Waiting on Part Purchase',
  WaitingOnParts = 'Waiting on Parts',
  WaitingOnRepairApproval = 'Waiting on Repair Approval',
  WaitingOnTransferOrder = 'Waiting on Transfer Order',
}

export interface NoBoxTickets {
  record_id: number;
  ticket_number: string;
  asset_tag: null | string;
  repair_type: string;
  serial_number: string;
  created_by: string;
  building: string;
}
export interface TicketBuilding {
  record_id: number;
  ticket_number: string;
  problem_description: string;
  repair_type: string;
  failure_type: string;
  student_technician: number;
  last_modified_date: string;
  complete_date: string;
  technician: number;
  repair_note: string;
  repair_status: string;
  last_modified_by: number;
  created_by: number;
  repair_box: number;
  fleet_device: number;
}

export interface RepairBox {
  ticket_number: string | number | null;
  problem_description: string | null;
  repair_type: string | null;
  failure_type: string | null;
  last_modified_date: string | null;
  complete_date: string | null;
  technician: number | null;
  repair_note: string | null;
  repair_status: string | null;
  last_modified_by: string | number | null;
  created_by?: string | number | null;
  repair_box: string | number | null;
  internal_technician: string | number | null;
  student_technician: string | number | null;
  fleet_device: string | number | null;
  token?: string;
  intake_note?: string | null;
  chromebook_issue?: string | null;
  closing_note?: string | null;
  created_date?: string | null;
  shipping_box_status?: string | null;
}

export interface UpdateTicket {
  ticket_number: string | null;
  repair_type: string | null;
  failure_type: string | null;
  warranty_status: string | null;
  building?: null | string;
  last_modified_date: string | null;
  complete_date: string | null;
  technician: number | null;
  repair_note: string | null;
  repair_status: string | null;
  last_modified_by: number | null;
  internal_technician: number | null;
  created_by: number | null;
  repair_box: number | null;
  fleet_device: number | null;
  student_technician: null;
  closing_note: string | null;
  intake_note: string | null;
  chromebook_issue: string | null;
  outbound_address: string | null;
  outbound_box: null | string;
  outbound_tracking: null | string;
  in_house_status: string | null;
  token?: string;
}

export interface UpdateTicketWorkflow {
  ticket_number: string | null;
  last_modified_date: string | null;
  complete_date: string | null;
  repair_note: string | null;
  closing_note: string | null;
  intake_note: string | null;
  in_house_status: string | null;
  token?: string;
}

export interface TicketContent {
  record_id?: number | null;
  shipped_from_date?: string | null;
  shipped_to_date?: string | null;
  eta?: string | null;
  shipping_from_cost?: string | null;
  shipping_to_cost?: string | null;
  weight?: number | null;
  height?: number | null;
  depth?: number | null;
  width?: number | null;
  carrier_from?: number | null;
  carrier_to?: number | null;
  building?: number | null;
  box_type: string;
  tracking_number_from?: string | null;
  tracking_number_to?: string | null;
  tracking_link_from?: string | null;
  tracking_link_to?: string | null;
  shipping_box_status?: string | null;
  label_from?: {
    type?: string | null;
    data?: object | null;
  };
  label_to?: {
    type?: string | null;
    data?: object | null;
  };
  shipping_and_handeling_fee?: string | null;
  token?: string;
  office_site?: string | number | null;
}

export interface TicketData {
  record_id: number;
  netsuite_record_id: number | null;
  ticket_number: string;
  asset_tag: string;
  product: string;
  serial_number: string;
  problem_description: string;
  repair_type: string;
  failure_type: string;
  student_technician: number;
  last_modified_date: string;
  complete_date: string;
  technician: number;
  repair_note: string;
  repair_status: string;
  last_modified_by: number;
  created_by: number;
  repair_box: number;
  fleet_device: number;
  internal_technician: number | null;
  closing_note: string;
  intake_note: string;
  base_warranty_start_date: string;
  base_warranty_end_date: string;
  advanced_warranty_start_date: string;
  advanced_warranty_end_date: string;
  asp_website: string | null | undefined;
  base_provider: number;
  advanced_provider: number;
  building_id: number | null;
  building: string;
  base: string;
  advanced: string;
  account: number | null;
  in_house_status: string;
  prn_ups: number | null;
}

export interface DeviceDetails {
  serial_number: string;
  building: number;
  import_number: number;
  device: number;
  base_warranty: number;
  base_warranty_start_date: string;
  base_warranty_end_date: string;
  advanced_warranty: number;
  advanced_warranty_end_date: string;
  advanced_warranty_start_date: string;
  asset_tag: string;
  student: number;
  loaner_status: string;
  created_date: string;
  last_modified_by: number;
  created_by: number;
}

export interface UserData {
  full_name: string;
  title: string | null;
  email: string;
  phone: string;
  account: number;
  profile_image: string | null;
  user_status: string | null;
  user_role: number;
  user_account: number | null;
  in_house_repair: boolean | null;
}

export interface FilterItemValue {
  id?: string | number;
  fieldName: string;
  fieldOp: string;
  fieldValue: string;
}

export interface RepairPart {
  record_id: number;
  part: number;
  repair_ticket: number;
  repair_issue: string;
  labor_hours: number;
  quantity: number;
  repair_cost: number;
  warranty_status: string;
  warranty_note: string;
  approval_status: string;
  diagnostic_fee: number;
}

export interface RepairImage {
  record_id: number;
  repair_image: string;
  repair_ticket: number;
  image_description: string;
  created_by: number;
  created_date: string;
}

export interface FilterStepValues {
  ticketNumber: string;
  serialNumber: string;
  assetTag: string;
  studentInfo: string;
  fieldOperations: FilterItemValue[];
}

export interface RepairInvoice {
  record_id: number;
  technician: number;
  ticket_number: string;
  fleet_device: number;
  invoice_date: string;
  quantity: number;
  repair_cost: string;
  warranty_status: string;
  full_name: string;
  email: string;
  device_name: string;
}

export interface WorkflowTicket {
  record_id: number;
  internal_technician: string;
  ticket_number: string;
  repair_type: string;
  fleet_device: string;
  failure_type: string;
  last_modified_date: string | null;
  complete_date: string | null;
  student_technician: string | null;
  technician: string | null;
  repair_note: string | null;
  repair_status: string | null;
  in_house_status: string | null;
  created_date: string | null;
  created_by: string | null;
  last_modified_by: string | null;
  outbound_address: number | null;
  repair_box: number;
}

export interface WorkflowData {
  [key: string]: {
    records: WorkflowTicket[];
    total_count: number;
  };
}

export interface RepairTicket {
  record_id?: string | number;
  ticket_number?: string;
  repair_type: string;
  failure_type: string;
  last_modified_date?: string | Date;
  complete_date?: string | null;
  technician: string | null;
  repair_note: string | null;
  internal_technician: string | null;
  repair_status: string;
  last_modified_by: string | null;
  created_by: string | null;
  repair_box: string | null | number;
  fleet_device: string | null;
  student_technician: string | null;
  closing_note?: string | null;
  intake_note?: string | null;
  chromebook_issue: string;
  created_date?: string | Date;
  building?: string | null;
  serial_number?: string;
  asset_tag?: string;
  sku?: string;
  token?: string;
}
export interface UpdateStatus {
  ticket_number: string;
  last_modified_date: string;
  repair_status: string;
  token?;
}
export interface LineItem {
  part: string | null;
  repair_ticket?: number | string;
  repair_issue: string | null;
  labor_hours?: number | null;
  quantity: number | null;
  repair_cost: number | null;
  warranty_status: string | null;
  warranty_note: string | null;
  approval_status: string | null;
  diagnostic_fee: number | null;
  note: string;
  token?: string;
}

export interface FetchFromLabel {
  repairBoxId: number;
  token?: string;
}

export interface RepairImages {
  repair_image: any;
  repair_ticket: number | string;
  image_description: string | null;
  created_by: string | null;
  created_date: string | Date;
}

export type FetchParamTypesWithPage = {
  token: string;
  page?: number;
  search_field?: string;
  search_value?: string;
};

export type ASPWebsite = {
  token: string;
  asp_website: string | null;
  provider_name: string | null;
};

export type FromLabelString = {
  encoded64Data: string;
};

const END_POINTS = {
  REDUCE_CUSTOM_PART_QUANTITY: (recordId: string | number) => `custom_part/reduceQuantity/${recordId}`,
  FETCH_ONE_BOX: (recordId: string | number | undefined) => `/repair_box/${recordId}`,
  FETCH_WORKFLOW: (accountNum: string | number | null) => `/repair_ticket/inHouseWorkflow/${accountNum}`,
  FETCH_ALL: (accountNum: string | number | null, boxType: string, offset: number, search: string | undefined) =>
    `/repair_box/account/${accountNum}/${boxType}/${offset}/${search}`,
  FETCH_ALL_TICKETS_NO_BOX: (accountNum?: number) => `/repair_box/tickets/no_box/${accountNum}`,
  GET_TICKET: (accountNum: string | number | null, repairType: string | null) =>
    `repair_ticket/account/${accountNum}/${repairType}`,
  GET_ONE_BY_TICKET_NUMBER: (ticketNumber: string) => `repair_ticket/${ticketNumber}`,
  GET_ALL_PARTS_BY_TICKET_NUMBER: (recordId: number) => `repair_part_used/all/${recordId}`,
  GET_ALL_IMAGES_BY_TICKET_ID: (recordId: number) => `repair_image/all/${recordId}`,
  GET_BUILDING: (itemId?: string | number | null) => `repair_ticket/building/${itemId}`,
  CREATE_BOX: (routeSuffix?: string) => `repair_ticket/${routeSuffix ?? ''}`,
  UPDATE_TICKET: (ticketNumber: string | number | null) => `repair_ticket/${ticketNumber}`,
  UPDATE_TICKET_WORKFLOW: (ticketNumber: string | number | null) => `repair_ticket/workflow/${ticketNumber}`,
  UPDATE_IN_HOUSE_TICKET: (ticketNumber: string | number | null) => `repair_ticket/repair/in_house/${ticketNumber}`,
  CREATE_TICKET: () => '/repair_ticket/in_house',
  CREATE_STUDENT_TICKET: () => '/repair_ticket/createStudent',
  GET_TICKET_INVOICE_DETAILS: (ticketNumber: string) => `repair_ticket/pdfTicketDetails/${ticketNumber}`,
  CREATE_BOX_DETAILS: () => 'repair_box/',
  FETCH_ALL_BY_ACCOUNT: (accountId: string | number) => `/fleet_device/account/${accountId}`,
  FETCH_LINE_ITEM_BY_TICKET: (repairId: string | number | undefined) => `/repair_part_used/all/${repairId}`,
  GET_USERS: () => '/user/',
  FETCH_ALL_IMAGES_BY_TICKET: (repairId: string | number | undefined) => `/repair_image/all/${repairId}`,
  FETCH_ALL_TICKETS_BY_BOX: (boxId: string | number | undefined) => `/repair_box/tickets/${boxId}`,
  FETCH_ALL_TICKETS_BY_INBOUND_BOX: (boxId: string | number | undefined) => `/repair_box/tickets/inbound/${boxId}`,
  UPDATE_STATUS: (ticketNumber: string | number | null) => `repair_ticket/update/updateStatus/${ticketNumber}`,
  UPDATE_LINE_ITEMS: () => `repair_part_used/`,
  FETCH_ONE_TICKET_NUMBER: (ticketNumber: string | number | undefined) => `/repair_ticket/${ticketNumber}`,
  FETCH_ALL_TICKET_SEARCH_CRITERIA: (
    filteredValue: string | any,
    statusFilter: string,
    searchSelect: string,
    accountId: string | number,
    repairType: string
  ) => `/repair_ticket/ticket_number/${filteredValue}/${statusFilter}/${searchSelect}/${accountId}/${repairType}`,
  DELETE_TICKETS: (ticketNumber: string | number) => `repair_ticket/${ticketNumber}`,
  DELETE_TEST_TICKETS: (ticketNumber: string | number) => `test-repairs/delete/${ticketNumber}`,
  FETCH_ALL_TICKETS_BY_ACCOUNT: (accountNum?: number) => `/repair_ticket/account/${accountNum}`,
  FETCH_ALL_BOXES_BY_ACCOUNT: (accountNum?: number) => `/repair_box/account/${accountNum}/all`,
  FETCH_REPAIR_BOX_LABEL_BY_BOX_ID: (boxId: number) => `/repair_box/${boxId}/label`,
  FETCH_REPAIR_SRC_FACILITATORS_BY_ROLE: (role: number | string) => `/external_user/${role}`,
  FETCH_ALL_TICKETS_BY_STUDENTS: () => `/repair_ticket/student/getAllRepairTickets`,
  FETCH_ALL_TICKETS_BY_PRACTICE_STUDENTS: () => `/repair_ticket/student_test/getAllRepairTickets`,
  GET_ALL_REPAIR_TICKETS: () => `/repair_ticket/list`,
};

export const fetchTicketsNoBox = async ({ accountNum, token }: { accountNum?: number; token?: string }) =>
  baseAPIFactory.get<NoBoxTickets[]>(END_POINTS.FETCH_ALL_TICKETS_NO_BOX(accountNum), handleTokenHeaders({ token }));

export const fetchAllTicketsSearch = async ({
  filteredValue,
  statusFilter,
  searchSelect,
  accountId,
  repairType,
  token,
}: {
  filteredValue: string | any;
  statusFilter: string;
  searchSelect: string;
  accountId: string | number;
  repairType: string;
  token?: string;
}) =>
  baseAPIFactory.get<any[]>(
    END_POINTS.FETCH_ALL_TICKET_SEARCH_CRITERIA(filteredValue, statusFilter, searchSelect, accountId, repairType),
    handleTokenHeaders({ token })
  );

export const fetchWorkflow = async ({
  accountNum,
  token,
  extraParams = null,
}: {
  accountNum: string | number;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<WorkflowTicket[]>(END_POINTS.FETCH_WORKFLOW(accountNum), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getTicketByTicketNumber = async ({
  ticketNumber,
  token,
}: {
  ticketNumber?: string | number;
  token?: string;
}) =>
  baseAPIFactory.get<RepairTicket[]>(END_POINTS.FETCH_ONE_TICKET_NUMBER(ticketNumber), handleTokenHeaders({ token }));

export const fetchTicketsInBox = async ({ boxId, token }: { boxId?: string | number; token?: string }) =>
  baseAPIFactory.get<RepairTicket[]>(END_POINTS.FETCH_ALL_TICKETS_BY_BOX(boxId), handleTokenHeaders({ token }));

export const fetchTicketsInboundBox = async ({ boxId, token }: { boxId?: string | number; token?: string }) =>
  baseAPIFactory.get<RepairTicket[]>(END_POINTS.FETCH_ALL_TICKETS_BY_INBOUND_BOX(boxId), handleTokenHeaders({ token }));

export const fetchOneBox = async ({ recordId, token }: { recordId: string | number | undefined; token?: string }) =>
  baseAPIFactory.get<RepairBox[]>(END_POINTS.FETCH_ONE_BOX(recordId), handleTokenHeaders({ token }));

export const fetchRepairImages = async ({ repairId, token }: { repairId?: string | number; token?: string }) =>
  baseAPIFactory.get<RepairImages[]>(END_POINTS.FETCH_ALL_IMAGES_BY_TICKET(repairId), handleTokenHeaders({ token }));

export const getRepairLineItems = async ({ repairId, token }: { repairId?: string | number; token?: string }) =>
  baseAPIFactory.get<LineItem[]>(END_POINTS.FETCH_LINE_ITEM_BY_TICKET(repairId), handleTokenHeaders({ token }));

export const fetchRepairBoxLabel = async ({ repairBoxId, token }: { repairBoxId: number; token?: string }) =>
  baseAPIFactory.get<string>(END_POINTS.FETCH_REPAIR_BOX_LABEL_BY_BOX_ID(repairBoxId), handleTokenHeaders({ token }));

export const getPaginatedList = async ({
  accountNum,
  boxType,
  offset,
  search,
  token,
}: {
  accountNum: string | number | null;
  boxType: string;
  offset: number;
  search: string | undefined;
  token?: string;
}) => baseAPIFactory.get<any>(END_POINTS.FETCH_ALL(accountNum, boxType, offset, search), handleTokenHeaders({ token }));
export const fetchRepairAccountItem = async ({
  accountNum,
  token,
  repairType,
  extraParams = null,
}: {
  accountNum: string | number;
  token?: string;
  repairType: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get(END_POINTS.GET_TICKET(accountNum, repairType), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const fetchRepairAccountItems = async (
  accountNum: string | number | null,
  repairType: string | null,
  props: FetchParamTypesWithPage
) => {
  const params: { [key: string]: any } = {};
  if (props.page) {
    params.page_no = props.page;
  }
  if (props.search_field) {
    params.search_field = props.search_field;
  }
  if (props.search_value) {
    params.search_value = props.search_value;
  }
  return baseAPIFactory.get<any[]>(END_POINTS.GET_TICKET(accountNum, repairType), {
    params,
    ...handleTokenHeaders(props),
  });
};

export const fecthRepairBuildingItem = async ({ itemId, token }: { itemId?: string | number | null; token?: string }) =>
  baseAPIFactory.get<TicketAccount[]>(END_POINTS.GET_BUILDING(itemId), handleTokenHeaders({ token }));

export const fecthDeviceByAccount = async ({ accountId, token }: { accountId: string | number; token?: string }) =>
  baseAPIFactory.get<DeviceDetails[]>(END_POINTS.FETCH_ALL_BY_ACCOUNT(accountId), handleTokenHeaders({ token }));

export const createRepairBox = async (data: RepairBox) =>
  baseAPIFactory.post(END_POINTS.CREATE_BOX(), data, handleTokenHeaders(data));

export const createRepairTicket = async (data: RepairTicket, routeSuffix?: string) =>
  baseAPIFactory.post(END_POINTS.CREATE_BOX(routeSuffix), data, handleTokenHeaders(data));

export const createInHouseRepairTicket = async (data: RepairTicket) =>
  baseAPIFactory.post(END_POINTS.CREATE_TICKET(), data, handleTokenHeaders(data));

export const createStudentRepairTicket = async (data: RepairTicket) =>
  baseAPIFactory.post(END_POINTS.CREATE_STUDENT_TICKET(), data, handleTokenHeaders(data));

export const createRepairTicketLineItems = async (data: LineItem) =>
  baseAPIFactory.post(END_POINTS.UPDATE_LINE_ITEMS(), data, handleTokenHeaders(data));

export const updateRepairTicket = async (data: UpdateTicket) =>
  baseAPIFactory.put(END_POINTS.UPDATE_TICKET(data?.ticket_number), data, handleTokenHeaders(data));

export const updateRepairTicketWorkflow = async (data: UpdateTicketWorkflow) =>
  baseAPIFactory.put(END_POINTS.UPDATE_TICKET_WORKFLOW(data?.ticket_number), data, handleTokenHeaders(data));

export const updateInHouseRepairTicket = async (data: UpdateTicket) =>
  baseAPIFactory.put(END_POINTS.UPDATE_IN_HOUSE_TICKET(data?.ticket_number), data, handleTokenHeaders(data));

export const updateTicketStatus = async (data: UpdateStatus) =>
  baseAPIFactory.put(END_POINTS.UPDATE_STATUS(data?.ticket_number), data, handleTokenHeaders(data));

export const createRepairBoxDetails = async (data: TicketContent) =>
  baseAPIFactory.post(END_POINTS.CREATE_BOX_DETAILS(), data, handleTokenHeaders(data));

export const getUser = async ({ token }: { token?: string }) =>
  baseAPIFactory.get<UserData[]>(END_POINTS.GET_USERS(), handleTokenHeaders({ token }));

export const getRepairTicketDetails = async (ticketnumber: string, { token }: { token?: string }) =>
  baseAPIFactory.get<TicketData>(END_POINTS.GET_ONE_BY_TICKET_NUMBER(ticketnumber), handleTokenHeaders({ token }));

export const reduceCustomPartQuantity = async ({ partId, token }: { partId: number | string; token?: string }) =>
  baseAPIFactory.post(END_POINTS.REDUCE_CUSTOM_PART_QUANTITY(partId), {}, handleTokenHeaders({ token }));

export const getAllRepairPartsByTicketId = async (recordId: number, { token }: { token?: string }) =>
  baseAPIFactory.get<RepairPart[]>(END_POINTS.GET_ALL_PARTS_BY_TICKET_NUMBER(recordId), handleTokenHeaders({ token }));

export const getAllRepairImagesByTicketId = async (recordId: number, { token }: { token?: string }) =>
  baseAPIFactory.get<RepairImage[]>(END_POINTS.GET_ALL_IMAGES_BY_TICKET_ID(recordId), handleTokenHeaders({ token }));

export const getInvoiceDetailsByTicketNumber = async (ticketNumber: string, { token }: { token?: string }) =>
  baseAPIFactory.get<RepairInvoice[]>(
    END_POINTS.GET_TICKET_INVOICE_DETAILS(ticketNumber),
    handleTokenHeaders({ token })
  );

export const deleteRepairTickets = async (recordId: number | string, { token }: { token?: string }) =>
  baseAPIFactory.delete(END_POINTS.DELETE_TICKETS(recordId), handleTokenHeaders({ token }));

export const deleteTestRepairTicket = async (recordId: number | string, { token }: { token?: string }) =>
  baseAPIFactory.delete(END_POINTS.DELETE_TEST_TICKETS(recordId), handleTokenHeaders({ token }));

export const getRepairTicketsData = async ({ accountId, token }: { accountId: number | undefined; token?: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_TICKETS_BY_ACCOUNT(accountId), handleTokenHeaders({ token }));

export const getRepairBoxesData = async ({ accountId, token }: { accountId: number | undefined; token?: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_BOXES_BY_ACCOUNT(accountId), handleTokenHeaders({ token }));

export const getSRCUserData = async ({ role, token }: { role: number | string; token?: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_REPAIR_SRC_FACILITATORS_BY_ROLE(role), handleTokenHeaders({ token }));

export const getRepairTicketsDataByStudent = async ({ token }: { token?: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_TICKETS_BY_STUDENTS(), handleTokenHeaders({ token }));
export const getTestRepairTicketsDataByStudent = async ({ token }: { token?: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_TICKETS_BY_PRACTICE_STUDENTS(), handleTokenHeaders({ token }));

export const getAllRepairTickets = async (params: any, token: string) =>
  baseAPIFactory.get<TransformedQueryResult>(END_POINTS.GET_ALL_REPAIR_TICKETS(), {
    params,
    ...handleTokenHeaders({ token }),
  });
