import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { setLoading } from './commonSlice';
import appLogger from '../infrastructure/config/appLogger';
import { isAPIResponseFailure, TokenParams } from '../api/base';
import type { RootState } from '../store';
import { getAllRoles, userRoleDetails } from '../api/externalUserRole';

const sliceName = 'externalUserRole';

export interface InitialState {
  roles: userRoleDetails[];
}
const initialState: InitialState = {
  roles: [],
};

export const fetchAllRoles = createAsyncThunk<userRoleDetails[], TokenParams>(
  `${sliceName}/fetchUserRole`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchUserRole`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const allRoles = await getAllRoles(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(allRoles)) {
        return ThunkAPI.rejectWithValue(allRoles);
      }
      return allRoles.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const externalUserRoleSlice = createSlice({
  name: sliceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllRoles.fulfilled, (state, action) => {
      const { payload } = action;
      state.roles = get(payload, 'rows', []);
    });
  },
});

export default externalUserRoleSlice.reducer;

export const externalUserRoleStateItem = createSelector(
  (state: RootState) => state.externalUserRole,
  (item: InitialState) => item
);

export const externalUserRoles = createSelector(
  (state: RootState) => state.externalUserRole.roles,
  (item: userRoleDetails[]) => item
);
