import React, { ReactElement, useState, useEffect } from 'react';
import DialogWrapper from 'src/components/modals/DialogWrapper';
import { DefaultButton } from 'src/components/StyledComponent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { getBooleanFeatureFlagValue } from 'src/utils/LaunchDarklyQuery';
import FILTER_GUIDE from '../components/modals/filterData/filterDataConstants';
import step1AgGrid from '../assets/images/step1AgGrid.gif';
import step2AgGrid from '../assets/images/step2AgGrid.gif';
import step3AgGrid from '../assets/images/step3AgGrid.gif';

interface AgGridTourProps {
  open: boolean;
  agGridTourModalKey: string;
}

const AgGridTour = ({ open, agGridTourModalKey }: AgGridTourProps): ReactElement => {
  const [isRunning, setIsRunning] = useState(false);
  const [step, setStep] = useState(0);
  const [content, setContent] = useState(<Typography>Loading...</Typography>);

  useEffect(() => {
    const fetchFlagValue = async () => {
      const flagValue = await getBooleanFeatureFlagValue('ag-grid-v2');
      if (flagValue) {
        setContent(
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/RligT7fElrk?si=n6EZNh6GXPzb-rgR"
            title="AG Grid V2 Tutorial"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{ width: '100%', height: '500px' }}
          />
        );
      } else {
        setContent(
          <iframe
            width="835"
            height="415"
            src="https://www.youtube.com/embed/WWx0f1Spa4M?si=tDFgAS5GF5rPyuE2"
            title="AG Grid V1 Tutorial"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{ width: '100%', height: '500px' }}
          />
        );
      }
    };

    fetchFlagValue();
  }, []);

  const step1 = () => (
    <div>
      {FILTER_GUIDE.introduction && <Typography paragraph>{FILTER_GUIDE.introduction}</Typography>}
      {FILTER_GUIDE.steps?.[0]?.heading && (
        <Typography variant="h6" gutterBottom>
          {FILTER_GUIDE.steps[0].heading}
        </Typography>
      )}
      {FILTER_GUIDE.steps?.[0]?.steps?.[0]?.title && (
        <Typography paragraph>
          <strong>{FILTER_GUIDE.steps[0].steps[0].title}:</strong> {FILTER_GUIDE.steps[0].steps[0].description}
        </Typography>
      )}
      <img src={step1AgGrid} alt="Step 1 AgGrid" style={{ width: ' 98%' }} />
      {FILTER_GUIDE.steps?.[0]?.steps?.[1]?.title && (
        <Typography paragraph>
          <strong>{FILTER_GUIDE.steps[0].steps[1].title}:</strong> {FILTER_GUIDE.steps[0].steps[1].description}
        </Typography>
      )}
    </div>
  );

  const step2 = () => (
    <div>
      {FILTER_GUIDE.steps?.[0]?.steps?.[2]?.title && (
        <Typography paragraph>
          <strong>{FILTER_GUIDE.steps[0].steps[2].title}:</strong> {FILTER_GUIDE.steps[0].steps[2].description}
        </Typography>
      )}
      {FILTER_GUIDE.steps?.[0]?.steps?.[3]?.title && (
        <Typography paragraph>
          <strong>{FILTER_GUIDE.steps[0].steps[3].title}:</strong> {FILTER_GUIDE.steps[0].steps[3].description}
        </Typography>
      )}
      <img src={step2AgGrid} alt="Step 2 AgGrid" style={{ width: ' 98%' }} />
    </div>
  );

  const step3 = () => (
    <div>
      {FILTER_GUIDE.steps?.[0]?.steps?.[4]?.title && (
        <Typography paragraph>
          <strong>{FILTER_GUIDE.steps[0].steps[4].title}:</strong> {FILTER_GUIDE.steps[0].steps[4].description}
        </Typography>
      )}
      <img src={step3AgGrid} alt="Step 2 AgGrid" style={{ width: ' 98%' }} />
    </div>
  );

  const step4 = () => (
    <div>
      {FILTER_GUIDE.steps?.[1]?.heading && (
        <Typography variant="h6" gutterBottom>
          {FILTER_GUIDE.steps[1].heading}
        </Typography>
      )}
      {FILTER_GUIDE.steps?.[1]?.description && <Typography paragraph>{FILTER_GUIDE.steps[1].description}</Typography>}
      {FILTER_GUIDE.videoTutorial && (
        <Typography variant="h6" gutterBottom>
          {FILTER_GUIDE.videoTutorial}
        </Typography>
      )}
      {content}
    </div>
  );

  const steps = [
    {
      content: step1,
    },
    {
      content: step2,
    },
    {
      content: step3,
    },
    {
      content: step4,
    },
  ];

  const dismiss = () => {
    localStorage.setItem(agGridTourModalKey, 'true');
    setIsRunning(!open);
  };

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      dismiss();
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    setIsRunning(open);
  }, [open]);

  return (
    <DialogWrapper title={FILTER_GUIDE.titleNewRelease} open={isRunning} handleClose={dismiss} showActions={false}>
      <>
        {steps[step].content()}
        <DialogActions>
          <DefaultButton startIcon={<NavigateBeforeIcon />} onClick={handleBack} disabled={step === 0}>
            Back
          </DefaultButton>
          <DefaultButton endIcon={<NavigateNextIcon />} onClick={handleNext}>
            {step < steps.length - 1 ? 'Next' : 'Finish'}
          </DefaultButton>
        </DialogActions>
      </>
    </DialogWrapper>
  );
};

export default AgGridTour;
