import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import customStyles from './headerMenu.styles';
import Chat from '../header/chat.component';

const HeaderMenu = () => {
  const theme = useTheme();
  const styles = customStyles(theme);
  const [hasNestedDivAbove, setHasNestedDivAbove] = useState(false);

  useEffect(() => {
    const headerElement = document.querySelector('header');

    const checkForNestedDivAbove = () => {
      const prevElement = headerElement?.previousElementSibling;
      // Logic for Release snack or spoofuser
      if (prevElement && prevElement.tagName === 'DIV') {
        const nestedDiv = prevElement.querySelector('div');
        setHasNestedDivAbove(!!nestedDiv);
      } else {
        setHasNestedDivAbove(false);
      }
    };
    checkForNestedDivAbove();

    const observer = new MutationObserver(() => {
      checkForNestedDivAbove();
    });

    if (headerElement?.parentElement) {
      observer.observe(headerElement.parentElement, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const renderContent = (
    <>
      <Box sx={{ ...styles.renderContentBox, ...styles.pointerEvents }} />
      <Stack direction="row" alignItems="center" spacing={1} sx={styles.pointerEvents}>
        <Chat />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        ...styles.appBar,
        ...(hasNestedDivAbove ? styles.withDivAbove : styles.withoutDivAbove),
      }}
    >
      <Toolbar sx={styles.toolbar}>{renderContent}</Toolbar>
    </AppBar>
  );
};

export default HeaderMenu;
