import { get, has, isObject } from 'lodash';
import { ROLE_ACTIONS, ROLE_MAP, VIEW_PAGE_ROLES } from './constants';

export interface PermissionReturn {
  canView: boolean;
  canCreate: boolean;
  canModify: boolean;
}

export type PermissionTypeKey = keyof typeof ROLE_ACTIONS;
export type PermissionTypeValue = (typeof ROLE_ACTIONS)[PermissionTypeKey];

export interface ProxyReturnType {
  permission: PermissionReturn;
  hasPermission: (permissionType: PermissionTypeValue) => boolean;
}

export interface ReturnView {
  canViewPage: () => boolean;
  checkPageName: (pageName: string) => boolean;
  handleMenuData: (item: any) => boolean;
  canView: (subModuleName: string) => boolean;
  canCreate: (subModuleName: string) => boolean;
  canModify: (subModuleName: string) => boolean;
  checkPermissions: (subModuleName: string) => PermissionReturn;
  defaultTabName: () => string;
  hasPermission: (subModuleName: string, permissionType: PermissionTypeValue) => boolean;
}

class RoleInfo {
  protected roleName = '';

  protected pageName = '';

  constructor(roleName: string, pageName: string) {
    this.roleName = roleName;
    this.pageName = pageName;
  }

  canViewPage() {
    return this.checkPageName(this.pageName);
  }

  checkPageName(pageName: string) {
    return (get(VIEW_PAGE_ROLES, pageName) || []).includes(this.roleName);
  }

  handleMenuData(item: any) {
    return !has(item, 'rolePage') ? true : this.checkPageName(item.rolePage || '');
  }

  getSubModulePermissions(subModuleName: string): string[] {
    return get(ROLE_MAP, [this.pageName, subModuleName, this.roleName]) || [];
  }

  checkPermissions(subModuleName: string): PermissionReturn {
    return {
      canView: this.canView(subModuleName),
      canCreate: this.canCreate(subModuleName),
      canModify: this.canModify(subModuleName),
    };
  }

  canView(subModuleName: string) {
    const permission = this.getSubModulePermissions(subModuleName);
    return permission.includes(ROLE_ACTIONS.VIEW);
  }

  canCreate(subModuleName: string) {
    const permission = this.getSubModulePermissions(subModuleName);
    return permission.includes(ROLE_ACTIONS.CREATE);
  }

  canModify(subModuleName: string) {
    const permission = this.getSubModulePermissions(subModuleName);
    return permission.includes(ROLE_ACTIONS.MODIFY);
  }

  hasPermission(subModuleName: string, permissionType: PermissionTypeValue) {
    const permission = this.getSubModulePermissions(subModuleName);
    return permission.includes(permissionType);
  }

  defaultTabName(): string {
    const permissions = get(ROLE_MAP, this.pageName) || {};
    if (isObject(permissions)) {
      const currentRoleName = this.roleName;
      const tabAllowed = Object.keys(permissions).filter((item: string) => {
        const permissionValues = get(permissions, [item, currentRoleName]);
        return Array.isArray(permissionValues) && permissionValues.includes(ROLE_ACTIONS.VIEW);
      });
      if (tabAllowed.length > 0) {
        return tabAllowed[0];
      }
      return '';
    }
    return '';
  }
}

export default RoleInfo;
