import get from 'lodash/get';
import baseAPIFactory, { handleTokenHeaders } from './base';

export interface CountResponse {
  count: string;
}

export interface InhouseTicketCardResponse {
  data: InHouseTicketCard;
}

export interface InHouseTicketCard {
  non_warranty_repairs: number;
  tickets_in_qa: number;
  tickets_in_repair: number;
  open_tickets: number;
  waiting_on_parts: number;
}

export interface VivacityTicketCardResponse {
  data: VivacityTicketCard;
}

export interface VivacityTicketCard {
  pending_repair_box: number;
  headed_to_vivacity: number;
  waiting_on_parts: number;
  in_repair: number;
  in_transit_to_me: number;
}

export interface UsedParts {
  part: number;
  repair_ticket: number;
  repair_issue: string;
  labor_hours: number;
  quantity: number;
  repair_cost: number;
  warranty_status: string;
  warranty_note: string;
  approval_status: string;
  diagnostic_fee: number;
}

export interface FailureType {
  count_adp: string;
  count_mechanical: string;
  count_id: string;
}

const END_POINTS = {
  GET_IN_TRANSIT: (accountNumber: string | number) => `vivacity_repair_report/in_transit/${accountNumber}`,
  GET_IN_TRANSIT_TO_VIVACITY: (accountNumber: string | number) =>
    `vivacity_repair_report/in_transit_to_vivacity/${accountNumber}`,
  GET_WAITING_ON_PARTS: (accountNumber: string | number) => `vivacity_repair_report/waiting_on_parts/${accountNumber}`,
  GET_IN_REPAIR: (accountNumber: string | number) => `vivacity_repair_report/in_repair/${accountNumber}`,
  GET_COMPLETED: (accountNumber: string | number) => `vivacity_repair_report/completed/${accountNumber}`,
  GET_NON_WARRANTY_REPAIR: (accountNumber: string | number) => `vivacity_repair_report/non_warranty/${accountNumber}`,
  GET_IN_QA: (accountNumber: string | number) => `vivacity_repair_report/qa/${accountNumber}`,
  GET_APPROVED: (accountNumber: string | number) => `vivacity_repair_report/approved/${accountNumber}`,
  GET_DENIED: (accountNumber: string | number) => `vivacity_repair_report/denied/${accountNumber}`,
  GET_CREATED: (accountNumber: string | number) => `vivacity_repair_report/past_month/${accountNumber}`,
  GET_PARTS_ORDERED: (accountNumber: string | number) => `vivacity_repair_report/parts_past_month/${accountNumber}`,
  GET_PARTS_USED: (ticketNumber: string | number) => `repair_part_used/all/${ticketNumber}`,
  GET_PAST_YEAR_TICKET_COUNT: () => `vivacity_repair_report/past_year`,
  GET_Failure_Type: () => `vivacity_repair_report/get_pie_chart_count`,
  GET_ALL_TRANSIT: (accountNumber: string | number) => `vivacity_repair_report/repair_status/${accountNumber}`,
  CREATE_TEST_TICKET: () => '/repair_ticket/create-test-tickets',
  GET_PENDING_STATUS: (accountNumber: string | number) => `/vivacity_repair_report/pending_status/${accountNumber}`,
  GET_VIVACITY_REPORT_DATA: () => `/vivacity_repair_report/vivacity_report_data`,
  GET_INHOUSE_REPORT_DATA: () => `/vivacity_repair_report/inhouse_report_data`,
  GET_BAR_CHART_DATA: () => `/vivacity_repair_report/get_bar_chart_count`,
  GET_INHOUSE_BAR_CHART_DATA: () => `/vivacity_repair_report/get_inhouse_bar_chart_count`,
};

export const getVivacityReportData = async ({ token, extraParams }: { token?: string; extraParams?: any }) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<VivacityTicketCardResponse>(END_POINTS.GET_VIVACITY_REPORT_DATA(), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getInhouseReportData = async ({ token, extraParams }: { token?: string; extraParams?: any }) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<InhouseTicketCardResponse>(END_POINTS.GET_INHOUSE_REPORT_DATA(), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getInTransit = async ({
  accountNumber,
  token,
  extraParams,
}: {
  accountNumber: string | number;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<CountResponse>(END_POINTS.GET_IN_TRANSIT(accountNumber), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getPastYearTicketCount = async ({ token, extraParams }: { token?: string; extraParams?: any }) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<CountResponse>(END_POINTS.GET_PAST_YEAR_TICKET_COUNT(), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getInTransitToVivacity = async ({
  accountNumber,
  token,
  extraParams,
}: {
  accountNumber: string | number;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<CountResponse>(END_POINTS.GET_IN_TRANSIT_TO_VIVACITY(accountNumber), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getWaitingOnParts = async ({
  accountNumber,
  token,
  extraParams,
}: {
  accountNumber: string | number;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<CountResponse>(END_POINTS.GET_WAITING_ON_PARTS(accountNumber), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getInRepair = async ({
  accountNumber,
  token,
  extraParams,
}: {
  accountNumber: string | number;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<CountResponse>(END_POINTS.GET_IN_REPAIR(accountNumber), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getPendingStatus = async ({
  accountNumber,
  token,
  extraParams,
}: {
  accountNumber: string | number;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<CountResponse>(END_POINTS.GET_PENDING_STATUS(accountNumber), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getCompleted = async ({ accountNumber, token }: { accountNumber: string | number; token?: string }) =>
  baseAPIFactory.get<CountResponse>(END_POINTS.GET_COMPLETED(accountNumber), handleTokenHeaders({ token }));

export const getNonWarrantyRepairs = async ({
  accountNumber,
  token,
}: {
  accountNumber: string | number;
  token?: string;
}) =>
  baseAPIFactory.get<CountResponse>(END_POINTS.GET_NON_WARRANTY_REPAIR(accountNumber), handleTokenHeaders({ token }));

export const getInQA = async ({ accountNumber, token }: { accountNumber: string | number; token?: string }) =>
  baseAPIFactory.get<CountResponse>(END_POINTS.GET_IN_QA(accountNumber), handleTokenHeaders({ token }));

export const getApproved = async ({ accountNumber, token }: { accountNumber: string | number; token?: string }) =>
  baseAPIFactory.get<CountResponse>(END_POINTS.GET_APPROVED(accountNumber), handleTokenHeaders({ token }));

export const getBarChartData = async ({ token, extraParams }: { token?: string; extraParams?: any }) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }

  return baseAPIFactory.get(END_POINTS.GET_BAR_CHART_DATA(), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getInhouseBarChartData = async ({ token, extraParams }: { token?: string; extraParams?: any }) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }

  return baseAPIFactory.get(END_POINTS.GET_INHOUSE_BAR_CHART_DATA(), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getDenied = async ({
  accountNumber,
  token,
  extraParams,
}: {
  accountNumber: string | number;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }

  return baseAPIFactory.get<CountResponse>(END_POINTS.GET_DENIED(accountNumber), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getAllTransit = async ({
  accountNumber,
  token,
  extraParams = null,
}: {
  accountNumber: string | number;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<CountResponse>(END_POINTS.GET_ALL_TRANSIT(accountNumber), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getCreated = async ({
  accountNumber,
  token,
  extraParams,
}: {
  accountNumber: string | number;
  token?: string;
  extraParams?: any;
}) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<CountResponse>(END_POINTS.GET_CREATED(accountNumber), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const getPartsOrdered = async ({ accountNumber, token }: { accountNumber: string | number; token?: string }) =>
  baseAPIFactory.get<CountResponse>(END_POINTS.GET_PARTS_ORDERED(accountNumber), handleTokenHeaders({ token }));

export const getUsedOnParts = async ({ ticketNumber, token }: { ticketNumber: string | number; token?: string }) =>
  baseAPIFactory.get<UsedParts>(END_POINTS.GET_PARTS_USED(ticketNumber), handleTokenHeaders({ token }));

export const getFailureType = async ({ token, extraParams }: { token?: string; extraParams?: any }) => {
  const extraHeaders: { [key: string]: any } = {};
  if (extraParams !== null) {
    extraHeaders.params = extraParams;
  }
  return baseAPIFactory.get<FailureType>(END_POINTS.GET_Failure_Type(), {
    ...extraHeaders,
    ...handleTokenHeaders({ token }),
  });
};

export const createTestRepairTickets = async ({ token }: { token: string }) => {
  try {
    const response = await baseAPIFactory.post(END_POINTS.CREATE_TEST_TICKET(), {}, handleTokenHeaders({ token }));
    return !!get(response, 'data.success');
  } catch (e) {
    return false;
  }
};
