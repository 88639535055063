import React from 'react';
import { Avatar } from '@mui/material';

interface AvatarWithConditionalCrossOriginProps {
  imageUrl: string;
  altText: string;
  styles: any;
}

const requiresCrossOrigin = (url) => !url.includes('auth') && !url.includes('google');

const AvatarWithConditionalCrossOrigin: React.FC<AvatarWithConditionalCrossOriginProps> = ({
  imageUrl,
  altText,
  styles,
}) => (
  <Avatar sx={styles}>
    <img alt={altText} src={imageUrl} {...(requiresCrossOrigin(imageUrl) ? { crossOrigin: 'anonymous' } : {})} />
  </Avatar>
);

export default AvatarWithConditionalCrossOrigin;
