import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { HOME_ROUTE } from '../../constants/routes';

type Props = {
  children: JSX.Element;
};

const GuestRoute = ({ children }: Props) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (!isLoading && isAuthenticated) {
    return <Navigate to={HOME_ROUTE} replace />;
  }
  return children;
};

export default GuestRoute;
