import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import omit from 'lodash/omit';
import baseAPIFactory, { gacBaseAPIFactory, handleTokenHeaders } from '../base';
import type { ListOptionsType, DeviceListOptionType, TreeType } from '../types';
import { processEmptyOptions } from './index';

const END_POINTS = {
  LIST_CREATE_USERS: () => '/api/users/list',
  LIST_DEVICES: () => '/api/device/list',
  UPDATE_DEVICE: (deviceId: string | number) => `/api/device/update/${deviceId}`,
  TREE: () => 'organization-unit/hierarchy',
  BRANCH: (itemId: string | number) => `/organization-unit/hierarchy/${itemId}`,
  TELEMETRY_LIST: () => `/api/telemetry/device/list`,
};

export const listUsers = async (options: ListOptionsType | null = null) => {
  const params: { [key: string]: any } = {};
  params.headers = handleTokenHeaders(options).headers;
  if (options !== null) {
    params.params = processEmptyOptions(options);
  }
  return gacBaseAPIFactory.get(END_POINTS.LIST_CREATE_USERS(), params);
};

export const listDeviceTelemetry = async (deviceId: string, token: string) => {
  const params: { [key: string]: any } = {
    headers: handleTokenHeaders({ token }).headers,
    params: { deviceId },
  };
  const data = await gacBaseAPIFactory.get(END_POINTS.TELEMETRY_LIST(), params);
  return data.data;
};

export const createUser = async (options: any, token?: string) => {
  const customOption = get(options, 'customTypes', []);
  const newOptions = omit(options, ['customTypes', 'token']);
  const customOptionObject =
    Array.isArray(customOption) && customOption.length > 0
      ? customOption.reduce((agg, value) => {
          const keyValue = camelCase(value.key);
          return {
            ...agg,
            [keyValue]: value.value,
          };
        }, {})
      : null;
  if (customOptionObject !== null) {
    newOptions.customSchema = {
      userSchema: customOptionObject,
    };
  }
  return gacBaseAPIFactory.post(END_POINTS.LIST_CREATE_USERS(), newOptions, {
    headers: {
      'Content-Type': 'application/json',
      ...handleTokenHeaders({ token }).headers,
    },
  });
};

export const updateUser = async (userId: string | number, options: any, token?: string) => {
  const customOption = get(options, 'customTypes', []);
  const newOptions = omit(options, ['customTypes', 'token']);
  const customOptionObject =
    Array.isArray(customOption) && customOption.length > 0
      ? customOption.reduce((agg, value) => {
          const keyValue = camelCase(value.key);
          return {
            ...agg,
            [keyValue]: value.value,
          };
        }, {})
      : null;
  if (customOptionObject !== null) {
    newOptions.customSchema = {
      userSchema: customOptionObject,
    };
  }
  return gacBaseAPIFactory.post(END_POINTS.LIST_CREATE_USERS(), newOptions, {
    headers: {
      'Content-Type': 'application/json',
      ...handleTokenHeaders({ token }).headers,
    },
  });
};

export const listDevices = async (options: DeviceListOptionType | null = null, token: string | null = null) => {
  const params: { [key: string]: any } = {};
  params.headers = handleTokenHeaders({ token }).headers;
  if (options !== null) {
    params.params = processEmptyOptions(options);
  }
  return gacBaseAPIFactory.get(END_POINTS.LIST_DEVICES(), params);
};

export const updateUserGACDevice = async (deviceId: string | number, options: any, token: string) => {
  const params: { [key: string]: any } = {};
  params.headers = handleTokenHeaders({ token }).headers;
  return gacBaseAPIFactory.post(
    END_POINTS.UPDATE_DEVICE(deviceId),
    omit(options, ['autoUpdateExpiration', 'isGAC', 'orgUnitId']),
    params
  );
};

export const getTreeList = async ({ token }: { token: string }) =>
  baseAPIFactory.get<TreeType[]>(END_POINTS.TREE(), handleTokenHeaders({ token }));

export const getBranchList = async ({ token, itemId }: { token: string; itemId: string | number }) =>
  baseAPIFactory.get<TreeType[]>(END_POINTS.BRANCH(itemId), handleTokenHeaders({ token }));
