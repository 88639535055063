import * as LDClient from 'launchdarkly-js-client-sdk';

const clientSideId = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID;
const user = {
  key: 'Dream1',
};

if (!clientSideId) {
  throw new Error('Client-side ID is not defined');
}
const ldClient = LDClient.initialize(clientSideId, user);

function getBooleanFeatureFlagValue(featureFlagKey: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    ldClient
      .waitForInitialization()
      .then(() => {
        try {
          const flagValue = ldClient.variation(featureFlagKey, false);
          resolve(flagValue);
        } catch (error) {
          reject(error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getStringFeatureFlagValue(featureFlagKey: string): Promise<string> {
  return new Promise((resolve, reject) => {
    ldClient
      .waitForInitialization()
      .then(() => {
        try {
          const flagValue = ldClient.variation(featureFlagKey, 'default text');
          resolve(flagValue);
        } catch (error) {
          reject(error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { getBooleanFeatureFlagValue, getStringFeatureFlagValue };
