import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { createNewTicket, listAllTestDevicesAPI, getRepairTicketAPI } from '../api/testRepair';
import { getBuildingNoPage } from '../api/building';
import appLogger from '../infrastructure/config/appLogger';
import { setLoading } from './commonSlice';
import { TokenParams, isAPIResponseFailure } from '../api/base';
import { RootState } from '../store';

const sliceName = 'testRepair';

export interface InitialState {
  devices: any[];
  buildings: any[];
  ticketDetail: any;
}

const initialState: InitialState = {
  devices: [],
  buildings: [],
  ticketDetail: {},
};

export const fetchAllDevicesList = createAsyncThunk<any[], TokenParams>(
  `${sliceName}/fetchDevicesList`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/fetchDevicesList`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const deviceList = await listAllTestDevicesAPI(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(deviceList)) {
        return ThunkAPI.rejectWithValue(deviceList);
      }
      return deviceList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllBuildingsList = createAsyncThunk<any[], TokenParams>(
  `${sliceName}/fetchAllBuildingsList`,
  async (params, ThunkAPI) => {
    const state = ThunkAPI.getState();
    appLogger.log(params);
    const loaderName = `${sliceName}/fetchAllBuildingsList`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const deviceList = await getBuildingNoPage({
        token: params.token,
        email: get(state, 'account.user.email'),
      });
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(deviceList)) {
        return ThunkAPI.rejectWithValue(deviceList);
      }
      return deviceList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const createNewInHouseTestTicket = createAsyncThunk<any[], any>(
  `${sliceName}/createNewInHouseTestTicket`,
  async (params, ThunkAPI) => {
    const state = ThunkAPI.getState();
    appLogger.log(params);
    const loaderName = `${sliceName}/createNewInHouseTestTicket`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const deviceList = await createNewTicket({
        token: get(state, 'account.user.token'),
        ...(params || {}),
      });
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(deviceList)) {
        return ThunkAPI.rejectWithValue(deviceList);
      }
      return deviceList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return {};
  }
);

export const getInHouseTestTicket = createAsyncThunk<any[], any>(
  `${sliceName}/getInHouseTestTicket`,
  async (params, ThunkAPI) => {
    const state = ThunkAPI.getState();
    appLogger.log(params);
    const loaderName = `${sliceName}/getInHouseTestTicket`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const deviceList = await getRepairTicketAPI({
        token: get(state, 'account.user.token'),
        ticketId: params,
      });
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(deviceList)) {
        return ThunkAPI.rejectWithValue(deviceList);
      }
      return deviceList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return {};
  }
);

const testRepairSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllDevicesList.fulfilled, (state, action) => {
      const { payload } = action;
      state.devices = payload;
    });
    builder.addCase(fetchAllBuildingsList.fulfilled, (state, action) => {
      const { payload } = action;
      state.buildings = payload;
    });
    builder.addCase(getInHouseTestTicket.fulfilled, (state, action) => {
      const { payload } = action;
      state.ticketDetail = payload;
    });
  },
});

export const testRepairStateItem = createSelector(
  (state: RootState) => state[sliceName],
  (items: InitialState) => items
);

export const getRepairTicketDetail = createSelector(
  (state: RootState) => state[sliceName].ticketDetail,
  (ticketDetail: InitialState['ticketDetail']) => ticketDetail || {}
);

export default testRepairSlice.reducer;
