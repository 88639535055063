import baseAPIFactory, { handleTokenHeaders } from './base';

export interface SpoofItem {
  email: string;
  token: string;
}

const END_POINTS = {
  SPOOF_LOGIN: () => `/spoofing/login-spoofing`,
};

export const spoofuser = async (data: SpoofItem) =>
  baseAPIFactory.post(END_POINTS.SPOOF_LOGIN(), data, handleTokenHeaders(data));
