import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { fetchImportsByAccount, fetchImportsByEmail, fleetImport } from '../api/fleetImport';
import { setLoading } from './commonSlice';
import appLogger from '../infrastructure/config/appLogger';
import { isAPIResponseFailure } from '../api/base';
import { PER_PAGE_RECORD_IMPORT_SERIAL } from '../constants/data/my-device.constants';
import { RootState } from '../store';
import { DeviceInfo, getAllByImportId } from '../api/device/device';

export interface InitialState {
  page: number;
  perPage: number;
  totalCount: number;
  totalPages: number;
  allAccountImports: fleetImport[];
  currentImportDevices: DeviceInfo[];
  dataLoading: boolean;
  accountAllImports: fleetImport[];
}

const initialState: InitialState = {
  page: 1,
  perPage: PER_PAGE_RECORD_IMPORT_SERIAL,
  totalCount: 0,
  totalPages: 0,
  allAccountImports: [],
  currentImportDevices: [],
  dataLoading: false,
  accountAllImports: [],
};

type fetchImportWithToken = {
  userEmail?: string;
  token: string;
  page?: number;
  searchField?: string;
  searchValue?: string;
  contains?: string;
};

type fetchDevicesWithToken = {
  importId?: string;
  token?: string;
};

type fetchImportsWithToken = { accountId: number; token?: string };

const sliceName = 'fleetImport';

export const fetchAllAccountImports = createAsyncThunk<fleetImport[], fetchImportWithToken>(
  `${sliceName}/fetchAllAccountImports`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const imports = await fetchImportsByEmail(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(imports)) {
        return ThunkAPI.rejectWithValue(imports);
      }
      return imports.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllDevicesByImport = createAsyncThunk<DeviceInfo[], fetchDevicesWithToken>(
  `${sliceName}/fetchAllDevicesByImport`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const devices = await getAllByImportId(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(devices)) {
        return ThunkAPI.rejectWithValue(devices);
      }
      return devices.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllImportsByAccount = createAsyncThunk<fleetImport[], fetchImportsWithToken>(
  `${sliceName}/fetchAllImportsByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const imports = await fetchImportsByAccount(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(imports)) {
        return ThunkAPI.rejectWithValue(imports);
      }
      return imports.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);
export const fleetImportSlice = createSlice({
  name: sliceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllAccountImports.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.allAccountImports = get(payload, 'rows', []);
      state.totalCount = totalCount;
      state.totalPages = Math.ceil(totalCount / state.perPage);
    });
    builder.addCase(fetchAllImportsByAccount.pending, (state) => {
      state.dataLoading = true;
    });
    builder.addCase(fetchAllImportsByAccount.fulfilled, (state, action) => {
      state.accountAllImports = action.payload;
      state.dataLoading = false;
    });
    builder.addCase(fetchAllImportsByAccount.rejected, (state) => {
      state.dataLoading = false;
    });
  },
});

export default fleetImportSlice.reducer;

export const fleetImportStateItem = createSelector(
  (state: RootState) => state.fleetImport,
  (item: InitialState) => item
);
