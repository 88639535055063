import { CSSObject, Theme, darken, styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import COLORS from '../../constants/colors/colors.constant';

const styles = (theme: any) => ({
  container: {
    maxHeight: '100vh',
    margin: 0,
    padding: 0,
    position: 'fixed',
    height: '100vh',
    backgroundColor: '#fff',
  },
  logo: {
    width: theme.spacing(5.15),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '12px auto',
    transition: '0.2s',
  },

  logoOnHover: {
    width: theme.spacing(6.45),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '7px auto',
    transition: '0.2s',
  },

  menu: {
    position: 'absolute',
    mt: theme.spacing(7.5),
    left: theme.spacing(11.5),
    zIndex: 1,
  },
  modal: {
    display: 'flex',
    position: 'absolute',
    bottom: '3%',
    left: '2%',
  },
  heading: {
    width: theme.spacing(44.875),
    height: theme.spacing(55),
    padding: theme.spacing(2.875, 0),
    zIndex: 1,
  },
  flexHeading: {
    textAlign: 'center',
    width: theme.spacing(37.625),
    height: theme.spacing(6.875),
    margin: 'auto',
  },
  collapsedMenu: {
    position: 'absolute',
    mt: theme.spacing(-2.25),
    left: theme.spacing(31),
    zIndex: 1,
  },
  flexBox: {
    width: theme.spacing(39.375),
    height: theme.spacing(4.25),
    background: COLORS.primary.textDark,
    borderRadius: theme.spacing(12.5),
  },
  labelBox: {
    height: theme.spacing(4.25),
    background: COLORS.primary.textDark,
    borderRadius: theme.spacing(12.5),
    width: '100%',
    mt: 1,
    mb: 2,
    justifyContent: 'start',
  },
  downArrow: {
    float: 'right',
    display: 'inline',
    width: 'min-content',
  },
  subHeading: {
    fontWeight: '800',
    fontSize: theme.spacing(2.75),
    color: COLORS.primary.black,
    lineHeight: theme.spacing(3.75),
    padding: theme.spacing(0),
    float: 'left',
  },
  linkText: {
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.25),
    fontWeight: '500',
    marginLeft: '10%',
    color: COLORS.primary.headerTitle,
  },
  pageText: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.25),
    color: COLORS.primary.headerTitle,
  },
  pageTextModal: {
    marginLeft: '25%',
    fontWeight: '500',
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.25),
    color: COLORS.primary.headerTitle,
    alignItems: 'center',
    marginTop: theme.spacing(1.625),
  },
  iconButton: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
    background: COLORS.primary.white,
    '&:hover': {
      background: COLORS.primary.white,
      boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.25)',
    },
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    p: 2,
  },
  strongText: {
    color: COLORS.primary.headerTitle,
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(2.5),
  },

  lightText: {
    fontWeight: 400,
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(2.5),
    color: COLORS.primary.navigationText,
    wordBreak: 'break-all',
    paddingRight: '20px',
  },
  flexRowBoxContainerCollapse: {
    position: 'fixed',
    bottom: theme.spacing(0),
    backgroundColor: '#fff',
  },
  flexRowBox: {
    display: 'flex',
    pt: 1,
    pb: 1,
  },
  list: {
    mt: 4,
    px: 5,
  },
  bottomList: {
    ml: 4.6,
    mt: 1,
  },

  listItem: {
    color: COLORS.primary.navigationText,
    fontWeight: '400',
    alignSelf: 'center',
    fontSize: theme.spacing(2.125),
    lineHeight: theme.spacing(3),
    fontStyle: 'normal',
  },
  listIcon: {
    color: COLORS.primary.listIcon,
    mr: 1.5,
    width: '30px',
  },
  icon: {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    alignSelf: 'center',
  },
  colorIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    alignSelf: 'center',
    position: 'absolute',
    left: theme.spacing(35.25),
  },
  activeListItem: {
    color: theme.palette.primary.main,
    fontWeight: '400',
    fontSize: theme.spacing(2.125),
    lineHeight: theme.spacing(3),
    alignSelf: 'center',
    fontStyle: 'normal',
  },
  copyrightBox: {
    margin: 'auto',
    mt: '5px',
    pt: '3px',
    pb: '3px',
    display: 'flex',
    width: '240px',
    flexDirection: 'column',
    alignContent: 'end',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    position: 'fixed',
    background: COLORS.primary.white,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
  copyrightText: {
    color: COLORS.primary.navigationText,
    fontWeight: '400',
    fontSize: theme.spacing(1.25),
    textAlign: 'center',
    fontStyle: 'Roboto',
    display: 'flex',
    flexDirection: 'row',
  },
  closedListIcon: {
    minWidth: 0,
    mr: 0,
    justifyContent: 'center',
  },
  openListIcon: {
    display: 'flex',
    minWidth: 0,
    marginRight: 20,
    justifyContent: 'center',
  },
  openListIconSubText: {
    display: 'flex',
    minWidth: 0,
    marginRight: 24,
    justifyContent: 'center',
  },
  subTextStyle: {
    fontSize: '14px',
    marginTop: '4px',
    display: 'block',
    color: COLORS.primary.darkGrey,
  },
  accountValue: {
    color: COLORS.secondary.creditGreen,
    fontWeight: 600,
  },
  activeListIcon: {
    color: COLORS.primary.main,
    mr: 1.5,
    width: '30px',
  },
  hidden: {
    display: 'none',
  },
  openIt: {
    animation: 'fade-in 500ms forwards',
  },
  closeIt: {
    animation: 'fade-out 500ms forwards',
  },
  dividerLine: {
    width: theme.spacing(27.5),
    height: theme.spacing(0.125),
    background: COLORS.primary.background,
    margin: 'auto',
  },
  arrowDownIcon: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(0.125),
  },
  externalLink: {
    textDecoration: 'none',
  },
  lightColorText: {
    color: COLORS.primary.black,
    fontSize: theme.spacing(1.75),
  },
  label: {
    width: '90%',
    margin: 'auto',
    marginTop: theme.spacing(4.75),
    marginBottom: theme.spacing(1.25),
  },
  labelModal: {
    margin: 'auto',
    marginTop: theme.spacing(1.875),
    marginBottom: theme.spacing(1.25),
    display: 'flex',
  },
  color: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    marginRight: theme.spacing(1.25),
  },
  cardModal: {
    width: theme.spacing(17.875),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1.25),
    overflow: 'auto',
    maxHeight: theme.spacing(32),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    alignSelf: 'center',
  },
  modalHeading: {
    fontWeight: '600',
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(2.25),
  },
  colorBox: {
    margin: theme.spacing(1.25, 'auto', 0, 0.625),
    width: '90%',
    paddingLeft: theme.spacing(0.75),
  },
  inputColor: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'inline-block',
    margin: '0px 3px',
    cursor: 'pointer',
  },
  avaterStyle: { height: '40px', width: '40px', borderRadius: '50%' },
  avaterStyleSx: { mr: 2, ml: 3.5 },
  feelLikeDisabledLink: {
    color: COLORS.primary.disabledGrey,
    cursor: 'default',
    '&:hover': {
      background: 'none',
      color: COLORS.primary.disabledGrey,
    },
  },
  sideBarFlexBox: {
    display: 'flex',
    width: '100%',
  },
  listItemIcon: {
    minWidth: 0,
    mr: 'auto',
    justifyContent: 'center',
    color: COLORS.primary.textDark,
  },
  listItemButton: {
    minHeight: 48,
    justifyContent: 'center',
    px: 2.5,
  },
  listItemBlock: {
    display: 'block',
  },
  listItemDisabled: {
    display: 'block',
    background: COLORS.primary.disabledGreylight,
  },
  listItemAvatar: {
    mr: 1,
    width: 32,
    height: 32,
    img: {
      width: '100%',
    },
  },
  listItemAvatarIcon: {
    mr: 1,
    ml: 0.7,
    width: 24,
    height: 24,
    img: {
      width: '100%',
    },
  },
  profileLinkItem: {
    textDecoration: 'none',
    fontSize: '.85rem',
  },
  profileLinkItemDisabled: {
    textDecoration: 'none',
    fontSize: '.85rem',
    fontWeight: '400',
    color: COLORS.primary.disabledGrey,
    '&:hover': {
      color: COLORS.primary.disabledGrey,
      backgroundColor: 'transparent',
    },
  },
  profileLinkButton: {
    fontSize: '.85rem',
    color: COLORS.primary.navigationText,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  bottomMenuList: {
    mb: '32px',
    backgroundColor: 'rgba(0,0,0,.025)',
  },
  profileMenuButtonText: {
    display: 'block',
    fontSize: 12,
    fontWeight: 'medium',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  profileMenuButtonTextContainer: {
    width: 120,
  },
  '&:hover': {
    color: COLORS.primary.white,
    opacity: 1,
  },
  dashboardColorIcon: {
    padding: 3,
  },
  scroll: {
    overflowY: 'auto',
    height: '100%',
    paddingBottom: theme.spacing(6.875),
  },
});

export const labelBoxBorder = ({ theme }, activeModal, item) => ({
  height: theme.spacing(4.25),
  background: COLORS.primary.textDark,
  borderRadius: theme.spacing(12.5),
  width: '100%',
  mt: 1,
  mb: 2,
  justifyContent: 'start',
  border: `${activeModal.title === item.title ? theme.spacing(0.125) : theme.spacing(0)} solid ${
    COLORS.secondary.Gainsboro
  }`,
});

export const drawerWidth = 240;

export const profileListItemIcon = (open) => ({
  minWidth: 0,
  color: 'inherit',
  margin: open ? 1.5 : 0,
  marginRight: 0,
  justifyContent: 'center',
});

export const keyboardIcon = (open) => ({
  mr: -1,
  opacity: 0,
  transform: open ? 'rotate(-180deg)' : 'rotate(0)',
  transition: '0.2s',
});

export const chevronButton = (open) => ({
  background: COLORS.primary.textDark,
  position: 'fixed',
  zIndex: 1200,
  width: '30px',
  height: '30px',
  top: open ? '230px' : '230px',
  left: open ? '216px' : '42px',
  transition: 'left .2s, top .2s',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  '&:hover': {
    background: darken(COLORS.primary.textDark, 0.01),
  },
});

export const drawerHeader = () => ({
  transition: 'margin-bottom .2s',
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'block',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const mainLinkItem = (open: boolean, theme: Theme, isActive?: boolean) => ({
  textDecoration: 'none',
  fontWeight: isActive ? '600' : '400',
  color: isActive ? theme.palette.primary.main : COLORS.primary.navigationText,
  '&:hover': {
    color: theme.palette.primary.main,
    opacity: 1,
  },
});

export const closedMixin = (): CSSObject => ({
  transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1)',
  overflowX: 'hidden',
  width: '57px',
  '@media (min-width: 600px)': {
    width: '65px',
  },
});

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open ? openedMixin(theme) : closedMixin()),
  '& .MuiDrawer-paper': {
    ...(open ? openedMixin(theme) : closedMixin()),
  },
}));

export const listItemText = (open) => ({
  display: open ? 'inline' : 'none',
});

export const listBox = (theme, open) => ({
  display: 'flex',
  justifyContent: open ? 'flex-start' : 'center',
  alignItems: open ? 'flex-start' : 'center',
  my: 0,
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.primary.main / 60,
    borderRadius: theme.spacing(0.25),
  },
});

export const profileButton = (theme, open) => ({
  display: 'flex',
  gap: 1,
  justifyContent: open ? 'start' : 'center',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.primary.main,
    color: '#FFF',
    borderRadius: theme.spacing(0.25),
  },
  pl: open ? 0 : 'auto',
  py: open ? 0 : 2,
  alignItems: 'center',
  '&:hover, &:focus': { '& svg': { opacity: 1 } },
});

export default styles;
