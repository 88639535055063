import baseAPIFactory, { handleTokenHeaders } from './base';

export type FetchParamTypes = {
  token?: string;
};

export type FetchParamTypesWithPage = {
  token?: string;
};

export type ItemList = {
  record_id: number;
  issue: string;
};

export interface repairInhouseTableData {
  recordId: number;
  assetTag: null | string;
  createdBy: string;
  building?: string;
  returnSite?: string;
  lastModifiedDate: Date;
  repairDevice: string;
  repairStatus: InhouseStatus;
  repairType: string;
  ticketNumber: string;
  serialNumber: string;
  warrantyStatus: null | string;
  internalTechnician?: string;
  inHouseStatus: InhouseStatus;
  student_name?: string;
  student_id?: null;
}

export enum InhouseStatus {
  Closed = 'Closed',
  Complete = 'Complete',
  InHouse = 'InHouse',
  InTransit = 'In_Transit',
  Intake = 'Intake',
  PartQueue = 'Part_Queue',
  PartReceived = 'Part_Received',
  PendingRepairBox = 'Pending_Repair_Box',
  QualityAssurance = 'Quality_Assurance',
  Repair = 'Repair',
  WaitingOnRepairApproval = 'Waiting_on_Repair_Approval',
}

export interface FetchrepairInhouseParamTypes {
  accountId: number;
  token?: string;
  params?: any;
}

const END_POINTS = {
  FETCH_ALL: () => `/repair_description/`,
  FETCH_ALL_INHOUSETICKETS: (accountNumber: number | undefined) => `/repair_ticket/${accountNumber}/inhouseTickets`,
  FETCH_ALL_INHOUSE_REPAIR_TICKETS: (accountNumber: number | undefined) =>
    `/repair_ticket/${accountNumber}/inHouseRepairTickets`,
};

export const getPaginatedList = async (token: string) =>
  baseAPIFactory.get<ItemList[]>(END_POINTS.FETCH_ALL(), handleTokenHeaders({ token }));

export const getAllInhouseTicketsList = async ({
  accountId,
  token,
  params,
}: {
  accountId: number | undefined;
  token?: string;
  params?: any;
}) =>
  baseAPIFactory.get<ItemList[]>(END_POINTS.FETCH_ALL_INHOUSETICKETS(accountId), {
    params,
    ...handleTokenHeaders({ token }),
  });

export const getAllInHouseRepairTickets = async ({
  accountId,
  token,
}: {
  accountId: number | undefined;
  token?: string;
}) =>
  baseAPIFactory.get<ItemList[]>(END_POINTS.FETCH_ALL_INHOUSE_REPAIR_TICKETS(accountId), handleTokenHeaders({ token }));
