import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Config from '../infrastructure/config/config';
import getStorage from '../infrastructure/util/Storage';
import { store } from '../store';
import { STORAGE_CONTEXT_NAME } from '../constants/common';
import { handleRefreshUserData } from '../slices/accountSlice';

const appConfig = Config.getConfig();
const baseAPIFactory = axios.create({ baseURL: appConfig.BASE_URL });
const storage = getStorage(STORAGE_CONTEXT_NAME);

export const interceptFulfilled = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = storage.getKey(appConfig.authStorageKeyName, false);
  if (token !== false) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const interceptRejected = (error: any) => Promise.reject(error);
export const interceptResponse = (response: AxiosResponse) => {
  const lastModified = get(response.headers, 'x-user-modified', null);
  if (!isEmpty(lastModified) && has(store, 'dispatch')) {
    store.dispatch(handleRefreshUserData(lastModified));
  }
  return response;
};

baseAPIFactory.interceptors.request.use(interceptFulfilled, interceptRejected);
baseAPIFactory.interceptors.response.use(interceptResponse, interceptRejected);

export type PaginatedResponse<T> = {
  total: string | number;
  rows: T[];
};

export type PagedResponse<T> = {
  rows: T[];
};

export interface RecordType {
  record_id: string | number;
}

export type TokenParams = {
  token?: string;
  page?: number;
  accountId?: number;
};

export const isAPIResponseFailure = (response: AxiosResponse): boolean =>
  response.status < 200 || response.status >= 300;

export function commonThenResult<T>(result: AxiosResponse<T>) {
  return result.data;
}

export const handleTokenHeaders = (props: any, extraHeaders?: any) => {
  const token = get(props, 'token');
  if (!isEmpty(token)) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(extraHeaders || {}),
      },
    };
  }
  return {};
};

export { storage };

export const gacBaseAPIFactory = axios.create({ baseURL: appConfig.GAC_HANDLER_URL });

export default baseAPIFactory;
