import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import appLogger from '../../infrastructure/config/appLogger';
import { setLoading } from '../commonSlice';
import { isAPIResponseFailure } from '../../api/base';
import {
  createUser,
  updateUser,
  listDevices,
  updateUserGACDevice,
  getTreeList,
  getBranchList,
} from '../../api/gac/directory';
import type { RootState } from '../../store';
import type { DeviceListOptionType, TreeType } from '../../api/types';
import { PER_PAGE_RECORD } from '../../constants/data/users.constant';

const sliceName = 'directory';

export interface InitialState {
  page: number;
  perPage: number;
  users: any[];
  devices: any[];
  canShowNext: boolean;
  canShowPrevious: boolean;
  nextToken: string | null;
  previousToken: string | null;
  tree: TreeType[];
  branch: TreeType[];
  subBranch: TreeType[];
}

interface DeviceWithToken extends DeviceListOptionType {
  token: string;
}

const initialState: InitialState = {
  page: 1,
  perPage: PER_PAGE_RECORD,
  users: [],
  devices: [],
  canShowNext: false,
  canShowPrevious: false,
  nextToken: null,
  previousToken: null,
  tree: [],
  branch: [],
  subBranch: [],
};

export const createGACUser = createAsyncThunk<any, any>(`${sliceName}/create`, async (params, ThunkAPI) => {
  const loaderName = `${sliceName}/create`;
  ThunkAPI.dispatch(setLoading([loaderName, true]));
  try {
    appLogger.log(params);
    const response = await createUser(params, params.token);
    ThunkAPI.dispatch(setLoading([loaderName, false]));
    if (isAPIResponseFailure(response)) {
      return ThunkAPI.rejectWithValue(response);
    }
    return response.data;
  } catch (e: any) {
    return e;
  } finally {
    ThunkAPI.dispatch(setLoading([loaderName, false]));
  }
});

export const editGACUser = createAsyncThunk<any, any>(`${sliceName}/edit-user`, async (params, ThunkAPI) => {
  const loaderName = `${sliceName}/edit-user`;
  ThunkAPI.dispatch(setLoading([loaderName, true]));
  try {
    appLogger.log(params);
    const { id, token, ...otherParams } = params;
    const response = await updateUser(id, otherParams, token);
    ThunkAPI.dispatch(setLoading([loaderName, false]));
    if (isAPIResponseFailure(response)) {
      return ThunkAPI.rejectWithValue(response);
    }
    return response.data;
  } catch (e) {
    appLogger.error(e);
  } finally {
    ThunkAPI.dispatch(setLoading([loaderName, false]));
  }
  return {};
});

export const updateGACDeviceData = createAsyncThunk<any, any>(`${sliceName}/updateDevice`, async (params, ThunkAPI) => {
  const loaderName = `${sliceName}/updateDevice`;
  ThunkAPI.dispatch(setLoading([loaderName, true]));
  try {
    appLogger.log(params);
    const { token, deviceId, ...options } = params;
    const response = await updateUserGACDevice(deviceId, options, token);
    ThunkAPI.dispatch(setLoading([loaderName, false]));
    if (isAPIResponseFailure(response)) {
      return ThunkAPI.rejectWithValue(response);
    }
    return response.data;
  } catch (e) {
    appLogger.error(e);
  } finally {
    ThunkAPI.dispatch(setLoading([loaderName, false]));
  }
  return {};
});

export const fetchGACDevices = createAsyncThunk<any, DeviceWithToken>(
  `${sliceName}/fetchDevice`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchDevice`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      appLogger.log(params);
      const response = await listDevices(params, params.token);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return {};
  }
);

export const fetchTreeList = createAsyncThunk<TreeType[], { token: string }>(
  `${sliceName}/fetchTree`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchTree`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const treeList = await getTreeList({ token: params.token });
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(treeList)) {
        return ThunkAPI.rejectWithValue(treeList);
      }
      return treeList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchBranchList = createAsyncThunk<TreeType[], { itemId: string | number; token: string }>(
  `${sliceName}/fetchBranch`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchBranch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const treeList = await getBranchList({ itemId: params.itemId, token: params.token });
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(treeList)) {
        return ThunkAPI.rejectWithValue(treeList);
      }
      return treeList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchSubBranchList = createAsyncThunk<TreeType[], { itemId: string | number; token: string }>(
  `${sliceName}/fetchSubBranch`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchSubBranch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const treeList = await getBranchList({ itemId: params.itemId, token: params.token });
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(treeList)) {
        return ThunkAPI.rejectWithValue(treeList);
      }
      return treeList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchSubMainBranchList = createAsyncThunk<TreeType[], { itemId: string | number; token: string }>(
  `${sliceName}/fetchSubMainBranch`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchSubMainBranch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const treeList = await getBranchList({ itemId: params.itemId, token: params.token });
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(treeList)) {
        return ThunkAPI.rejectWithValue(treeList);
      }
      return treeList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

const directorySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createGACUser.fulfilled, (state, action) => {
      const { payload } = action;
      state.users?.push(payload);
    });
    builder.addCase(fetchTreeList.fulfilled, (state, action) => {
      state.tree = action.payload;
    });
    builder.addCase(fetchBranchList.fulfilled, (state, action) => {
      state.branch = action.payload;
    });
    builder.addCase(fetchSubBranchList.fulfilled, (state, action) => {
      state.subBranch = action.payload;
    });
  },
});

export default directorySlice.reducer;

export const directoryStateItem = createSelector(
  (state: RootState) => state[sliceName],
  (items: InitialState) => items
);

export const directoryUserStateItem = createSelector(
  (state: RootState) => state[sliceName].users,
  (items: any[]) => items
);

export const directoryDevicesStateItem = createSelector(
  (state: RootState) => state[sliceName].devices,
  (items: any) => items
);
