import { Alert, Snackbar } from '@mui/material';
import { createContext, useContext, useMemo, useState, useCallback } from 'react';

export const SnackbarContext = createContext<any>({
  setSnackBar: () => null,
});

type Props = {
  children: React.ReactNode;
};

const SnackBarWrapper = ({ children }: Props) => {
  const [snack, setSnackBar] = useState({
    message: '',
    success: false,
    open: false,
  });

  const memo = useMemo(
    () => ({
      snack,
      setSnackBar,
    }),
    [snack]
  );

  const handleClose = useCallback(() => {
    setSnackBar((prev) => ({
      ...prev,
      open: false,
    }));
  }, []);

  return (
    <SnackbarContext.Provider value={memo}>
      <Snackbar open={snack?.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity={snack?.success ? 'success' : 'error'}>{snack?.message}</Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export const snackbarContext = () => useContext(SnackbarContext);

export default SnackBarWrapper;
