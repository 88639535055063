import React, { useState, useEffect, ReactNode } from 'react';
import { useAppSelector, useTokenCallBack } from 'src/store/hooks';
import { getOrgUnits } from 'src/api/user/google';
import { accountStateItem } from 'src/slices/accountSlice';
import { SERVICE_ACCOUNT_STATES } from 'src/constants/data/account.constants';
import { SUBSCRIPTION_TIER } from 'src/constants/data/paid-tier.constants';
import { fetchServiceAccountByAccountNumber } from 'src/api/account/serviceAccount';
import appLogger from 'src/infrastructure/config/appLogger';
import { useUserDetails } from './UserDetailWrapper';
import OrgUnitsContext, { OrgUnitContextType } from './OrgUnitsContext';

interface OrgUnitsProviderProps {
  children: ReactNode;
}

const OrgUnitsProvider: React.FC<OrgUnitsProviderProps> = ({ children }) => {
  const [orgUnits, setOrgUnits] = useState<OrgUnitContextType>({
    data: [],
    isLoading: true,
  });
  const userData = useUserDetails();
  const { accountDetails } = useAppSelector(accountStateItem);
  const subscriptionTier = accountDetails?.subscription_tier || SUBSCRIPTION_TIER.STARTER;
  const accountNumber = userData?.user?.account || 0;
  const makeTokenCall = useTokenCallBack();

  useEffect(() => {
    if ([SUBSCRIPTION_TIER.PROFESSIONAL, SUBSCRIPTION_TIER.ENTERPRISE].includes(subscriptionTier)) {
      setOrgUnits((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      makeTokenCall(async (token) => {
        try {
          const response = await fetchServiceAccountByAccountNumber({ accountNumber, token });
          const serviceAccount = response.data;
          if (
            serviceAccount.account_number &&
            serviceAccount.current_state !== SERVICE_ACCOUNT_STATES.NOT_SETUP &&
            serviceAccount.current_state !== SERVICE_ACCOUNT_STATES.ERRORED
          ) {
            const orgUnitsData = await getOrgUnits(accountNumber, token);
            setOrgUnits({ data: orgUnitsData.data, isLoading: false });
          } else {
            appLogger.log('Service account not properly set up or in an errored state.');
          }
        } catch (error) {
          appLogger.log(`Failed to fetch organization units or validate service account: ${error}`);
          setOrgUnits((prevState) => ({
            ...prevState,
            isLoading: false,
            data: [],
          }));
        }
      });
    } else {
      appLogger.log('Subscription tier not eligible for fetching organization units.');
      setOrgUnits((prevState) => ({
        ...prevState,
        isLoading: false,
        data: [],
      }));
    }
  }, [makeTokenCall, accountNumber, subscriptionTier]);

  return <OrgUnitsContext.Provider value={orgUnits}>{children}</OrgUnitsContext.Provider>;
};

export default OrgUnitsProvider;
