import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, DialogActions } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  DialogModalAction,
  DialogModalActionMargin,
  DialogModalActions,
  DialogModalCloseIcon,
  DialogModalContent,
  DialogModalTitle,
  DialogModalCustomButton,
  direction,
} from './styles_v2';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';

interface Action {
  name: string | JSX.Element;
  method: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}
interface Props extends DialogProps {
  open: boolean;
  title?: string;
  isFullScreen?: boolean;
  handleClose: any;
  disabledCloseButton?: boolean;
  children?: JSX.Element;
  primaryAction?: Action;
  secondaryAction?: Action;
  modalActionDirection?: direction;
  showActions?: boolean;
  customButton?: JSX.Element | null;
  disablePrimaryAction?: boolean;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const DialogWrapperV2 = ({
  open,
  title = '',
  isFullScreen = false,
  handleClose,
  disabledCloseButton = false,
  children,
  primaryAction = { name: '', method: () => null, disabled: false },
  secondaryAction = { name: '', method: () => null, disabled: false },
  modalActionDirection = 'row',
  showActions = true,
  customButton = null,
  disablePrimaryAction = false,
  ...props
}: Props) => {
  const handleDialogClose = (event: any) => {
    event.preventDefault();
    handleClose(false);
  };

  return (
    <Dialog
      fullScreen={isFullScreen}
      open={open}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
      scroll="paper"
      {...props}
      maxWidth={props.maxWidth ?? 'md'}
    >
      <Toolbar>
        {title !== '' && (
          <Typography sx={DialogModalTitle} variant="h6" component="div">
            {title}
          </Typography>
        )}
        <IconButton
          edge="start"
          sx={DialogModalCloseIcon}
          onClick={handleDialogClose}
          disabled={disabledCloseButton}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      {children && (
        <DialogContent sx={DialogModalContent} dividers>
          {children}
        </DialogContent>
      )}
      {showActions && (
        <DialogActions
          sx={[
            DialogModalActions,
            {
              flexDirection: modalActionDirection,
            },
          ]}
        >
          {customButton && <Box sx={DialogModalCustomButton}>{customButton}</Box>}
          {!isEmpty(secondaryAction.name) && (
            <SecondaryButton
              sx={DialogModalAction}
              onClick={secondaryAction.method}
              disabled={secondaryAction.disabled}
            >
              {secondaryAction.name}
            </SecondaryButton>
          )}
          {!isEmpty(primaryAction.name) && (
            <PrimaryButton
              sx={[DialogModalAction, DialogModalActionMargin(modalActionDirection)]}
              onClick={primaryAction.method}
              disabled={primaryAction.disabled || disablePrimaryAction}
            >
              {primaryAction.name}
            </PrimaryButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogWrapperV2;
