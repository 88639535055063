export const FILTER_GUIDE = {
  titleNewRelease: 'Introducing: Dream Data Filtering Guide 🎉',
  title: 'Dream Data Filtering Guide',
  introduction:
    'Welcome to the Dream Data Filtering Guide! This tutorial will help you understand how to use the filtering features in Dream to find and manage your data efficiently.',
  steps: [
    {
      heading: 'How to Apply Filters',
      steps: [
        {
          title: 'Open the Filter Menu',
          description:
            'Click on the menu icon (≡) in the header of the column you wish to filter. This opens the filter menu for that column.',
        },
        {
          title: 'Choose Filter Type',
          description:
            'Select the appropriate filter type based on the data in the column. Options include: Contains, Does Not Contain, Equals, Begins with, and many more.',
        },
        {
          title: 'Set Filter Criteria',
          description: 'Enter your filter criteria into the input fields to find exactly what you need.',
        },
        {
          title: 'Apply the Filter',
          description:
            'Click the "Apply" button to filter the data. The table will update to show results that match your criteria.',
        },
        {
          title: 'Clear or Reset Filter',
          description:
            'To remove the filter, click the "Clear" button or reset the criteria. The table will display all records again.',
        },
      ],
    },
    {
      heading: 'More Filters and Sorting',
      description:
        'You can filter like this with multiple columns and sort columns according to your needs by simply clicking the column header.',
    },
  ],
  videoTutorial: 'Brief Video Tutorial',
};

export default FILTER_GUIDE;
