import React from 'react';
import { Button, darken, styled, Tooltip } from '@mui/material';
import { setWith } from 'lodash';
import COLORS from 'src/constants/colors/colors.constant';
import { getLuminance } from 'src/utils/colorConversion';

interface PrimaryButtonProps {
  tooltip?: string;
  [key: string]: any;
}

export const PrimaryButtonCore = styled(Button)(({ theme }) => {
  setWith(theme, 'palette.primary.main', theme.palette.primary.main);

  const backgroundColor = theme.palette.primary.main;
  const luminance = getLuminance(backgroundColor);
  let textColor;
  let hoverTextColor;
  let hoverBgColor = darken(backgroundColor, 0.2);

  if (luminance < 0.3) {
    textColor = COLORS.primary.textDark;
    hoverBgColor = darken(backgroundColor, 0.2);
  } else if (luminance > 0.7) {
    textColor = darken(backgroundColor, 0.6);
    hoverTextColor = darken(textColor, 0.2);
    hoverBgColor = darken(backgroundColor, 0.075);
  } else {
    textColor = COLORS.primary.textDark;
  }

  return {
    fontWeight: 'bold',
    padding: '8px 16px',
    color: textColor,
    backgroundColor,
    borderRadius: theme.spacing(1),
    textTransform: 'none',
    gap: theme.spacing(1),
    '&.MuiButton-root:hover': {
      backgroundColor: hoverBgColor,
      color: hoverTextColor,
    },
    '&.MuiButton-root:disabled': {
      color: COLORS.primary.offWhite,
      backgroundColor: COLORS.primary.disabledGrey,
      pointerEvents: 'auto',
    },
  };
});

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ tooltip, ...props }) =>
  tooltip ? (
    <Tooltip title={tooltip}>
      <PrimaryButtonCore {...props} />
    </Tooltip>
  ) : (
    <PrimaryButtonCore {...props} />
  );

export default PrimaryButton;
