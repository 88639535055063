import { Typography, AppBar, Toolbar, useTheme } from '@mui/material';
import { snackbarContext } from 'src/wrappers/SnackBarWrapper';
import topAppBarStyles from 'src/creative_commons/topBarContainer/topAppBar.styles';
import { storage } from 'src/api/base';
import { SPOOFING_ACTION } from 'src/constants/data/topAppBar.constants';
import { spoofuser } from 'src/api/spoof';
import transformErrorMessage from 'src/utils/transformers';
import { DefaultButton } from '../StyledComponent';

const TopBar = () => {
  const theme = useTheme();
  const styles = topAppBarStyles(theme);
  const spoofingUser = storage.getKey('spoofingUserDetails');
  const snackBar = snackbarContext();

  const removeSpoofLoginUser = async () => {
    const token = await storage.getKey('userToken');
    const data = {
      email: spoofingUser?.spoofingEmail,
      action: SPOOFING_ACTION.logout,
      token,
    };
    try {
      await spoofuser(data);
      snackBar.setSnackBar({ message: 'Removed spoofed user', open: true, success: true });
      storage.removeKey('spoofingUserDetails');
      storage.removeKey('userToken');

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (ex: any) {
      snackBar.setSnackBar({
        message: transformErrorMessage(ex, 'Failed to logout of spoofed user'),
        success: false,
        open: true,
      });
    }
  };

  return spoofingUser?.isSpoofing ? (
    <AppBar position="static" sx={styles.spoofTopBar}>
      <Toolbar>
        <Typography component="div">
          You logged in as &apos;{spoofingUser?.spoofingEmail}&apos;. &nbsp;
          <DefaultButton onClick={removeSpoofLoginUser}>Click here</DefaultButton>&nbsp; to return to your user.
        </Typography>
      </Toolbar>
    </AppBar>
  ) : null;
};

export default TopBar;
