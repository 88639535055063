import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { CircularProgress, Box } from '@mui/material';
import { store } from '../store';
import ThemeWrapper from './ThemeWrapper';

type Props = {
  children: React.ReactNode;
};

const ProviderWrapper = ({ children }: Props) => (
  <Provider store={store}>
    <Suspense
      fallback={
        <Box sx={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
          <CircularProgress />
        </Box>
      }
    >
      <ThemeWrapper>{children}</ThemeWrapper>
    </Suspense>
  </Provider>
);

export default ProviderWrapper;
