import isEmpty from 'lodash/isEmpty';

const generateKey = (key: string, parentKey?: string): string => {
  if (isEmpty(parentKey)) {
    return `storageData${key}`;
  }
  return `${parentKey}-${key}`;
};

type LocalStorage = {
  getItem: (key: any) => any;
  removeItem: (key: any) => any;
  setItem: (key: any, value: any) => void;
};

const getLocalStorage = (): LocalStorage => {
  if (typeof window !== 'undefined') {
    return window.localStorage;
  }
  return {
    getItem: () => null,
    removeItem: () => null,
    setItem: () => null,
  };
};

const getKey =
  (parentKey?: string) =>
  (key: string, defaultValue: any = null): any => {
    const keyName = generateKey(key, parentKey);
    const localStorage = getLocalStorage();
    const value = localStorage.getItem(keyName);
    if (!isEmpty(value)) {
      return JSON.parse(value);
    }
    return defaultValue;
  };

const storeKey = (parentKey?: string) => (key: string, value: unknown) => {
  const keyName = generateKey(key, parentKey);
  const localStorage = getLocalStorage();
  localStorage.setItem(keyName, JSON.stringify(value));
};

const removeKey = (parentKey?: string) => (key: string) => {
  const keyName = generateKey(key, parentKey);
  const localStorage = getLocalStorage();
  localStorage.removeItem(keyName);
};

const getStorage = (parentKey?: string) => ({
  getKey: getKey(parentKey),
  storeKey: storeKey(parentKey),
  removeKey: removeKey(parentKey),
});

export type StorageType = {
  getKey: (key: string, defaultValue?: any) => any;
  storeKey: (key: string, value: unknown) => void;
  removeKey: (key: string) => void;
};

export default getStorage;
