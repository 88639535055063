import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Config from '../infrastructure/config/config';
import { HOME_ROUTE } from '../constants/routes';

type Props = {
  children: React.ReactNode;
};

const Auth0ProviderWithHistory = ({ children }: Props) => {
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const audience = `https://${domain}/api/v2/`;
  const config = useMemo(() => Config.getConfig(), []);

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || HOME_ROUTE);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: config.hostName as any,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
