import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';
import COLORS from 'src/constants/colors/colors.constant';
import { setLoading, setGlobalError } from './commonSlice';
import {
  getPaginatedList,
  getOfficeSites,
  fetchColor,
  updateColor,
  BuildingColor,
  FavCreateInitialType,
  createFavView,
  fetchFav,
  FavoriteType,
  deleteOne,
  OfficeSiteItem,
  fetchBuildingDetailsByEmailId,
  getBuildingNoPage,
  fetchBuildingByAccount,
  getAllbuildings,
  FetchParamTypesWithPage,
  getBuildingData,
} from '../api/building';
import appLogger from '../infrastructure/config/appLogger';
import { PER_PAGE_RECORD, PER_PAGE_RECORD_100 } from '../constants/data/roadmap.constants';
import { isAPIResponseFailure, RecordType } from '../api/base';
import type { BuildingItem } from '../api/building';
import type { RootState } from '../store';
import { BuildingDashboardTableData, FetchBuildingParamTypes } from '../api/types/building';

const sliceName = 'building';

export interface InitialState {
  page: number;
  perPage: number;
  totalCount: number;
  buildingColor: Array<string>;
  FavItem: any | FavoriteType;
  totalPages: number;
  buildingList: any;
  allBuildingList: BuildingItem[];
  officeSites: OfficeSiteItem[];
  selectedItem: null | BuildingItem;
  officeSiteTotalCount: number;
  officeSiteTotalPages: number;
  accountBuilding: any[];
  allBuilding: any[];
  totalBuildingPages: number;
  buildingData: BuildingDashboardTableData[];
  buildingLoading: boolean;
}

const initialState: InitialState = {
  page: 1,
  perPage: PER_PAGE_RECORD,
  totalCount: 0,
  totalPages: 0,
  buildingColor: [COLORS.primary.main, COLORS.secondary.main],
  FavItem: null,
  buildingList: [],
  allBuildingList: [],
  officeSites: [],
  officeSiteTotalCount: 0,
  officeSiteTotalPages: 0,
  selectedItem: null,
  accountBuilding: [],
  allBuilding: [],
  totalBuildingPages: 0,
  buildingData: [],
  buildingLoading: false,
};

type FetchFavoriteWithToken = {
  userId?: number | string;
  token: string;
};

type FavDeleteType = {
  recordId?: string | number;
  token: string;
};

export const fetchBuildingItems = createAsyncThunk<BuildingItem[], { token: string }>(
  `${sliceName}/fetch`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getPaginatedList({ token: params.token });
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      ThunkAPI.dispatch(setGlobalError(e));
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllInfoBuilding = createAsyncThunk<any[], FetchParamTypesWithPage>(
  `${sliceName}/fetchAllInfoBuilding`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchAllInfoBuilding`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getAllbuildings(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      ThunkAPI.dispatch(setGlobalError(e));
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchOfficeSiteItems = createAsyncThunk<OfficeSiteItem[], { token: string }>(
  `${sliceName}/fetch-office-sites`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getOfficeSites({ token: params.token });
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

type FetchBuildingDetailsWithToken = {
  emailAddress?: string;
  token?: string;
  page?: number;
  searchField?: string;
  searchValue?: string;
  contains?: string;
};

type FetchBuildingDetailsWithTokenNoPage = {
  email?: string;
  token?: string;
};

export const fetchBuildingDropdown = createAsyncThunk<BuildingItem | any, FetchBuildingDetailsWithTokenNoPage>(
  `${sliceName}/fetchByEmailAddressNoPage`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchByEmailAddressNoPage`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.email) {
        const response: any = await getBuildingNoPage(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

type FetchBuildingWithAccount = {
  itemId: string | number;
  token: string;
};

export const fetchBuildingAccount = createAsyncThunk<any | any, FetchBuildingWithAccount>(
  `${sliceName}/fetchByAccount`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchByAccount`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.itemId) {
        const response: any = await fetchBuildingByAccount(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchBuildingDetailsByEmail = createAsyncThunk<any, FetchBuildingDetailsWithToken>(
  `${sliceName}/fetchByEmailAddress`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchByEmailAddress`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.emailAddress) {
        const response: any = await fetchBuildingDetailsByEmailId(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const updateBuildingColor = createAsyncThunk<null, BuildingColor>(
  `${sliceName}/updateColor`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/updateColor`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await updateColor(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      thunkAPI.dispatch(setGlobalError(e));
      appLogger.error(e);
      throw e;
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
  }
);

export const fetchBuildingColor = createAsyncThunk(`${sliceName}/fetchColor`, async (params: any, ThunkAPI) => {
  const loaderName = `${sliceName}/fetchColor`;
  ThunkAPI.dispatch(setLoading([loaderName, true]));
  try {
    const buildingColor = await fetchColor(params);
    ThunkAPI.dispatch(setLoading([loaderName, false]));
    if (isAPIResponseFailure(buildingColor)) {
      return ThunkAPI.rejectWithValue(buildingColor);
    }
    return buildingColor?.data;
  } catch (e) {
    ThunkAPI.dispatch(setGlobalError(e));
    appLogger.error(e);
    throw e;
  } finally {
    ThunkAPI.dispatch(setLoading([loaderName, false]));
  }
});

export const createFavItem = createAsyncThunk<RecordType | null, FavCreateInitialType>(
  `${sliceName}/createByFav`,
  async (params, thunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/createByFav`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await createFavView(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      thunkAPI.dispatch(setGlobalError(e));
      appLogger.error(e);
      throw e;
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
  }
);

export const deleteOneFav = createAsyncThunk<RecordType | any, FavDeleteType>(
  `${sliceName}/deleteFavorite`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/deleteFavorite`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await deleteOne(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      thunkAPI.dispatch(setGlobalError(e));
      appLogger.error(e);
      throw e;
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
  }
);

export const fetchFavItems = createAsyncThunk<FavoriteType | any, FetchFavoriteWithToken>(
  `${sliceName}/fetchFav`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchFav`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.userId !== null) {
        const response = await fetchFav(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      thunkAPI.dispatch(setGlobalError(e));
      appLogger.error(e);
      throw e;
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchAllBuilding = createAsyncThunk<BuildingDashboardTableData[], FetchBuildingParamTypes>(
  `${sliceName}/fetchAllBuilding`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchAllBuilding`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getBuildingData(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      ThunkAPI.dispatch(setGlobalError(e));
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

const buildingSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllBuilding.pending, (state) => {
      state.buildingLoading = true;
    });
    builder.addCase(fetchAllBuilding.fulfilled, (state, action) => {
      state.buildingData = action.payload;
      state.buildingLoading = false;
    });
    builder.addCase(fetchAllBuilding.rejected, (state) => {
      state.buildingLoading = false;
    });
    builder.addCase(fetchBuildingItems.fulfilled, (state, action) => {
      const { payload } = action;
      state.allBuildingList = get(payload, 'rows', []);
    });
    builder.addCase(fetchBuildingDropdown.fulfilled, (state, action) => {
      state.buildingList = get(action, 'payload.rows') || [];
    });
    builder.addCase(fetchBuildingColor.fulfilled, (state, action) => {
      state.buildingColor = action.payload;
    });
    builder.addCase(fetchBuildingAccount.fulfilled, (state, action) => {
      state.accountBuilding = action.payload;
    });
    builder.addCase(fetchAllInfoBuilding.fulfilled, (state, action) => {
      const { payload } = action;
      state.allBuilding = get(payload, 'rows', []);
      const totalBuildingCount = get(payload, 'total', 0);
      state.totalBuildingPages = Math.ceil(totalBuildingCount / PER_PAGE_RECORD_100);
    });
    builder.addCase(fetchBuildingDetailsByEmail.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.buildingList = get(payload, 'rows') || [];
      state.totalCount = totalCount;
      state.totalPages = Math.ceil(totalCount / state.perPage);
    });
    builder.addCase(fetchFavItems.fulfilled, (state, action) => {
      state.FavItem = action.payload;
    });
    builder.addCase(fetchOfficeSiteItems.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.officeSites = get(payload, 'rows', []);
      state.officeSiteTotalCount = totalCount;
      state.officeSiteTotalPages = Math.ceil(totalCount / state.perPage);
    });
  },
});

export default buildingSlice.reducer;

export const buildingStateItem = createSelector(
  (state: RootState) => state.building,
  (items: InitialState) => items
);

export const selectBuildingData = createSelector(
  (state: RootState) => state.building.buildingData,
  (dashboardData) => dashboardData
);

export const selectBuildingDataLoading = createSelector(
  (state: RootState) => state.building.buildingLoading,
  (loading) => loading
);
