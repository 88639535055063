import { useLocation } from 'react-router-dom';

export function decodeJwtToken(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = window.atob(base64);

  return JSON.parse(jsonPayload);
}

export function downloadStringAsCSV(name: string, content: string) {
  const atag = document.createElement('a');
  const file = new Blob([content], { type: 'text/csv' });
  atag.href = URL.createObjectURL(file);
  atag.download = name;
  atag.click();
}

export function openBlobAsPDF(content: Blob | null) {
  if (!content) {
    return;
  }

  const atag = document.createElement('a');
  atag.href = URL.createObjectURL(content);
  atag.target = '_blank';
  atag.click();
}

export function downloadBlobAsPDF(name: string, content: Blob | null) {
  if (!content) {
    return;
  }

  const atag = document.createElement('a');
  atag.href = URL.createObjectURL(content);
  atag.download = name;
  atag.click();
}

export function setCookie(name, value, hours) {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + hours * 60 * 60 * 1000);
  const expires = `expires=${expirationDate.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

export function getCookie(name) {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return '';
}

export const useQueryParam = (param: string) => {
  const query = new URLSearchParams(useLocation().search);
  const value = query.get(param);
  return value ? value.split(',') : [];
};

export default downloadStringAsCSV;
