import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { Video, VideoCategory, listVideos, listVideoCategories } from '../api/videos';
import { setLoading } from './commonSlice';
import { isAPIResponseFailure } from '../api/base';
import appLogger from '../infrastructure/config/appLogger';
import type { RootState } from '../store';
import { deviceCategory } from '../api/device/device';

const sliceName = 'resources';

export interface InitialState {
  categories: VideoCategory[];
  videos: Video[];
  devicecategories: deviceCategory[];
  videoCount: number;
  videoCategoryCount: number;
}

const initialState: InitialState = {
  categories: [],
  videos: [],
  devicecategories: [],
  videoCount: 0,
  videoCategoryCount: 0,
};

type TokenParam = {
  token: string;
};

export const fetchVideoItems = createAsyncThunk<Video[], TokenParam>(
  `${sliceName}/fetchVideoItems`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchVideoItems`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await listVideos(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchVideoCategoryItems = createAsyncThunk<VideoCategory[], TokenParam>(
  `${sliceName}/fetchVideoCategoryItems`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchVideoCategoryItems`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await listVideoCategories(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

const resourceSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVideoItems.fulfilled, (state, action) => {
      const { payload } = action;
      state.videos = payload;
      state.videoCount = payload.length;
    });
    builder.addCase(fetchVideoCategoryItems.fulfilled, (state, action) => {
      const { payload } = action;
      state.categories = payload;
      state.videoCategoryCount = payload.length;
    });
  },
});

export default resourceSlice.reducer;

export const resourceStateItem = createSelector(
  (state: RootState) => state.resource,
  (items: InitialState) => items
);

export const resourceVideoCount = createSelector(
  (state: RootState) => state.resource.videoCount,
  (count: number) => count
);
