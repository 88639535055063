import { get, head } from 'lodash';
import { createSlice, createSelector, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import isValid from 'date-fns/isValid';
import { setLoading } from './commonSlice';
import {
  createAccount,
  FetchParamTypes,
  fetchAccountById,
  getPaginatedList,
  AccountCreateInitialType,
  AccountDetails,
  fetchAccountDetailsByEmailId,
  AccountBuilding,
  fetchAccountBuildingById,
  getAllAccounts,
  getPaginatedAccountList,
  updateAccount,
  USER_PROFILE,
  getAccountDashboardData,
  startTrialTierAccount,
  getAllAccountsForAdmin,
} from '../api/account/account';
import { ServiceAccount, fetchServiceAccountByAccountNumber } from '../api/account/serviceAccount';
import appLogger from '../infrastructure/config/appLogger';
import { PER_PAGE_RECORD_DEFAULT } from '../constants/data/account.constants';
import { isAPIResponseFailure, RecordType } from '../api/base';
import type { AccountItem, FetchParamTypesNoPage, FetchParamTypesWithPageAccount } from '../api/account/account';
import type { RootState } from '../store';
import type { AuthData, ModifyType } from '../api/user/user';
import { AccountDashboardTableData, FetchAccountDashboardParamTypes } from '../api/types/account';
import { getAdminUsers } from '../api/externalUser';

const sliceName = 'account';

export interface InitialState {
  page: number;
  perPage: number;
  totalCount: number;
  totalPages: number;
  allAccountCount: number;
  allAccountPages: number;
  accountList: AccountItem[];
  selectedItem: null | AccountItem;
  accountDetails: any | AccountDetails;
  allAccountList: AccountDetails[];
  accountBuildingItem: any | AccountBuilding;
  user: null | AuthData;
  accountLists: any[];
  subscriptionTierLoading: boolean;
  totalAll: number;
  userModifyData: null | ModifyType;
  dashboardDataLoading: boolean;
  dashboardData: AccountDashboardTableData[] | null;
  adminData: AccountItem[] | null;
  allAccountListManage: any | null;
  allAccountListManageLoading: boolean;
}

const initialState: InitialState = {
  page: 1,
  perPage: PER_PAGE_RECORD_DEFAULT,
  totalCount: 0,
  totalPages: 0,
  totalAll: 0,
  allAccountCount: 0,
  allAccountPages: 0,
  accountList: [],
  allAccountList: [],
  allAccountListManage: [],
  allAccountListManageLoading: false,
  selectedItem: null,
  accountDetails: null,
  accountBuildingItem: null,
  user: null,
  accountLists: [],
  userModifyData: null,
  dashboardDataLoading: false,
  dashboardData: null,
  adminData: null,
  subscriptionTierLoading: false,
};

type FetchWithToken = {
  itemId: number | string;
  token?: string;
};

type FetchAccountDetailsWithToken = {
  emailAddress?: string;
  token?: string;
};

type FetchAccountBuildingWithToken = {
  itemId: number | string;
  token?: string;
};

type FetchServiceAccountWithToken = {
  accountNumber: number;
  token?: string;
};

export const fetchAccountDashboardData = createAsyncThunk<AccountDashboardTableData[], FetchAccountDashboardParamTypes>(
  `${sliceName}/fetchDashboard`,
  async (params, { rejectWithValue, dispatch }) => {
    const loaderName = `${sliceName}/fetchDashboard`;
    dispatch(setLoading([loaderName, true])); // Start loading

    try {
      const response = await getAccountDashboardData(params); // Make the API call

      if (isAPIResponseFailure(response)) {
        // Check for API failure
        return rejectWithValue(response); // Handle failure
      }

      return response.data; // Success
    } catch (error) {
      appLogger.error(error); // Log error
      return rejectWithValue(error); // Handle error
    } finally {
      dispatch(setLoading([loaderName, false])); // End loading
    }
  }
);

export const fetchAccountItems = createAsyncThunk<AccountItem[], FetchParamTypesWithPageAccount>(
  `${sliceName}/fetch`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getPaginatedList(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllAccountForAdmin = createAsyncThunk<AccountDetails[], FetchParamTypes>(
  `${sliceName}/manageAccounts`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/manageAccounts`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAllAccountsForAdmin(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllAccount = createAsyncThunk<AccountDetails[], FetchParamTypes>(
  `${sliceName}/accountfetch`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/accountfetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAllAccounts(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const startTrialTier = createAsyncThunk<AccountDetails[], FetchServiceAccountWithToken>(
  `${sliceName}/start-trial`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/start-trial`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response: any = await startTrialTierAccount(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      appLogger.error(response);
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllAccountNoPage = createAsyncThunk<AccountDetails[], FetchParamTypesNoPage>(
  `${sliceName}/accountfetchNoPage`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/accountfetchNoPage`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAllAccounts(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllAccountItem = createAsyncThunk<AccountDetails[], FetchParamTypes>(
  `${sliceName}/fetchAllAccount`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/fetchAllAccount`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getPaginatedAccountList(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const createNewAccountItem = createAsyncThunk<RecordType | null, AccountCreateInitialType>(
  `${sliceName}/create`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/create`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await createAccount(params);
      if (isAPIResponseFailure(response)) {
        return thunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchAllAdminAccount = createAsyncThunk<AccountItem[], FetchParamTypes>(
  `${sliceName}/adminaccountfetch`,
  async (params, ThunkAPI) => {
    appLogger.log(params);
    const loaderName = `${sliceName}/adminaccountfetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const response = await getAdminUsers(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(response)) {
        return ThunkAPI.rejectWithValue(response);
      }
      return response.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const updateAccountItem = createAsyncThunk<
  RecordType | null,
  { recordId?: string | number; updateData: USER_PROFILE }
>(`${sliceName}/update`, async (params, thunkAPI) => {
  appLogger.log(params);
  const loaderName = `${sliceName}/fetch`;
  thunkAPI.dispatch(setLoading([loaderName, true]));
  try {
    const response = await updateAccount(params);
    if (isAPIResponseFailure(response)) {
      return thunkAPI.rejectWithValue(response);
    }
    return response.data;
  } catch (e) {
    appLogger.error(e);
  } finally {
    thunkAPI.dispatch(setLoading([loaderName, false]));
  }
  return null;
});

export const fetchAccountItem = createAsyncThunk<AccountItem | any, FetchWithToken>(
  `${sliceName}/fetchById`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchById`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.itemId !== null) {
        const response = await fetchAccountById(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchAccountBuildingItem = createAsyncThunk<AccountBuilding | any, FetchAccountBuildingWithToken>(
  `${sliceName}/fetchAccountBuildingById`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchAccountBuildingById`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.itemId && params.itemId !== null) {
        const response = await fetchAccountBuildingById(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchAccountDetails = createAsyncThunk<AccountDetails | any, FetchAccountDetailsWithToken>(
  `${sliceName}/fetchByEmailAddress`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchByEmailAddress`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.emailAddress) {
        const response: any = await fetchAccountDetailsByEmailId(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

export const fetchServiceAccount = createAsyncThunk<ServiceAccount, FetchServiceAccountWithToken>(
  `${sliceName}/fetchServiceAccount`,
  async (params, thunkAPI) => {
    const loaderName = `${sliceName}/fetchServiceAccount`;
    thunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      if (params.accountNumber) {
        const response: any = await fetchServiceAccountByAccountNumber(params);
        if (isAPIResponseFailure(response)) {
          return thunkAPI.rejectWithValue(response);
        }
        return response.data;
      }
    } catch (e) {
      appLogger.error(e);
    } finally {
      thunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return null;
  }
);

// @TODO - Add thunk reducer as well
const accountSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setUserDetails: (state, payload: PayloadAction<AuthData>) => {
      state.user = payload.payload;
    },
    handleRefreshUserData: (state, payload: PayloadAction<null | string>) => {
      const payLoadValue = payload.payload || '';
      const date = new Date(payLoadValue);
      if (isValid(date)) {
        state.userModifyData = {
          lastDate: payLoadValue,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllAccountForAdmin.pending, (state) => {
      state.allAccountListManageLoading = true;
    });
    builder.addCase(fetchAllAccountForAdmin.fulfilled, (state, action) => {
      state.allAccountListManage = action.payload;
      state.allAccountListManageLoading = false;
    });
    builder.addCase(fetchAllAccountForAdmin.rejected, (state) => {
      state.allAccountListManageLoading = false;
    });
    builder.addCase(fetchAccountDashboardData.pending, (state) => {
      state.dashboardDataLoading = true;
    });
    builder.addCase(fetchAccountDashboardData.fulfilled, (state, action) => {
      state.dashboardData = action.payload;
      state.dashboardDataLoading = false;
    });
    builder.addCase(fetchAccountDashboardData.rejected, (state) => {
      state.dashboardDataLoading = false;
    });
    builder.addCase(fetchAccountItems.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.accountList = get(payload, 'rows', []);
      state.totalCount = totalCount;
      state.totalPages = Math.ceil(totalCount / state.perPage);
    });
    builder.addCase(fetchAllAccountItem.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.allAccountCount = totalCount;
      state.allAccountPages = Math.floor(totalCount / state.perPage);
      state.allAccountList = get(payload, 'rows', []);
    });
    builder.addCase(fetchAccountDetails.fulfilled, (state, action) => {
      const { payload } = action;
      state.accountDetails = head(payload);
    });
    builder.addCase(fetchAccountBuildingItem.fulfilled, (state, action) => {
      const { payload } = action;
      state.accountBuildingItem = payload;
    });
    builder.addCase(fetchAllAccountNoPage.fulfilled, (state, action) => {
      const { payload } = action;
      state.allAccountList = payload;
    });
    builder.addCase(fetchAccountItem.pending, (state) => {
      state.selectedItem = null;
    });
    builder.addCase(fetchAccountItem.fulfilled, (state, action) => {
      state.selectedItem = action.payload;
    });
    builder.addCase(fetchAllAccount.pending, (state) => {
      state.accountLists = [];
      state.subscriptionTierLoading = true;
    });
    builder.addCase(fetchAllAccount.fulfilled, (state, action) => {
      const { payload } = action;
      state.accountLists = get(payload, 'rows', []);
      const totalCount = get(payload, 'total', 0);
      state.totalAll = Math.floor(totalCount / state.perPage);
      state.subscriptionTierLoading = false;
    });
    builder.addCase(fetchAllAccount.rejected, (state) => {
      state.accountLists = [];
      state.subscriptionTierLoading = false;
    });
    builder.addCase(fetchAllAdminAccount.pending, (state) => {
      state.dashboardDataLoading = true;
    });
    builder.addCase(fetchAllAdminAccount.fulfilled, (state, action) => {
      state.adminData = action.payload;
      state.dashboardDataLoading = false;
    });
    builder.addCase(fetchAllAdminAccount.rejected, (state) => {
      state.dashboardDataLoading = false;
    });
  },
});

export const { setUserDetails, handleRefreshUserData } = accountSlice.actions;

export default accountSlice.reducer;

export const accountStateItem = createSelector(
  (state: RootState) => state.account,
  (items: InitialState) => items
);

export const accountManagementSelector = createSelector(
  (state: RootState) => state.account.allAccountListManage,
  (items: any) => items
);

export const accountManagementLoadingSelector = createSelector(
  (state: RootState) => state.account.allAccountListManageLoading,
  (items: boolean) => items
);

export const accountItemSelectedState = createSelector(
  (state: RootState) => state.account.selectedItem,
  (item: AccountItem | null) => item
);

export const getGoogleUserData = createSelector(
  (state: RootState) => state.account.user,
  (item: AuthData | null) => item
);

export const modifiedStateItem = createSelector(
  (state: RootState) => get(state, [sliceName, 'userModifyData']),
  (item: null | ModifyType) => item
);

export const selectDashboardData = createSelector(
  (state: RootState) => state.account.dashboardData,
  (dashboardData) => dashboardData
);

export const selectDashboardDataLoading = createSelector(
  (state: RootState) => state.account.dashboardDataLoading,
  (loading) => loading
);
