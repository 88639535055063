/* eslint-disable import/no-unresolved */
// Account Dashboard - navigation
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DevicesIcon from '@mui/icons-material/Devices';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import HardwareIcon from '@mui/icons-material/Hardware';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { RepairStatusOptions } from 'src/api/repair';
import { MANAGE_DEVICES, REPAIR_CENTER_ROUTE } from '../routes';
// REPORT_CARD_IMAGES
import { ReactComponent as LeftTruck } from '../../assets/svg/report-card/left-truck.svg';
import { ReactComponent as RightTruck } from '../../assets/svg/report-card/right-truck.svg';
import { ReactComponent as Tools } from '../../assets/svg/report-card/tools.svg';
import { ReactComponent as Clock } from '../../assets/svg/report-card/clock.svg';
import { ReactComponent as Invoice } from '../../assets/svg/report-card/invoice.svg';
import { ReactComponent as Ticket } from '../../assets/svg/report-card/ticket.svg';
import { ReactComponent as PendingActions } from '../../assets/svg/report-card/pending-actions.svg';
import {
  ACCOUNT_DASHBOARD_SUB_MODULE_MAP,
  REPORT_CENTER_SUB_MODULE_MAP,
  PAGE_NAMES,
  INVOICES_ORDERS_SUB_MODULE_MAP,
} from '../../components/roles/constants';
import { spaceToUnderscoreFormatter } from '../../utils/textValueFormatters';

export const labels = [
  RepairStatusOptions.DidNotArrive,
  RepairStatusOptions.PendingRepairBox,
  RepairStatusOptions.BoxCreated,
  RepairStatusOptions.ShippingToVivacity,
  RepairStatusOptions.InTransit,
  RepairStatusOptions.ReceivedByVivacity,
  RepairStatusOptions.IntakeQueue,
  RepairStatusOptions.Intake,
  RepairStatusOptions.WaitingOnDeprovision,
  RepairStatusOptions.ClaimOverage,
  RepairStatusOptions.WaitingOnRepairApproval,
  RepairStatusOptions.EstimateApproved,
  RepairStatusOptions.EstimateDenied,
  RepairStatusOptions.ClaimOnHold,
  RepairStatusOptions.WaitingOnClaimPart,
  RepairStatusOptions.WaitingOnPartPurchase,
  RepairStatusOptions.PartOrdered,
  RepairStatusOptions.WaitingOnBreakdownPart,
  RepairStatusOptions.PartQueue,
  RepairStatusOptions.SolderQueue,
  RepairStatusOptions.InRepairQueue,
  RepairStatusOptions.RepairInProgress,
  RepairStatusOptions.Repair,
  RepairStatusOptions.QualityAssurance,
  RepairStatusOptions.RepairComplete,
  RepairStatusOptions.ShippingToCustomer,
];

export const myChartLabels = [
  RepairStatusOptions.EstimateApproved,
  RepairStatusOptions.EstimateDenied,
  RepairStatusOptions.InQAQueue,
  RepairStatusOptions.PartOrdered,
];

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const currentYear = new Date().getFullYear();

export const COPYRIGHT_YEAR_TEXT = `Copyright © Vivacity Tech ${currentYear}.`;

export const DRAWER_VARIANT = 'permanent';

export const COPYRIGHT_TEXT = `All Rights Reserved.`;

export const ACCOUNT_NAV_FIELD = {
  DETAILS_TAB: 'acc-1',
  BUILDINGS_TAB: 'acc-2',
};

export const ACCOUNT_NAVIGATION = [
  {
    id: 0,
    text: 'My Account Details',
    type: ACCOUNT_NAV_FIELD.DETAILS_TAB,
    roleName: ACCOUNT_DASHBOARD_SUB_MODULE_MAP.MY_ACCOUNT_DETAILS,
  },
  {
    id: 1,
    text: 'Buildings',
    type: ACCOUNT_NAV_FIELD.BUILDINGS_TAB,
    roleName: ACCOUNT_DASHBOARD_SUB_MODULE_MAP.BUILDINGS,
  },
];

export const REPORT_NAVIGATION = [
  {
    id: 0,
    text: 'Telemetry Data',
    type: 'rep-1',
    name: REPORT_CENTER_SUB_MODULE_MAP.TELEMETRY_DATA,
  },
  {
    id: 1,
    text: 'Vivacity Repairs',
    type: 'rep-2',
    name: REPORT_CENTER_SUB_MODULE_MAP.VIVACITY_REPAIR_REPORT,
  },
  {
    id: 2,
    text: 'In-House Repairs',
    type: 'rep-3',
    name: REPORT_CENTER_SUB_MODULE_MAP.IN_HOUSE_REPAIR_REPORTS,
  },
  {
    id: 3,
    text: 'Device Repair History',
    type: 'rep-4',
    name: REPORT_CENTER_SUB_MODULE_MAP.DEVICE_REPAIR_HISTORY_REPORT,
  },
];

export const INVOICES_ORDERS_NAVIGATION = [
  {
    id: 0,
    text: 'Repair Estimates',
    type: 'tran-1',
    name: INVOICES_ORDERS_SUB_MODULE_MAP.ESTIMATES,
  },
  {
    id: 1,
    text: 'Quotes',
    type: 'tran-2',
    name: INVOICES_ORDERS_SUB_MODULE_MAP.QUOTES,
  },
  {
    id: 2,
    text: 'Orders',
    type: 'tran-3',
    name: INVOICES_ORDERS_SUB_MODULE_MAP.ORDERS,
  },
  {
    id: 3,
    text: 'Invoices',
    type: 'tran-4',
    name: INVOICES_ORDERS_SUB_MODULE_MAP.INVOICES,
  },
];

export const REPAIR_WARRANTY = [
  {
    id: 0,
    text: 'Waiting on Approval',
    type: 'warn-1',
  },
  {
    id: 1,
    text: 'Revision Requested',
    type: 'warn-3',
  },
  {
    id: 2,
    text: 'Denied',
    type: 'warn-4',
  },
  {
    id: 3,
    text: 'Last Week',
    type: 'warn-5',
  },
  {
    id: 4,
    text: 'Last Month',
    type: 'warn-6',
  },
  {
    id: 5,
    text: 'Last Year',
    type: 'warn-7',
  },
];

export const REPAIR_NAVIGATION = [
  {
    id: 0,
    text: 'Vivacity Repair Center',
    type: 'repa-1',
  },
  {
    id: 1,
    text: 'In-House Repair Center',
    type: 'repa-2',
  },
  {
    id: 2,
    text: ' Student Repair Center',
    type: 'repa-3',
  },
  {
    id: 3,
    text: ' Parts Closet',
    type: 'repa-4',
  },
];

export const STUDENT_REPAIR_CENTER_ITEMS = [
  {
    id: 0,
    text: 'SRC Repair Tickets',
    type: 'src-1',
  },
  {
    id: 1,
    text: 'SRC Facilitators',
    type: 'src-2',
  },
  {
    id: 2,
    text: 'SRC Students',
    type: 'src-3',
  },
];

export const IN_HOUSE_REPAIR_CENTER_ITEMS = [
  {
    id: 1,
    text: 'In-House Workflow',
    type: 'ihr-1',
  },
  {
    id: 2,
    text: 'All In-House Tickets',
    type: 'ihr-2',
  },
];

export const VIVACITY_REPAIR_TICKET_DETAILS_ITEMS = [
  {
    id: 0,
    text: 'Step 1: Intake Note',
    type: 'rtd-1',
  },
  {
    id: 1,
    text: 'Step 2 & 3: Add Part(s) + Repair',
    type: 'rtd-2',
  },
  {
    id: 2,
    text: 'Step 4: Close Ticket',
    type: 'rtd-3',
  },
  {
    id: 3,
    text: 'Device Details',
    type: 'rtd-4',
  },
  {
    id: 4,
    text: 'Repair Images',
    type: 'rtd-5',
  },
];
export const VIVACITY_REPAIR_CENTER_ITEMS = [
  {
    id: 0,
    text: 'Repair Tickets',
    type: 'vr-1',
  },
  {
    id: 1,
    text: 'Repair Boxes',
    type: 'vr-2',
  },
];
export const VIVACITY_TICKET_DETAILS = [
  {
    id: 0,
    text: 'Line Items',
    type: 'vr-1',
  },
  {
    id: 1,
    text: 'Repair Images',
    type: 'vr-2',
  },
  {
    id: 1,
    text: 'Notes',
    type: 'vr-3',
  },
];
export const TICKET_ITEMS = [
  {
    id: 0,
    key: 'non_warranty_repairs',
    total: 'Total',
    title: 'Out-of-Warranty Repairs',
    icon: () => <Invoice />,
    items: 10,
    agGridFilterQuery:
      '?warranty_status=Non_Warranty&in_house_status=Intake,Part_Queue,Part_Received,Repair,Quality_Assurance',
    searchBy: [
      {
        field: 'warranty_status',
        descriptor: 'Equals',
        search: 'Non-Warranty',
      },
      {
        field: 'in_house_status',
        descriptor: 'Not In',
        search: `('Complete')`,
      },
    ],
  },
  {
    id: 1,
    key: 'tickets_in_qa',
    total: 'Total',
    title: 'Tickets in QA',
    icon: () => <Ticket />,
    items: 8,
    agGridFilterQuery: '?in_house_status=Quality_Assurance',
    searchBy: [
      {
        field: 'in_house_status',
        descriptor: 'In',
        search: `('Quality Assurance')`,
      },
    ],
  },
  {
    id: 2,
    key: 'waiting_on_parts',
    total: 'Total',
    title: 'Waiting on Parts',
    icon: () => <PendingActions />,
    items: 9,
    agGridFilterQuery: '?in_house_status=Part_Queue',
    searchBy: [
      {
        field: 'in_house_status',
        descriptor: 'In',
        search: `(
                '${RepairStatusOptions.WaitingOnParts}', 
                '${RepairStatusOptions.WaitingOnClaimPart}', 
                '${RepairStatusOptions.WaitingOnPartPurchase}', 
                '${RepairStatusOptions.PartOrdered}', 
                '${RepairStatusOptions.WaitingOnBreakdownPart}', 
                '${RepairStatusOptions.WaitingOnPartApproval}', 
                '${RepairStatusOptions.PartQueue}', 
                '${RepairStatusOptions.WaitingOnClaimPart}', 
                '${RepairStatusOptions.WaitingOnPartPurchase}', 
                '${RepairStatusOptions.WaitingOnBreakdownPart}'
            )`,
      },
    ],
  },
  {
    id: 2,
    key: 'tickets_in_repair',
    total: 'Total',
    title: 'Tickets in Repair',
    icon: () => <Tools />,
    items: 9,
    agGridFilterQuery: '?in_house_status=Repair',
    searchBy: [
      {
        field: 'in_house_status',
        descriptor: 'In',
        search: `('Repair')`,
      },
    ],
  },
  {
    id: 3,
    key: 'open_tickets',
    total: 'Total',
    title: 'Open Tickets',
    icon: () => <Ticket />,
    items: 11,
    agGridFilterQuery: '?in_house_status=Intake,Part_Queue,Part_Received,Repair,Quality_Assurance',
    searchBy: [
      {
        field: 'in_house_status',
        descriptor: 'Not In',
        search: `('Complete')`,
      },
    ],
  },
];

export const VIVACITY_ITEMS = [
  {
    id: 4,
    key: 'pending_repair_box',
    total: 'Repair Tickets',
    title: 'Pending Repair Box',
    icon: () => <Clock />,
    items: 12,
    agGridFilterQuery: '?repair_status=Pending_Repair_Box',
    searchBy: [
      {
        field: 'repair_status',
        descriptor: 'Equals',
        search: 'Pending Repair Box',
      },
    ],
  },
  {
    id: 0,
    key: 'headed_to_vivacity',
    total: 'Repair Tickets',
    title: 'Headed to Vivacity',
    icon: () => <LeftTruck />,
    items: 10,
    agGridFilterQuery: `?repair_status=Shipping_to_Vivacity`,
    searchBy: [
      {
        field: 'repair_status',
        descriptor: 'Equals',
        search: 'Shipping to Vivacity',
      },
    ],
  },
  {
    id: 2,
    key: 'waiting_on_parts',
    total: 'Repair Tickets',
    title: 'Waiting on Parts',
    icon: () => <Tools />,
    items: 9,
    agGridFilterQuery: `?repair_status=${[
      RepairStatusOptions.WaitingOnPartApproval,
      RepairStatusOptions.WaitingOnClaimPart,
      RepairStatusOptions.WaitingOnPartPurchase,
      RepairStatusOptions.PartOrdered,
      RepairStatusOptions.WaitingOnBreakdownPart,
      RepairStatusOptions.PartQueue,
    ]
      .map(spaceToUnderscoreFormatter)
      .join(',')}`,
    searchBy: [
      {
        field: 'repair_status',
        descriptor: 'In',
        search: `(
                '${RepairStatusOptions.WaitingOnParts}', 
                '${RepairStatusOptions.WaitingOnClaimPart}', 
                '${RepairStatusOptions.WaitingOnPartPurchase}', 
                '${RepairStatusOptions.PartOrdered}', 
                '${RepairStatusOptions.WaitingOnBreakdownPart}', 
                '${RepairStatusOptions.WaitingOnPartApproval}', 
                '${RepairStatusOptions.PartQueue}', 
                '${RepairStatusOptions.WaitingOnClaimPart}', 
                '${RepairStatusOptions.WaitingOnPartPurchase}', 
                '${RepairStatusOptions.WaitingOnBreakdownPart}'
            )`,
      },
    ],
  },
  {
    id: 3,
    key: 'in_repair',
    total: 'Repair Tickets',
    title: 'In Repair',
    icon: () => <Clock />,
    items: 11,
    agGridFilterQuery: `?repair_status=${[
      RepairStatusOptions.RepairInProgress,
      RepairStatusOptions.Repair,
      RepairStatusOptions.InRepairQueue,
      RepairStatusOptions.InQAQueue,
      RepairStatusOptions.QAInProgress,
      RepairStatusOptions.RepairComplete,
      RepairStatusOptions.IntakeQueue,
      RepairStatusOptions.Intake,
    ]
      .map(spaceToUnderscoreFormatter)
      .join(',')}`,
    searchBy: [
      {
        field: 'repair_status',
        descriptor: 'In',
        search: `(
                '${RepairStatusOptions.Repair}', 
                '${RepairStatusOptions.InRepairQueue}', 
                '${RepairStatusOptions.InQAQueue}', 
				        '${RepairStatusOptions.QAInProgress}', 
                '${RepairStatusOptions.RepairComplete}', 
                '${RepairStatusOptions.IntakeQueue}', 
                '${RepairStatusOptions.Intake}'
              )`,
      },
    ],
  },
  {
    id: 1,
    key: 'in_transit_to_me',
    total: 'Repair Tickets',
    title: 'In Transit to Me',
    icon: () => <RightTruck />,
    items: 8,
    agGridFilterQuery: '?repair_status=Shipping_to_Customer',
    searchBy: [
      {
        field: 'repair_status',
        descriptor: 'Equals',
        search: 'Shipping to Customer',
      },
    ],
  },
];

export const MY_ACCOUNT_HEADER = [
  {
    id: 0,
    text: 'Account Contacts',
    type: 'ma-1',
  },
  {
    id: 1,
    text: 'Addresses',
    type: 'ma-2',
  },
];

export const LOANER_MANAGEMENT_ITEMS = [
  {
    id: 0,
    text: 'Available',
    type: 'checkIn',
  },
  {
    id: 1,
    text: 'Checked Out',
    type: 'checkOut',
  },
];

export const SIDEBAR_ITEMS = [
  {
    id: 1,
    route: '/report-center',
    name: 'Report Center',
    icon: <BarChartOutlinedIcon />,
    rolePage: PAGE_NAMES.REPORT_CENTER,
  },
  {
    id: 2,
    route: MANAGE_DEVICES,
    name: 'Manage Devices',
    icon: <DevicesIcon />,
    rolePage: PAGE_NAMES.MANAGE_DEVICES,
  },
  {
    id: 3,
    route: REPAIR_CENTER_ROUTE,
    name: 'Repair 360',
    icon: <HardwareIcon />,
    rolePage: PAGE_NAMES.REPAIR_360,
  },
  {
    id: 4,
    route: '/users',
    name: 'Users',
    icon: <PeopleAltOutlinedIcon />,
    rolePage: PAGE_NAMES.USERS,
  },
  {
    id: 5,
    route: 'https://vivacitytech.com/parts/',
    name: 'Shop Parts',
    icon: <ShoppingCartCheckoutOutlinedIcon />,
    newTab: true,
  },
  {
    id: 6,
    route: '/resources',
    name: 'Resources',
    icon: <LayersOutlinedIcon />,
    rolePage: PAGE_NAMES.RESOURCES,
  },
  {
    id: 7,
    route: '/invoices-orders',
    name: 'Invoices & Orders',
    icon: <AttachMoneyOutlinedIcon />,
    rolePage: PAGE_NAMES.INVOICES_ORDERS,
  },
  {
    id: 8,
    route: '/admin',
    name: 'Vivacity Admin',
    icon: <ManageAccountsOutlinedIcon />,
    rolePage: PAGE_NAMES.VIVACITY_ADMIN_PAGE,
  },
  {
    id: 9,
    route: '/my-account',
    name: 'Account Credit',
    subText: 'Credit: ',
    icon: <LocalAtmIcon />,
    rolePage: PAGE_NAMES.ACCOUNT_CREDITS,
  },
];

export const BOTTOM_BAR_ITEMS = [
  {
    id: 1,
    route: '/my-account',
    name: 'My Account',
    icon: <DashboardOutlinedIcon />,
    rolePage: PAGE_NAMES.ACCOUNT_DASHBOARD,
  },
  {
    id: 2,
    route: '/my-buildings',
    name: 'My Buildings',
    icon: <LocationCityOutlinedIcon />,
    rolePage: PAGE_NAMES.MY_BUILDINGS,
  },
  {
    id: 3,
    route: '/my-profile',
    name: 'My Profile',
    icon: <PermIdentityOutlinedIcon />,
    rolePage: PAGE_NAMES.MY_PROFILE,
  },
  {
    id: 4,
    route: '/support',
    name: 'Dream Support',
    icon: <SettingsOutlinedIcon />,
    rolePage: PAGE_NAMES.SETTINGS,
  },
  {
    id: 5,
    route: '/features',
    name: 'Features',
    icon: <BallotOutlinedIcon />,
    rolePage: PAGE_NAMES.FEATURES,
  },
];
