export const UNDER_CONSIDERATION = 'under_consideration';
export const SCHEDULED = 'scheduled';
export const UNDER_CONSTRUCTION = 'under_construction';
export const LAUNCHED = 'launched';
export const HIDDEN = 'hidden';

export const PER_PAGE_RECORD = 12;
export const PER_PAGE_RECORD_10 = 10;

export const PER_PAGE_RECORD_100 = 100;
export const ROADMAP_REQUESTS_COLUMNS = [
  {
    id: 0,
    text: 'Created At',
  },
  {
    id: 1,
    text: 'Roadmap Title',
  },
  {
    id: 2,
    text: 'Roadmap Description',
  },
  {
    id: 3,
    text: 'Total Votes',
  },
  {
    id: 4,
    text: 'Approve or Deny',
  },
  {
    id: 5,
    text: 'Details',
  },
];

export const ROADMAP_NAVIGATION = [
  {
    id: 0,
    text: 'Under Consideration',
    type: UNDER_CONSIDERATION,
  },
  {
    id: 1,
    text: 'Scheduled',
    type: SCHEDULED,
  },
  {
    id: 2,
    text: 'Under Construction',
    type: UNDER_CONSTRUCTION,
  },
  {
    id: 3,
    text: 'Launched',
    type: LAUNCHED,
  },
];

export const FILTER_FIELDS = [
  {
    id: 1,
    label: 'Title',
    value: 'title',
  },
  {
    id: 1,
    label: 'Description',
    value: 'description',
  },
  {
    id: 2,
    label: 'Creator',
    value: 'created_by',
  },
];

export const STATE = ['under_consideration', 'Under Construction', 'Launched', 'hidden'];
export const DUMMY = {};
