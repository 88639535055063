const TOPBAR = {
  cookieName: 'snackbarBetaMessage',
  expirationTime: 0.5, // hours
};
export default TOPBAR;

export const SPOOFING_ACTION = {
  login: 'login',
  logout: 'logout',
};
