import { createContext, useContext } from 'react';

export type OrgUnitContextType = {
  data: any[];
  isLoading: boolean;
};
const OrgUnitsContext = createContext<OrgUnitContextType | null>(null);

export const useOrgUnits = () => useContext(OrgUnitsContext);

export default OrgUnitsContext;
