const styles = {
  mainLayout: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  sidebarLayout: {
    display: 'flex',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
};

export default styles;
