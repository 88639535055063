import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { buildingStateItem } from '../slices/buildingSlice';
import { useAppSelector } from '../store/hooks';
import COLORS from '../constants/colors/colors.constant';

type Props = {
  children: React.ReactNode;
};

const ThemeWrapper = ({ children }: Props) => {
  const { buildingColor } = useAppSelector(buildingStateItem);
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          secondary: {
            main: buildingColor[1] || COLORS.secondary.main,
          },
          primary: {
            main: buildingColor[0] || COLORS.primary.main,
          },
          contrastThreshold: 4.5,
          tonalOffset: 0.4,
        },
        typography: {
          fontFamily: "'Noto Sans', 'sans-serif'",
          fontWeightLight: 400,
        },
      }),
    [buildingColor]
  );

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>{children}</BrowserRouter>
    </ThemeProvider>
  );
};

export default ThemeWrapper;
