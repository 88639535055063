import baseAPIFactory, { handleTokenHeaders } from './base';

const END_POINTS = {
  GLOBAL_SEARCH: (accountId: string | number) => `/customer/${accountId}/global-search`,
};

export type GlobalSearchResult = {
  record_id: number;
  record_type: 'device' | 'ticket' | 'user';
  account_number: string | null;
  serial_number: string | null;
  asset_tag: string | null;
  ticket_number: string | null;
  full_name: string | null;
  email: string | null;
  repair_type: string | null;
};

export const globalSearch = async ({
  accountId,
  token,
  searchTerm,
}: {
  accountId: string | number;
  token: string;
  searchTerm: string;
}) => {
  const headers = handleTokenHeaders({ token });
  const params = { q: searchTerm };
  return baseAPIFactory.get<GlobalSearchResult[]>(END_POINTS.GLOBAL_SEARCH(accountId), {
    params,
    ...headers,
  });
};
