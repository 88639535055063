import baseAPIFactory, { handleTokenHeaders } from './base';

export type FetchParamTypes = {
  token?: string;
};

export type fleetImport = {
  recordId: string | number;
  dateImported: string;
  importer: string;
  quantityDevices: string | number;
  import: any;
  account: string | number;
};

export interface FetchAccountDashboardParamTypes {
  accountId: number;
  token?: string;
}

const END_POINTS = {
  FETCH_ALL_BY_EMAIL: (userEmail?: string) => `/fleet_import/email/${userEmail}`,
  CREATE_SERIAL_DEVICE: () => `/fleet_import/`,
  FETCH_ALL_BY_ACCOUNT: (accountId: number | undefined) => `/fleet_import/${accountId}/all`,
};

export const fetchImportsByEmail = async ({
  token,
  page,
  searchField,
  searchValue,
  contains,
  userEmail,
}: {
  token: string;
  page?: number;
  searchField?: string;
  searchValue?: string;
  contains?: string;
  userEmail?: string;
}) => {
  const params: { [key: string]: any } = {};
  if (page) {
    params.page_no = page;
  }
  if (searchValue) {
    params.search_value = searchValue;
  }
  if (searchField) {
    params.search_field = searchField;
  }
  if (contains) {
    params.contains = contains;
  }
  return baseAPIFactory.get<fleetImport[]>(END_POINTS.FETCH_ALL_BY_EMAIL(userEmail), {
    params,
    ...handleTokenHeaders({ token }),
  });
};

export const createImportSerialDevice = async (data: any) =>
  baseAPIFactory.post(END_POINTS.CREATE_SERIAL_DEVICE(), data.formData, handleTokenHeaders(data));

export const fetchImportsByAccount = async ({ accountId, token }: { accountId: number | undefined; token?: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_BY_ACCOUNT(accountId), handleTokenHeaders({ token }));
