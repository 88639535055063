/* eslint-disable */
const LandingDisclaimer = () => (
  <>
    <h1 style={{ textAlign: 'center' }}>
      <span style={{ fontSize: '22.5pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>Our Terms Of Service</span>
        </span>
      </span>
    </h1>
    <h2>
      <span style={{ fontSize: '18.5pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <strong>Vivacity Tech PBC Terms of Service</strong>
          </span>
        </span>
      </span>
    </h2>
    <h3>
      <span style={{ fontSize: '18pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <strong>1. Terms</strong>
          </span>
        </span>
      </span>
    </h3>
    <p>
      <span style={{ fontSize: '12.499999999999998pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            By accessing this website, you are agreeing to be bound by these terms of service, all applicable laws and
            regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not
            agree with any of these terms, you are prohibited from using or accessing this site. The materials contained
            in this website are protected by applicable copyright and trademark{' '}
          </span>
        </span>
      </span>
      <span style={{ fontSize: '12pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>law.</span>
        </span>
      </span>
      <span style={{ fontSize: '12pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            Vivacity Tech PBC owns all Vivacity Tech PBC trademarks, service marks, branding, logos and other similar
            assets (the "Trademarks"). You are not authorized to copy, imitate, modify, display or otherwise use the
            Trademarks (in whole or in part) for purposes other than in connection with the use of the tools, without
            our prior written approval.&nbsp;
          </span>
        </span>
      </span>
    </p>
    <h3>
      <span style={{ fontSize: '18pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <strong>2. Use License</strong>
          </span>
        </span>
      </span>
    </h3>
    <ol>
      <li style={{ listStyleType: 'decimal' }}>
        <span style={{ fontSize: '12pt' }}>
          <span style={{ fontFamily: 'Roboto,sans-serif' }}>
            <span style={{ color: '#292421' }}>
              <span style={{ backgroundColor: '#ffffff' }}>
                Vivacity Tech PBC grants you a non-exclusive, non-transferable, non-sublicensable, revocable limited
                license to use our tools, in object code form only, solely (i) on a device that you own or control, and
                (ii) for your personal, non-commercial, or educational purposes. The tools are licensed to you, not
                sold, under this Agreement. We reserve all rights not expressly granted to you in the tools.{' '}
              </span>
            </span>
          </span>
        </span>
        <span style={{ fontSize: '12pt' }}>
          <span style={{ fontFamily: 'Roboto,sans-serif' }}>
            <span style={{ color: '#292421' }}>This is the grant of a lice</span>
          </span>
        </span>
        <span style={{ fontSize: '12.499999999999998pt' }}>
          <span style={{ fontFamily: 'Roboto,sans-serif' }}>
            <span style={{ color: '#292421' }}>nse, not a transfer of title, and under this license you may not:</span>
          </span>
        </span>
        <ol>
          <li style={{ listStyleType: 'lower-roman' }}>
            <span style={{ fontSize: '12.499999999999998pt' }}>
              <span style={{ fontFamily: 'Roboto,sans-serif' }}>
                <span style={{ color: '#292421' }}>modify or copy the materials;</span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: 'lower-roman' }}>
            <span style={{ fontSize: '12.499999999999998pt' }}>
              <span style={{ fontFamily: 'Roboto,sans-serif' }}>
                <span style={{ color: '#292421' }}>
                  use the materials for any commercial purpose, or for any public display (commercial or
                  non-commercial);
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: 'lower-roman' }}>
            <span style={{ fontSize: '12.499999999999998pt' }}>
              <span style={{ fontFamily: 'Roboto,sans-serif' }}>
                <span style={{ color: '#292421' }}>
                  attempt to decompile or reverse engineer any software contained on Vivacity Tech PBC's website;
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: 'lower-roman' }}>
            <span style={{ fontSize: '12.499999999999998pt' }}>
              <span style={{ fontFamily: 'Roboto,sans-serif' }}>
                <span style={{ color: '#292421' }}>
                  remove any copyright or other proprietary notations from the materials;
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: 'lower-roman' }}>
            <span style={{ fontSize: '12.499999999999998pt' }}>
              <span style={{ fontFamily: 'Roboto,sans-serif' }}>
                <span style={{ color: '#292421' }}>
                  transfer the materials to another person or "mirror" the materials on any other server; or
                </span>
              </span>
            </span>
          </li>
          <li style={{ listStyleType: 'lower-roman' }}>
            <span style={{ fontSize: '12.499999999999998pt' }}>
              <span style={{ fontFamily: 'Roboto,sans-serif' }}>
                <span style={{ color: '#292421' }}>act in bad faith to circumvent restrictions.</span>
              </span>
            </span>
          </li>
        </ol>
      </li>
      <li style={{ listStyleType: 'decimal' }}>
        <span style={{ fontSize: '12.499999999999998pt' }}>
          <span style={{ fontFamily: 'Roboto,sans-serif' }}>
            <span style={{ color: '#292421' }}>
              This license shall automatically terminate if you violate any of these restrictions and may be terminated
              by Vivacity Tech PBC at any time. Upon terminating your viewing of these materials or upon the termination
              of this license, you must destroy any downloaded materials in your possession whether in electronic or
              printed format.
            </span>
          </span>
        </span>
      </li>
    </ol>
    <h3>
      <span style={{ fontSize: '18pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <strong>3. Disclaimer</strong>
          </span>
        </span>
      </span>
    </h3>
    <ol>
      <li style={{ listStyleType: 'decimal' }}>
        <span style={{ fontSize: '12.499999999999998pt' }}>
          <span style={{ fontFamily: 'Roboto,sans-serif' }}>
            <span style={{ color: '#292421' }}>
              The materials on Vivacity Tech PBC's website are provided on an 'as is' basis. Vivacity Tech PBC makes no
              warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without
              limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of rights.
            </span>
          </span>
        </span>
      </li>
      <li style={{ listStyleType: 'decimal' }}>
        <span style={{ fontSize: '12.499999999999998pt' }}>
          <span style={{ fontFamily: 'Roboto,sans-serif' }}>
            <span style={{ color: '#292421' }}>
              Further, Vivacity Tech PBC does not warrant or make any representations concerning the accuracy, likely
              results, or reliability of the use of the materials on its website or otherwise relating to such materials
              or on any sites linked to this site.
            </span>
          </span>
        </span>
      </li>
      <li style={{ listStyleType: 'decimal' }}>
        <span style={{ fontSize: '12pt' }}>
          <span style={{ fontFamily: 'Roboto,sans-serif' }}>
            <span style={{ color: '#292421' }}>
              We continually update our tools to offer the best possible suite of products. We may make changes to the
              features or functions of tools at any time, and we cannot guarantee that any specific feature or function
              will be available for the entire subscription period. If you are not satisfied with any changes you are
              encouraged to contact our support team.
            </span>
          </span>
        </span>
      </li>
      <li style={{ listStyleType: 'decimal' }}>
        <span style={{ fontSize: '12pt' }}>
          <span style={{ fontFamily: 'Roboto,sans-serif' }}>
            <span style={{ color: '#292421' }}>
              Schools acknowledge and agree that they are solely responsible for compliance with the Children’s Online
              Privacy Protection Act of 1998 ("COPPA") and Family Educational Rights and Privacy Act ("FERPA"),
              including, but not limited to, obtaining parental consent concerning the collection of students’ personal
              information used in connection with the provisioning and use of the tools or access to such information
              through the use of Third-Party Products (as defined herein). Schools are solely responsible for obtaining
              parental consent before allowing any User under the age of 13 to use any of the tools.
            </span>
          </span>
        </span>
      </li>
    </ol>
    <h3>
      <span style={{ fontSize: '18pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <strong>4. Indemnification</strong>
          </span>
        </span>
      </span>
    </h3>
    <h3>
      <span style={{ fontSize: '12pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <span style={{ backgroundColor: '#ffffff' }}>
              To the maximum extent permitted by applicable law, you agree to defend, indemnify and hold harmless
              Vivacity Tech PBC, its subsidiaries and affiliates, and their respective agents, licensors, managers,
              employees, contractors, officers and directors, from and against any and all claims, damages, obligations,
              losses, liabilities, costs or debt, and expenses (including but not limited to reasonable attorney’s fees
              and court costs) arising from or relating in any way to: (i) your use of and access to the Tools,
              including any data or Content transmitted or received by you; (ii) your violation of any term of this
              Agreement; (iii) your violation of any third-party rights, including but not limited to any right of
              privacy or Intellectual Property Rights; (iv) your violation of any applicable law, rule or regulation,
              including but not limited to COPPA and FERPA; (v) any content or information that is submitted via your
              account; or (vi) any other party’s access and use of the Tools with your unique username, password or
              other appropriate security code. We reserve the right, at your expense, to assume the exclusive defense
              and control of any matter for which you are required to indemnify us under this Agreement, and you agree
              to cooperate with our defense of these claims.
            </span>
          </span>
        </span>
      </span>
    </h3>
    <h3>
      <span style={{ fontSize: '18pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <strong>5. Limitations</strong>
          </span>
        </span>
      </span>
    </h3>
    <p>
      <span style={{ fontSize: '12.499999999999998pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            In no event shall Vivacity Tech PBC be liable for any damages (including, without limitation, damages for
            loss of data or profit, or due to business interruption) arising out of the use or inability to use the
            materials on Vivacity Tech PBC's website, even if Vivacity Tech PBC or a Vivacity Tech PBC authorized
            representative has been notified orally or in writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential
            or incidental damages, these limitations may not apply to you.
          </span>
        </span>
      </span>
    </p>
    <h3>
      <span style={{ fontSize: '18pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <strong>6. Accuracy of materials</strong>
          </span>
        </span>
      </span>
    </h3>
    <p>
      <span style={{ fontSize: '12.499999999999998pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            The materials appearing on Vivacity Tech PBC's website could include technical, typographical, or
            photographic errors. Vivacity Tech PBC does not warrant that any of the materials on its website are
            accurate, complete or current. Vivacity Tech PBC may make changes to the materials contained on its website
            at any time without notice. However Vivacity Tech PBC does not make any commitment to update the materials.
          </span>
        </span>
      </span>
    </p>
    <h3>
      <span style={{ fontSize: '18pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <strong>7. Links</strong>
          </span>
        </span>
      </span>
    </h3>
    <p>
      <span style={{ fontSize: '12.499999999999998pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            Vivacity Tech PBC has not reviewed all of the sites linked to its website and is not responsible for the
            contents of any such linked site. The inclusion of any link does not imply endorsement by Vivacity Tech PBC
            of the site. Use of any such linked website is at the user's own risk.
          </span>
        </span>
      </span>
    </p>
    <h3>
      <span style={{ fontSize: '18pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <strong>8. Modifications</strong>
          </span>
        </span>
      </span>
    </h3>
    <p>
      <span style={{ fontSize: '12.499999999999998pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            Vivacity Tech PBC may revise these terms of service for its website at any time without notice. By using
            this website you are agreeing to be bound by the then current version of these terms of service.
          </span>
        </span>
      </span>
    </p>
    <h3>
      <span style={{ fontSize: '18pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            <strong>9. Governing Law</strong>
          </span>
        </span>
      </span>
    </h3>
    <p>
      <span style={{ fontSize: '12.499999999999998pt' }}>
        <span style={{ fontFamily: 'Roboto,sans-serif' }}>
          <span style={{ color: '#292421' }}>
            These terms and conditions are governed by and construed in accordance with the laws of Minnesota and you
            irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
          </span>
        </span>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </>
);

export default LandingDisclaimer;
