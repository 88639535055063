import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';
import type { RootState } from '../store';
import { isAPIResponseFailure } from '../api/base';
import appLogger from '../infrastructure/config/appLogger';
import { setLoading } from './commonSlice';
import {
  Device,
  getAllCompatible,
  getAllCompatibleByDevice,
  getAllCustomParts,
  getAllDevices,
  getAllPartCloset,
  getAllParts,
  getAllPartsByAccount,
} from '../api/partCloset';
import { getTestPartCompatabilityAPI } from '../api/testRepair';

const sliceName = 'repair';

export interface PartCloset {
  record_id?: number;
  account: number;
  part_name: string;
  sku: string;
  partid: number;
  manufacturer: string;
  parent_device: string;
  part_category: string;
  building: string;
  quantity: number;
}

export interface Part {
  record_id: number;
  part_name: string;
}

type fetchPartClosetWithToken = {
  accountID?: number;
  token?: string;
  extraParams?: any | null;
};

type fetchWithToken = {
  token?: string;
};

type fetchCustomPartWithToken = {
  accountNum: string | number;
  token?: string;
  extraParams?: any | null;
};

type fetchCompatibleWithToken = {
  serialId: string | number;
  token?: string;
  isPracticeStudent?: boolean;
};
type fetchCompatibleDeviceWithToken = {
  deviceId: string | number;
  accountId: string | number;
  buildingId: string | number;
  token?: string;
};

type fetchAllPartsWithToken = {
  accountId: string | number;
  token?: string;
};
export interface InitialState {
  partCloset: PartCloset[];
  parts: any;
  customPart: PartCloset[];
  devices: Device[];
  totalCount: number;
  partsDataLoading: boolean;
  partsData: PartCloset[];
}

const initialState: InitialState = {
  customPart: [],
  partCloset: [],
  parts: [],
  devices: [],
  totalCount: 0,
  partsDataLoading: false,
  partsData: [],
};

export const fetchAllDevices = createAsyncThunk<Device[], fetchWithToken>(
  `${sliceName}/fetchDeviceCloset`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const loggedInUser: any = await getAllDevices(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllCustomParts = createAsyncThunk<any, fetchCustomPartWithToken>(
  `${sliceName}/fetchCustomParts`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const loggedInUser: any = await getAllCustomParts(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllPartCloset = createAsyncThunk<PartCloset[], fetchPartClosetWithToken>(
  `${sliceName}/fetchPartCloset`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const loggedInUser: any = await getAllPartCloset(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllParts = createAsyncThunk<any[], fetchWithToken>(
  `${sliceName}/fetchPart`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const loggedInUser: any = await getAllParts(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllPartsByFleetDevice = createAsyncThunk<any[], fetchCompatibleWithToken>(
  `${sliceName}/fetchAllPartsByFleetDevice`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchAllPartsByFleetDevice`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const isPracticeStudent = !!get(params, 'isPracticeStudent', false);
      const targetFunction = isPracticeStudent ? getTestPartCompatabilityAPI : getAllCompatible;
      const loggedInUser: any = await targetFunction(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllPartsByParentDevice = createAsyncThunk<any[], fetchCompatibleDeviceWithToken>(
  `${sliceName}/fetchAllPartsByParentDevice`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchAllPartsByParentDevice`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const loggedInUser: any = await getAllCompatibleByDevice(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllPartsByAccount = createAsyncThunk<any[], fetchAllPartsWithToken>(
  `${sliceName}/fetchAllPartsByAccount`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchAllPartsByAccount`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const loggedInUser: any = await getAllPartsByAccount(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(loggedInUser)) {
        return ThunkAPI.rejectWithValue(loggedInUser);
      }
      return loggedInUser.data;
    } catch (e) {
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

const partClosetSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllPartCloset.fulfilled, (state, action) => {
      const { payload } = action;
      const totalCount = get(payload, 'total', 0);
      state.partCloset = get(payload, 'rows', []);
      state.totalCount = totalCount;
    });
    builder.addCase(fetchAllParts.fulfilled, (state, action) => {
      const { payload } = action;
      state.parts = get(payload, 'rows', []);
    });
    builder.addCase(fetchAllPartsByFleetDevice.fulfilled, (state, action) => {
      const { payload } = action;
      state.parts = payload;
    });
    builder.addCase(fetchAllPartsByParentDevice.fulfilled, (state, action) => {
      const { payload } = action;
      state.parts = payload;
    });
    builder.addCase(fetchAllCustomParts.fulfilled, (state, action) => {
      const { payload } = action;
      const customObject: any[] = [];
      payload.forEach((element) => {
        customObject.push({
          record_id: element.record_id,
          account: element.account,
          manufacturer: element.manufacturer,
          parent_device: element.parent_device,
          part_category: 'Custom',
          part_name: element.part_name,
          partid: element.record_id,
          quantity: element.quantity,
          sku: element.part_sku,
          building: element.building,
        });
      });
      state.customPart = customObject;
    });
    builder.addCase(fetchAllDevices.fulfilled, (state, action) => {
      const { payload } = action;
      state.devices = payload;
    });
    builder.addCase(fetchAllPartsByAccount.pending, (state) => {
      state.partsDataLoading = true;
    });
    builder.addCase(fetchAllPartsByAccount.fulfilled, (state, action) => {
      state.partsData = action.payload;
      state.partsDataLoading = false;
    });
    builder.addCase(fetchAllPartsByAccount.rejected, (state) => {
      state.partsDataLoading = false;
    });
  },
});

export default partClosetSlice.reducer;

export const partClosetStateItem = createSelector(
  (state: RootState) => state.partCloset,
  (items: InitialState) => items
);
