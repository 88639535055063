import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogActions } from '@mui/material';
import { DefaultButton } from '../StyledComponent';
import { DialogModalActions, DialogModalTitle } from './styles';

interface Props extends DialogProps {
  open: boolean;
  title?: string;
  isFullScreen?: boolean;
  handleClose: any;
  children: JSX.Element;
  formik?: any;
  customButton?: JSX.Element | null;
  modalActionPostition?: React.CSSProperties['justifyContent'];
  showActions?: boolean;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const DialogWrapper = ({
  open,
  title = undefined,
  isFullScreen = false,
  handleClose,
  children,
  formik = null,
  customButton = null,
  modalActionPostition = 'flex-end',
  showActions = true,
  ...props
}: Props) => {
  const handleDialogClose = (event: any) => {
    event.preventDefault();
    handleClose(false);
  };

  return (
    <Dialog
      fullScreen={isFullScreen}
      open={open}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
      scroll="paper"
      {...props}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          {title !== null && (
            <Typography sx={DialogModalTitle} variant="h6" component="div">
              {title}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <DialogContent dividers>{children}</DialogContent>
      {showActions && (
        <DialogActions
          sx={[
            DialogModalActions,
            {
              justifyContent: modalActionPostition,
            },
          ]}
        >
          {customButton}
          <DefaultButton onClick={handleDialogClose}>Dismiss</DefaultButton>
          {formik && <DefaultButton onClick={() => formik.handleSubmit()}>Save</DefaultButton>}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogWrapper;
