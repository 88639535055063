import { useEffect, useState } from 'react';
import { getBooleanFeatureFlagValue } from '../../utils/LaunchDarklyQuery';
import appLogger from '../../infrastructure/config/appLogger';

const Chat = () => {
  const [ldDriftChat, setLdDriftChat] = useState<boolean | null>(null);
  const [ldHubSpotChat, setLdHubSpotChat] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const chatDriftEnable = await getBooleanFeatureFlagValue('dream-chat-drift-enable');
        const chatHubSpotEnable = await getBooleanFeatureFlagValue('dream-chat-hubspot-enable');
        setLdDriftChat(chatDriftEnable);
        setLdHubSpotChat(chatHubSpotEnable);
      } catch (error) {
        setLdHubSpotChat(false);
        setLdDriftChat(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (ldDriftChat) {
      const initDrift = () => {
        const driftt = window.driftt || [];
        const drift = window.drift || driftt;
        window.driftt = driftt;
        window.drift = drift;

        if (!drift.init) {
          if (drift.invoked) {
            appLogger.log('Drift snippet included twice.');
            return;
          }

          drift.invoked = true;
          drift.methods = [
            'identify',
            'config',
            'track',
            'reset',
            'debug',
            'show',
            'ping',
            'page',
            'hide',
            'off',
            'on',
          ];
          drift.factory =
            (method: string) =>
            (...args: any[]) => {
              args.unshift(method);
              drift.push(args);
              return drift;
            };

          drift.methods.forEach((method: string) => {
            drift[method] = drift.factory(method);
          });

          drift.load = (id: string) => {
            const interval = 300000;
            const time = Math.ceil(new Date().getTime() / interval) * interval;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.crossOrigin = 'anonymous';
            script.src = `https://js.driftt.com/include/${time}/${id}.js`;

            const firstScript = document.getElementsByTagName('script')[0];
            if (firstScript?.parentNode) {
              firstScript.parentNode.insertBefore(script, firstScript);
            }
          };
        }
      };

      initDrift();
      window.drift.SNIPPET_VERSION = '0.3.1';
      window.drift.load('r88z43km94hs');

      // Check if Drift API is ready
      window.drift.on('ready', () => {
        if (window.drift.api && window.drift.api.widget) {
          window.drift.api.widget.show();
        } else {
          appLogger.log('Drift API is not available.');
        }
      });
    }
  }, [ldDriftChat]);

  useEffect(() => {
    if (ldHubSpotChat) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = '//js.hs-scripts.com/7052047.js';
      document.body.appendChild(script);

      return () => {
        // Clean up HubSpot script if necessary
        const existingScript = document.getElementById('hs-script-loader');
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
      };
    }

    // Ensure the effect always returns either a cleanup function or undefined
    return undefined;
  }, [ldHubSpotChat]);

  return null;
};

export default Chat;
