import { useState } from 'react';
import SidebarDrawer from '../../creative_commons/sidebar/sidebarDrawer.component';
import styles from '../../components/LayoutStyles';
import { getContentCSS } from '../../slices/commonSlice';
import { useAppSelector } from '../../store/hooks';

const SidebarLayout = ({ children }: any) => {
  const [bottomCollapse, setBottomCollapse] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const addCustomCSS: any = useAppSelector(getContentCSS);

  return (
    <div style={styles.sidebarLayout}>
      <div>
        <SidebarDrawer
          setCollapsed={setCollapsed}
          collapsed={collapsed}
          bottomCollapse={bottomCollapse}
          setBottomCollapse={setBottomCollapse}
        />
      </div>
      <div style={{ ...styles.content, ...addCustomCSS }}>{children}</div>
    </div>
  );
};

export default SidebarLayout;
