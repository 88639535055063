import { get, has, isBoolean, isEmpty, isString } from 'lodash';
import { commonThenResult, gacBaseAPIFactory, handleTokenHeaders } from '../base';

const END_POINTS = {
  GENERATE_GAC_PARAMS: () => '/api/auth/login',
  REFRESH_GAC_PARAMS: () => '/api/auth/refresh/',
};

export const generateGACParams = async (redirectURL: string, token: string) => {
  const response = await gacBaseAPIFactory.post(
    END_POINTS.GENERATE_GAC_PARAMS(),
    {
      redirect_url: redirectURL,
    },
    handleTokenHeaders({ token })
  );
  return commonThenResult(response);
};

export const refreshGACToken = async (token: string) => {
  const response = await gacBaseAPIFactory.post(END_POINTS.REFRESH_GAC_PARAMS(), {}, handleTokenHeaders({ token }));
  return commonThenResult(response);
};

export const processEmptyOptions = (options: any) => {
  const objectList: any = Object.keys(options)
    .map((key: any) => {
      const value: any = get(options, key);
      if (isString(value) && value.length > 0) {
        return {
          key,
          value,
        };
      }
      if (isBoolean(value)) {
        return {
          key,
          value,
        };
      }
      return {};
    })
    .reduce((agg, item) => {
      if (!isEmpty(item)) {
        return {
          ...agg,
          [item.key]: item.value,
        };
      }
      return agg;
    }, {});
  if (has(objectList, 'token')) {
    delete objectList.token;
  }
  return objectList;
};
