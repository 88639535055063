export const underscoreToSpaceFormatter = (text: string, titleCase = false): string => {
  if (!text) return '';
  const formattedText = text.replace(/_/g, ' ');
  if (titleCase) {
    return formattedText.replace(/\b\w/g, (char) => char.toUpperCase());
  }
  return formattedText;
};
export const spaceToUnderscoreFormatter = (repairStatus) => repairStatus.replace(/ /g, '_');

export const underscoreToHyphenFormatter = (repairStatus) => repairStatus?.replace(/_/g, '-');
