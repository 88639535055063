import baseAPIFactory, { handleTokenHeaders } from './base';

export interface VideoCategory {
  category_name: string;
  category_description: string;
}

export interface Video {
  video_link: string;
  date_created: string;
  created_by: number;
  product_type: string;
  video_category: string;
  product_record_id: number;
  video_description: string;
  video_title: string;
}

const END_POINTS = {
  LIST_VIDEO_CATEGORIES: () => '/video_category/all',
  LIST_VIDEOS: () => '/video/all',
};

export const listVideoCategories = async ({ token }: { token: string }) =>
  baseAPIFactory.get<VideoCategory[]>(END_POINTS.LIST_VIDEO_CATEGORIES(), handleTokenHeaders({ token }));

export const listVideos = async ({ token }: { token: string }) =>
  baseAPIFactory.get<Video[]>(END_POINTS.LIST_VIDEOS(), handleTokenHeaders({ token }));
