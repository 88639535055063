import React, { useState } from 'react';
import { useTheme, Typography, IconButton, Box, darken } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getLuminance } from 'src/utils/colorConversion';
import COLORS from 'src/constants/colors/colors.constant';
import { setCookie, getCookie } from '../../utils/web.util';
import topAppBarStyles from './topAppBar.styles';
import TOPBAR from '../../constants/data/topAppBar.constants';

interface TopAppBarProps {
  content: string;
}

function TopAppBar({ content }: TopAppBarProps) {
  const cookieName = TOPBAR?.cookieName;
  const expirationTime = TOPBAR?.expirationTime; // hours
  const cookieValue = getCookie(cookieName);
  const cookieStatus = cookieValue === '' ? true : /true/.test(cookieValue);

  const [isAppBarOpen, setIsAppBarOpen] = useState(cookieStatus);
  const theme = useTheme();
  const styles = topAppBarStyles(theme);

  const backgroundColor = theme.palette.primary.main;
  const luminance = getLuminance(backgroundColor);

  const textColor =
    luminance < 0.3 || (luminance >= 0.3 && luminance <= 0.7) ? COLORS.primary.textDark : darken(backgroundColor, 0.6);

  const onClose = () => {
    setIsAppBarOpen(false);
    setCookie(cookieName, false, expirationTime);
  };

  const transformContentToHyperlink = (text: string) => {
    const regex = /(https:\/\/[^\s]+)/g;
    const match = regex.exec(text);
    if (match) {
      const url = match[0];
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fontWeight: 'bold',
            textDecoration: 'underline',
            color: 'inherit',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {text.replace(url, '').trim()}
          <OpenInNewIcon sx={{ ml: 0.5, fontSize: 'inherit' }} />
        </a>
      );
    }
    return text;
  };

  return isAppBarOpen ? (
    <Box
      sx={{
        ...styles.customizedTopBar,
        backgroundColor,
        color: textColor,
      }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={2}
    >
      <Typography component="div" sx={{ ...styles.customizedTopBarTypography, color: textColor }}>
        {transformContentToHyperlink(content)}
      </Typography>
      <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
        <CloseIcon />
      </IconButton>
    </Box>
  ) : null;
}

export default TopAppBar;
