const styles = (theme: any) => ({
  renderContentBox: {
    flexGrow: 1,
  },
  pointerEvents: {
    pointerEvents: 'auto',
  },
  appBar: {
    boxShadow: 'none',
    height: '50px',
    backgroundColor: 'transparent',
    position: 'fixed',
    transition: theme.transitions.create(['height', 'margin-top'], {
      duration: theme.transitions.duration.shorter,
    }),
    pointerEvents: 'none',
  },
  withDivAbove: {
    marginTop: '50px',
  },
  withoutDivAbove: {
    marginTop: '2px',
  },
  toolbar: {
    height: 1,
    px: { xs: 4, lg: 4 },
  },
});

export default styles;
