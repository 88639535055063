const paidDialogStyles = {
  subHeading: {
    marginBottom: '16px',
  },
  textAreaModal: {
    width: '100%',
    padding: '8px',
    margin: '16px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
};

export default paidDialogStyles;
