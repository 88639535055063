import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { datadogRum } from '@datadog/browser-rum';
import { AUTH_TOKEN_KEY, AUTH_RESPONSE_STORE_NAME } from '../../constants/common';
import appLogger from './appLogger';

type ConfigReturn = {
  authStorageKeyName: string;
  authResponseStoreName: string;
  hostName: string;
  BASE_URL?: string;
  GAC_HANDLER_URL?: string;
  CLIENT_KEY?: string;
  CLIENT_SECRET?: string;
  APP_HOST_URL?: string;
  version?: string;
  googleClientId?: string;
};

class Config {
  private readonly config = {};

  private readonly authStoreName = AUTH_TOKEN_KEY;

  private readonly authResponseStoreName = AUTH_RESPONSE_STORE_NAME;

  constructor() {
    this.config = this.initConfig();
  }

  environmentName() {
    return get(process.env, 'REACT_APP_SERVER', 'local');
  }

  initConfig() {
    const environment = this.environmentName();
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
    const envData = require(`./${environment}.json`);
    appLogger.log('config', envData);
    return envData;
  }

  getConfig(): ConfigReturn {
    return {
      ...this.config,
      authStorageKeyName: this.authStoreName,
      authResponseStoreName: this.authResponseStoreName,
      hostName: get(process.env, 'REACT_APP_HOST_URL', ''),
      version: get(process.env, 'REACT_APP_DREAM_VERSION', ''),
      googleClientId: '480272753811-1ue7gg6a0g7r6lvrmel3eotubso9gimr.apps.googleusercontent.com',
    };
  }

  isDev() {
    return !this.isProd();
  }

  isProd() {
    const environment = this.environmentName();
    return ['production'].includes(environment);
  }

  getAuthStoreName() {
    return this.authStoreName;
  }

  initDataDog() {
    const datadogConfig = {
      applicationId: get(process.env, 'REACT_APP_DATADOG_APP_ID1', ''),
      clientToken: get(process.env, 'REACT_APP_DATADOG_CLIENT_TOKEN1', ''),
      service: get(process.env, 'REACT_APP_DATADOG_SERVICE_NAME1', ''),
    };
    const requiredVariables = [datadogConfig.applicationId, datadogConfig.clientToken, datadogConfig.service];
    if (requiredVariables.every((item: any) => !isEmpty(item))) {
      const environment = this.environmentName();
      datadogRum.init({
        applicationId: datadogConfig.applicationId,
        clientToken: datadogConfig.clientToken,
        site: 'us5.datadoghq.com',
        service: datadogConfig.service,
        env: environment,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: '1.0.0',
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.startSessionReplayRecording();
    }
  }
}

export default new Config();
