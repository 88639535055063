export const DEVICE_FLEET_TAB = 'fleet';
export const DEVICE_LOANER_MANAGEMENT_TAB = 'loaner_management';
export const DEVICE_IMPORT_SERIAL_TAB = 'import_serial';
export const DEVICE_ASSETS_TAB = 'uploaded_assests';
export const PER_PAGE_RECORD_IMPORT_SERIAL = 10;
export const PER_PAGE_RECORD = 9;

export const GAC_INITIAL_DATA = {
  orderBy: 'SERIAL_NUMBER',
  projection: 'FULL',
  sortOrder: 'DESCENDING',
};

export const PROVISION_QUERY = {
  ACTIVE: 'status:provisioned',
  RETIRED: 'status:deprovisioned',
};

export const DEVICE_STATUS = ['Active', 'Inactive'];

export const MY_DEVICE_NAVIGATION = [
  {
    id: 0,
    text: 'My Fleet',
    type: DEVICE_FLEET_TAB,
  },
  {
    id: 1,
    text: 'Loaner Management',
    type: DEVICE_LOANER_MANAGEMENT_TAB,
  },
  {
    id: 2,
    text: 'Import Serial Devices',
    type: DEVICE_IMPORT_SERIAL_TAB,
  },
  {
    id: 3,
    text: 'Organization Uploaded Assets',
    type: DEVICE_ASSETS_TAB,
  },
];

const commonSearchQuery = [
  {
    name: 'like',
    label: 'Is Any',
  },
  {
    name: 'equal',
    label: 'Is',
  },
  {
    name: 'contains',
    label: 'Contains',
  },
  {
    name: 'not_contains',
    label: 'Does Not Contain',
  },
  {
    name: 'blank',
    label: 'Is Blank',
  },
];

const skuSearchQuery = [
  {
    name: 'like',
    label: 'Is Any',
  },
  {
    name: 'contains',
    label: 'Contains',
  },
];

export const ADVANCED_SEARCH_ITEMS = [
  {
    name: 'serial_number',
    label: 'Serial Number',
    fields: commonSearchQuery,
  },
  {
    name: 'asset_tag',
    label: 'Asset Tag',
    fields: commonSearchQuery,
  },
  {
    name: 'sku',
    label: 'SKU',
    fields: skuSearchQuery,
  },
  {
    name: 'device_name',
    label: 'Device Name',
    fields: commonSearchQuery,
  },
];

export const INITIAL_ADVANCED_SEARCH_FILTER: Record<string, any> = {
  serial_number: '',
  asset_tag: null,
  sku: null,
  device_name: null,
};

export const MANAGE_DEVICE_TABS = [
  {
    id: 1,
    name: 'Active',
    label: 'Active',
  },
  {
    id: 2,
    name: 'Deactivate',
    label: 'Retired',
  },
];

export const DEVICE_TEMPLATE = {
  serial_number: '',
  building: '',
  device: '',
  base_warranty: '',
  base_warranty_start_date: '',
  base_warranty_end_date: '',
  advanced_warranty: '',
  advanced_warranty_start_date: '',
  advanced_warranty_end_date: '',
  asset_tag: '',
  account: 0,
};

export const DEVICE_REQUIRED_HEADERS = [
  { id: 1, title: 'Date Imported', key: 'date_imported' },
  { id: 2, title: 'Importer', key: 'importer' },
  { id: 3, title: 'Quantity Devices', key: 'quantity_devices' },
  { id: 5, title: 'Import', key: 'import' },
  { id: 4, title: 'Account', key: 'account' },
];

export const DEVICE_UPLOADED_ASSETS = [
  { id: 1, title: 'Image' },
  { id: 2, title: 'Description' },
  { id: 3, title: 'Acquire Date' },
  { id: 4, title: 'Location' },
  { id: 5, title: 'Action' },
];

export const DEVICE_TABLEDATA = [
  {
    id: 1,
    date_imported: '2023-05-05T02:01:12.704Z',
    importer: '1',
    quantity_devices: '84113',
    import: 'test data',
    account: 1,
  },
];

export const ASSETS_FILTER_FIELDS = [
  {
    id: 1,
    label: 'Description',
    value: 'description',
  },
  {
    id: 2,
    label: 'Acquire Location',
    value: 'acquire_location',
  },
];

export const IMPORT_FILTER_FIELDS = [
  {
    id: 1,
    label: 'Importer',
    value: 'importer',
  },
  {
    id: 2,
    label: 'Quantity Devices',
    value: 'quantity_devices',
  },
];

export const NEW_DEVICE_UPLOADED_ASSETS = [
  { id: 1, title: 'Location', key: 'acquire_location' },
  { id: 2, title: 'Tag', key: 'tag' },
  { id: 3, title: 'Notes', key: 'notes' },
  { id: 4, title: 'Category', key: 'type' },
  { id: 5, title: 'Serial Number', key: 'serial_number' },
  { id: 6, title: 'Model', key: 'model' },
  { id: 7, title: 'Assignment', key: 'assignment' },
  { id: 8, title: 'Warranty Info', key: 'warranty_info' },
  { id: 9, title: 'Fund Source', key: 'fund_source' },
  { id: 10, title: 'PO', key: 'po' },
  { id: 11, title: 'Cost', key: 'cost' },
  { id: 12, title: 'Invoice', key: 'invoice' },
  { id: 13, title: 'Vendor', key: 'vendor' },
  { id: 14, title: 'Quantity', key: 'quantity' },
  { id: 15, title: 'Image', key: 'image' },
  { id: 16, title: 'Description', key: 'description' },
  { id: 17, title: 'Custom Data', key: 'custom_data' },
];

export const NEW_UPLOADED_ASSETS_DATA = [
  {
    description: 'test',
    image: '<image_Url>.jpg',
    acquire_location: 'Test Location',
    tag: 0,
    notes: 'Test Notes',
    current_location: 'undefined',
    type: 'Test Category',
    acquire_date: '2024-09-09T07:54:04.000Z',
    created_by: 1137,
    created_date: '2024-09-09T13:24:06.681Z',
    custom_data: {
      Key: 'Value',
    },
    serial_number: 'Test Serial Number',
    model: 'Test Model',
    assignment: 'Test Assignment',
    warranty_info: 'Test Warranty Info',
    fund_source: 'Test Fund',
    po: 'Test PO',
    invoice: 'Test Invoice',
    vendor: 'Test vendor',
    cost: 1000,
    quantity: 15,
  },
];
