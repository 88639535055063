export const DialogModalActions = () => ({
  display: 'flex',
  justifyContent: 'flex-end',
  px: 2,
  py: 2,
});

export const DialogModalTitle = () => ({
  ml: 2,
  flex: 1,
});
