import React from 'react';
import { Tooltip, Box } from '@mui/material';
import { SecondaryButtonCore, YellowDot } from './SecondaryButton.styles';

export interface SecondaryButtonProps {
  disabledStyle?: boolean;
  disabledCompletely?: boolean;
  tooltip?: string;
  showYellowDot?: boolean;
  [key: string]: any;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ tooltip, showYellowDot, ...props }) =>
  tooltip ? (
    <Tooltip title={tooltip}>
      <Box position="relative" display="inline-flex">
        <SecondaryButtonCore {...props} />
        {showYellowDot && <YellowDot />}
      </Box>
    </Tooltip>
  ) : (
    <Box position="relative" display="inline-flex">
      <SecondaryButtonCore {...props} />
      {showYellowDot && <YellowDot />}
    </Box>
  );

export default SecondaryButton;
