import { get } from 'lodash';
import baseAPIFactory, { handleTokenHeaders } from './base';
import type { RepairInvoice, RepairImages, LineItem } from './repair';
import type { NoteItem } from './notes';

const END_POINTS = {
  FETCH_ALL_DEVICES: () => '/test-repairs/all',
  CREATE_NEW_REPAIR_TICKET: () => '/test-repairs/create-in-house',
  GET_UPDATE_REPAIR_TICKET: (ticketId: any) => `/test-repairs/detail/${ticketId}`,
  GET_PART_COMPATABILITY: (deviceId: any) => `/test-repairs/parts-compatability/${deviceId}`,
  GET_PDF_PART_DETAILS: (ticketNumber: any) => `/test-repairs/pdfTicketDetails/${ticketNumber}`,
  GET_REPAIR_TICKET_IMAGES: (ticketNumber: any) => `/test-repairs/repair_image/all/${ticketNumber}`,
  GET_REPAIR_PARTS_USED: (ticketNumber: any) => `/test-repairs/repair_part_used/all/${ticketNumber}`,
  UPDATE_LINE_ITEMS: () => '/test-repairs/repair_part_used',
  DECREASE_PART_QUANTITY: (accountId: string | number, partId: string | number) =>
    `/test-repairs/part_closet/decrease/${accountId}/${partId}`,
  DELETE_LINE_ITEM: (lineItemId: any) => `/test-repairs/repair_part_used/${lineItemId}`,
  NOTE_ITEM: () => `/test-repairs/note`,
};

export const listAllTestDevicesAPI = async ({ token }: { token?: string }) =>
  baseAPIFactory.get<any>(END_POINTS.FETCH_ALL_DEVICES(), handleTokenHeaders({ token }));

export const createNewTicket = async (options: any) => {
  const { token, ...otherData } = options;
  return baseAPIFactory.post(END_POINTS.CREATE_NEW_REPAIR_TICKET(), otherData, handleTokenHeaders({ token }));
};

export const getRepairTicketAPI = async ({ ticketId, token }: { ticketId: any; token?: string }) =>
  baseAPIFactory.get<any>(END_POINTS.GET_UPDATE_REPAIR_TICKET(ticketId), handleTokenHeaders({ token }));

export const updateRepairTicketAPI = async (updateData: any) => {
  const ticketId = get(updateData, 'ticket_number');
  const { token, ...otherData } = updateData;
  return baseAPIFactory.put(END_POINTS.GET_UPDATE_REPAIR_TICKET(ticketId), otherData, handleTokenHeaders({ token }));
};

export const getTestPartCompatabilityAPI = async ({ serialId, token }: { serialId: string | number; token?: string }) =>
  baseAPIFactory.get(END_POINTS.GET_PART_COMPATABILITY(serialId), handleTokenHeaders({ token }));

export const getTestInvoiceDetailsByTicketNumber = async (ticketNumber: string, { token }: { token?: string }) =>
  baseAPIFactory.get<RepairInvoice>(END_POINTS.GET_PDF_PART_DETAILS(ticketNumber), handleTokenHeaders({ token }));

export const getTestRepairImagesByTicketNumber = async ({
  repairId,
  token,
}: {
  repairId?: string | number;
  token?: string;
}) => baseAPIFactory.get<RepairImages[]>(END_POINTS.GET_REPAIR_TICKET_IMAGES(repairId), handleTokenHeaders({ token }));

export const getTestLineItemsByTicketNumber = async ({
  repairId,
  token,
}: {
  repairId?: string | number;
  token?: string;
}) => baseAPIFactory.get<LineItem[]>(END_POINTS.GET_REPAIR_PARTS_USED(repairId), handleTokenHeaders({ token }));

export const createTestRepairTicketLineItems = async (data: LineItem) =>
  baseAPIFactory.post(END_POINTS.UPDATE_LINE_ITEMS(), data, handleTokenHeaders(data));

export const decreaseTestPartQuantity = async ({
  accountId,
  partId,
  token,
}: {
  accountId: string | number;
  partId: string | number;
  token?: string;
}) => baseAPIFactory.get(END_POINTS.DECREASE_PART_QUANTITY(accountId, partId), handleTokenHeaders({ token }));

export const deleteTestRepairPartUsed = async (recordId: number | string, { token }: { token?: string }) =>
  baseAPIFactory.delete(END_POINTS.DELETE_LINE_ITEM(recordId), handleTokenHeaders({ token }));

export const listTestNewNoteItem = async ({ ticketId, token }: { ticketId?: string | number; token?: string }) =>
  baseAPIFactory.get<NoteItem[]>(`${END_POINTS.NOTE_ITEM()}/${ticketId}`, handleTokenHeaders({ token }));
