import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading, setGlobalError } from './commonSlice';
import { getAllInhouseTicketsList, getPaginatedList, getAllInHouseRepairTickets } from '../api/repairDescription';
import appLogger from '../infrastructure/config/appLogger';
import { PER_PAGE_RECORD } from '../constants/data/roadmap.constants';
import { isAPIResponseFailure } from '../api/base';
import type { FetchrepairInhouseParamTypes, ItemList, repairInhouseTableData } from '../api/repairDescription';
import type { RootState } from '../store';

const sliceName = 'repairDescription';

export interface InitialState {
  page: number;
  perPage: number;
  totalCount: number;
  totalPages: number;
  itemList: ItemList[];
  dataLoading: boolean;
  inhouseTicketsData: repairInhouseTableData[];
}

const initialState: InitialState = {
  page: 1,
  perPage: PER_PAGE_RECORD,
  totalCount: 0,
  totalPages: 0,
  itemList: [],
  dataLoading: false,
  inhouseTicketsData: [],
};

export const fetchRepairDescriptionItems = createAsyncThunk<ItemList[], { token: string }>(
  `${sliceName}/fetch`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetch`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getPaginatedList(params.token);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      ThunkAPI.dispatch(setGlobalError(e));
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchInhouseRepairItems = createAsyncThunk<any[], FetchrepairInhouseParamTypes>(
  `${sliceName}/fetchInhouseRepairItems`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchInhouseRepairItems`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getAllInhouseTicketsList(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      ThunkAPI.dispatch(setGlobalError(e));
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

export const fetchAllInHouseRepairTickets = createAsyncThunk<any[], FetchrepairInhouseParamTypes>(
  `${sliceName}/fetchAllInHouseRepairTickets`,
  async (params, ThunkAPI) => {
    const loaderName = `${sliceName}/fetchAllInHouseRepairTickets`;
    ThunkAPI.dispatch(setLoading([loaderName, true]));
    try {
      const todoList = await getAllInHouseRepairTickets(params);
      ThunkAPI.dispatch(setLoading([loaderName, false]));
      if (isAPIResponseFailure(todoList)) {
        return ThunkAPI.rejectWithValue(todoList);
      }
      return todoList.data;
    } catch (e) {
      ThunkAPI.dispatch(setGlobalError(e));
      appLogger.error(e);
    } finally {
      ThunkAPI.dispatch(setLoading([loaderName, false]));
    }
    return [];
  }
);

const repairDescriptionSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRepairDescriptionItems.fulfilled, (state, action) => {
      const { payload } = action;
      state.itemList = payload;
    });
    builder.addCase(fetchInhouseRepairItems.pending, (state) => {
      state.dataLoading = true;
    });
    builder.addCase(fetchInhouseRepairItems.fulfilled, (state, action) => {
      state.inhouseTicketsData = action.payload;
      state.dataLoading = false;
    });
    builder.addCase(fetchInhouseRepairItems.rejected, (state) => {
      state.dataLoading = false;
    });
    builder.addCase(fetchAllInHouseRepairTickets.pending, (state) => {
      state.dataLoading = true;
    });
    builder.addCase(fetchAllInHouseRepairTickets.fulfilled, (state, action) => {
      state.inhouseTicketsData = action.payload;
      state.dataLoading = false;
    });
    builder.addCase(fetchAllInHouseRepairTickets.rejected, (state) => {
      state.dataLoading = false;
    });
  },
});

export default repairDescriptionSlice.reducer;

export const repairDescriptionStateItem = createSelector(
  (state: RootState) => state.repairDescription,
  (items: InitialState) => items
);
