import baseAPIFactory, { handleTokenHeaders } from './base';

export interface Estimates {
  data: Order[];
}

export interface Quotes {
  data: Quote[];
}

export interface Invoices {
  data: Invoice[];
}

export interface Order {
  sales_order_id: string;
  account_number: number;
  created_at: Date;
  updated_at: Date;
  subtotal: number;
  tax_total: number;
  order_total: number;
  location: string;
  active_status: boolean;
  pdf: PDF;
  quote_id: string;
  customer_po_number: string;
  shipping_address: string;
  eta: Date;
  tracking_number: string;
  vt_project_number: string;
  account: Account;
  quote: Quote;
}

export interface Account {
  account_number: number;
  account_name: string;
  phone_number: string;
  email: string;
  domain: string;
  credit_limit: string;
  account_balance: string;
  account_manager: number;
  account_site: number;
  in_house_repairs: null;
  territory: null;
  created_by: number;
  payment_terms: null;
  last_modified_by: number;
  created_on: Date;
  account_paid_tier: string;
  tadabase_account_number: null;
  subscription_tier: string;
  netsuite_id: string;
  account_credit: number;
}

export interface Invoice {
  invoice_id: string;
  pdf: PDF;
  account_number: number;
  sales_order_id: string;
  created_at: Date;
  updated_at: Date;
  customer_po_number: string;
  shipping_address: string;
  eta: Date;
  tracking_number: string;
  account: Account;
  sales_order: Order;
}

export interface PDF {
  type: string;
  data: any[];
}

export interface Quote {
  quote_id: string;
  pdf: PDF;
  account_number: number;
  created_at: Date;
  updated_at: Date;
}

export const END_POINTS = {
  FETCH_ALL_ESTIMATES: () => '/estimates',
  FETCH_ALL_ESTIMATES_BY_ACCOUNT: (accountNum: number) => `/estimates/account/${accountNum}`,
  FETCH_ALL_QUOTES: () => '/quote',
  FETCH_ALL_QUOTES_BY_ACCOUNT: (accountNum: number) => `/quote/account/${accountNum}`,
  FETCH_ALL_SALES_ORDER: () => '/sales_order',
  FETCH_ALL_SALES_ORDER_BY_ACCOUNT: (accountNum: number) => `/sales_order/account/${accountNum}`,
  FETCH_ALL_INVOICES: () => '/invoice',
  FETCH_ALL_INVOICES_BY_ACCOUNT: (accountNum: number) => `/invoice/account/${accountNum}`,
};

export const getEstimates = async ({ token }: { token: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_ESTIMATES(), handleTokenHeaders({ token }));

export const getEstimatesByAccount = async ({ token, accountNumber }: { token: string; accountNumber: number }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_ESTIMATES_BY_ACCOUNT(accountNumber), handleTokenHeaders({ token }));

export const getQuotes = async ({ token }: { token: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_QUOTES(), handleTokenHeaders({ token }));

export const getQuotesByAccount = async ({ token, accountNumber }: { token: string; accountNumber: number }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_QUOTES_BY_ACCOUNT(accountNumber), handleTokenHeaders({ token }));

export const getSalesOrder = async ({ token }: { token: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_SALES_ORDER(), handleTokenHeaders({ token }));

export const getSalesOrderByAccount = async ({ token, accountNumber }: { token: string; accountNumber: number }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_SALES_ORDER_BY_ACCOUNT(accountNumber), handleTokenHeaders({ token }));

export const getInvoices = async ({ token }: { token: string }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_INVOICES(), handleTokenHeaders({ token }));

export const getInvoicesByAccount = async ({ token, accountNumber }: { token: string; accountNumber: number }) =>
  baseAPIFactory.get(END_POINTS.FETCH_ALL_INVOICES_BY_ACCOUNT(accountNumber), handleTokenHeaders({ token }));
