import { CSSObject, lighten } from '@mui/material/styles';
import COLORS from '../../constants/colors/colors.constant';

const globalSearchInputStyles = () => ({
  resultsContainer: {
    position: 'absolute' as const,
    top: '100%',
    left: 0,
    right: 0,
    zIndex: 999999,
    maxHeight: 700,
    overflowY: 'auto' as const,
    backgroundColor: 'white',
  } as CSSObject,
  searchWrapper: {
    position: 'relative' as const,
    padding: '4px 3px 2px 3px',
  } as CSSObject,
  noResults: {
    padding: '8px',
  } as CSSObject,
  listItemText: {
    display: 'block',
    whiteSpace: 'nowrap' as const,
    overflow: 'hidden' as const,
    textOverflow: 'ellipsis' as const,
  } as CSSObject,
  textField: {
    '& .MuiOutlinedInput-root': {
      border: 'none',
      backgroundColor: lighten(COLORS.secondary.main, 0.7),
      borderRadius: 2,
      '&:hover fieldset': {
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      paddingLeft: '10px',
    },
  } as CSSObject,
});

export default globalSearchInputStyles;
